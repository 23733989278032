import { AddAttachments } from "components/AddAttachments/AddAttachments";
import { RadioGroupField } from "components/controls/RadioGroupField";
import { TextareaField } from "components/controls/TextareaField";
import { ManageCategoryDocuments } from "components/ManageCategoryDocuments/ManageCategoryDocuments";
import { Grid } from "components/styled";
import { useFormikContext } from "formik";
import { checkedOptions } from "library/constants";
import { IBenefitCategory, IRowItem } from "library/types";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { UploadFile } from "../Upload/UploadFile";
import { BenefitCategoryNameField } from "./BenefitCategoryNameField";

type Props = {
  benefitCategory?: IBenefitCategory;
  countryCode?: number | string;
  clientInternalNumberGOS?: number;
  rootCountryCode?: boolean;
};

export function BenefitFormItems({
  benefitCategory,
  countryCode,
  clientInternalNumberGOS,
  rootCountryCode,
}: Props) {
  const { t } = useTranslation();
  const { values, setFieldValue, errors } = useFormikContext<FormData>();

  return (
    <Container>
      <Grid>
        <BenefitCategoryNameField
          isAddCategory={!benefitCategory}
          countryCode={countryCode}
          clientInternalNumberGOS={clientInternalNumberGOS}
          rootCountryCode={rootCountryCode}
        />
        <RadioGroupField
          value={values["consentsNeeded"]}
          setFieldValue={setFieldValue}
          name="consentsNeeded"
          label={t("hbh.consentNeeded.label")}
          options={checkedOptions}
          // required
        />
        <RadioGroupField
          value={values["isAutoEnrollment"]}
          setFieldValue={setFieldValue}
          name="isAutoEnrollment"
          label={t("hbh.isAutoEnrollment.label")}
          options={checkedOptions}
          // required
        />
        <UploadFile
          isUploadAppImages
          key={values["coverPhoto"]?.path}
          setFieldValue={setFieldValue}
          fieldName="coverPhoto"
          defaultValue={values["coverPhoto"]}
          label={t("hbh.chooseCoverPicture.label")}
        />
      </Grid>
      <Grid $gridColumns={1}></Grid>
      <TextareaField
        required
        label={t("hbh.description.label")}
        name="description"
        value={values["description"]}
        setFieldValue={setFieldValue}
        error={errors["description"]}
        rows={3}
        resize="vertical"
      />
      {benefitCategory ? (
        <ManageCategoryDocuments item={benefitCategory as IRowItem} />
      ) : (
        <AddAttachments attachHiddenForEmployee />
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
`;
