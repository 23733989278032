import useNotificationHandler from "hooks/useNotificationHandler";
import { MUTATION_KEYS, QUERY_KEYS } from "library/shared";
import { IEmployeeDependent, IEmployeeEnrollment } from "library/types";
import { useMutation, useQueryClient } from "react-query";
import api from "service/api";
import { useInvalidateCompanyEmployeeViews } from "./useGetCompanyEmployeeViews";
import { useInvalidateNeedsAttentionData } from "./useGetNeedsAttentionData";

type Params = {
  countryCode: number | string;
  clientInternalNumberGOS: number | string;
  enrollments: IEmployeeEnrollment[];
};
const updateEmployeeEnrollments = async ({
  countryCode,
  clientInternalNumberGOS,
  enrollments,
}: Params) => {
  const response = await api.patch(
    `/employeeenrollment/update/${countryCode}/${clientInternalNumberGOS}`,
    [...enrollments]
  );

  return response.data as IEmployeeDependent;
};

export function useUpdateEmployeeEnrollments(refetch = true) {
  const queryClient = useQueryClient();
  const invalidateCompanyEmployees = useInvalidateCompanyEmployeeViews();
  const { handleAddAxiosErrorNotification } = useNotificationHandler();
  const invalidateNeedsAttentionData = useInvalidateNeedsAttentionData();

  return useMutation(updateEmployeeEnrollments, {
    mutationKey: MUTATION_KEYS.updateEmployeeEnrollments,
    onSuccess: () => {
      if (refetch) {
        invalidateCompanyEmployees();

        queryClient.invalidateQueries({
          queryKey: QUERY_KEYS.companyPackages,
        });
        queryClient.invalidateQueries({
          queryKey: QUERY_KEYS.companyAction,
        });
      }
      invalidateNeedsAttentionData();
    },
    onError: (error) => {
      // handle error
      handleAddAxiosErrorNotification(error);
    },
  });
}
