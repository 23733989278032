import useNotificationHandler from "hooks/useNotificationHandler";
import { MUTATION_KEYS, QUERY_KEYS } from "library/shared";
import { IEmployee, IEmployeeDependent, IEmployeeView } from "library/types";
import { useMutation, useQueryClient } from "react-query";
import api from "service/api";
import { useInvalidateCompanyEmployeeViews } from "./useGetCompanyEmployeeViews";
import { useInvalidateDependentDetails } from "./useGetDependentDetails";
import { useInvalidateEmployeeDetails } from "./useGetEmployeeDetails";
import { useInvalidateNeedsAttentionData } from "./useGetNeedsAttentionData";

type Params = {
  countryCode: number | string;
  clientInternalNumberGOS: number | string;
  employeeDependentTag: number | string;
  payload: IEmployeeDependent;
  employeeLoginEmailAddress: string;
};

const updateEmployeeDependent = async ({
  countryCode,
  clientInternalNumberGOS,
  employeeDependentTag,
  employeeLoginEmailAddress,
  payload,
}: Params) => {
  const response = await api.patch(
    `/employee/dependent/${countryCode}/${clientInternalNumberGOS}/${employeeLoginEmailAddress}/${employeeDependentTag}`,
    payload
  );
  return response.data as IEmployeeDependent;
};

export function useUpdateEmployeeDependent(
  refetch = true,
  onlyActiveImplementations = true
) {
  const queryClient = useQueryClient();
  const invalidateCompanyEmployees = useInvalidateCompanyEmployeeViews();
  const invalidateDependentDetails = useInvalidateDependentDetails();
  const invalidateEmployeeDetails = useInvalidateEmployeeDetails();
  const invalidateNeedsAttentionData = useInvalidateNeedsAttentionData();
  const { handleAddAxiosErrorNotification } = useNotificationHandler();

  return useMutation(updateEmployeeDependent, {
    mutationKey: MUTATION_KEYS.updateEmployeeDependent,
    onSuccess: (updatedDependent) => {
      invalidateNeedsAttentionData();
      if (refetch) {
        invalidateCompanyEmployees();
        invalidateDependentDetails();
        invalidateEmployeeDetails();
      } else {
        const formattedDependent = {
          ...updatedDependent,
          isDependent: true,
        };
        const previousEmployeeViewData = queryClient.getQueryData<
          IEmployeeView[]
        >([
          QUERY_KEYS.companyEmployeeViews,
          updatedDependent?.countryCode?.toString(),
          updatedDependent?.clientInternalNumberGos?.toString(),
          onlyActiveImplementations,
        ]);

        const previousEmployeeData = queryClient.getQueryData<IEmployee>([
          QUERY_KEYS.employeeDetails,
          updatedDependent?.countryCode?.toString(),
          updatedDependent?.clientInternalNumberGos?.toString(),
          updatedDependent?.employeeLoginEmailAddress,
        ]);

        const updatedEmployeeData = {
          ...previousEmployeeData,
          dependents: [
            ...(previousEmployeeData?.dependents || []).map((dependent) => {
              if (
                updatedDependent.employeeDependentTag ===
                dependent?.employeeDependentTag
              ) {
                return updatedDependent;
              }
              return dependent;
            }),
          ],
        };

        const updatedEmployeeViewData = (previousEmployeeViewData || []).map(
          (employee) => {
            if (
              employee.employeeDependentTag ===
              updatedDependent.employeeDependentTag
            ) {
              return {
                ...employee,
                ...formattedDependent,
                expanded: true,
                selected: true,
              };
            }
            if (
              employee.employeeLoginEmailAddress ===
              updatedDependent.employeeLoginEmailAddress
            ) {
              return {
                ...employee,
                selected: true,
                expanded: true,
              };
            }
            return employee;
          }
        );

        if (previousEmployeeData) {
          queryClient.setQueryData(
            [
              QUERY_KEYS.employeeDetails,
              updatedDependent?.countryCode?.toString(),
              updatedDependent?.clientInternalNumberGos?.toString(),
              updatedDependent?.employeeLoginEmailAddress,
            ],
            updatedEmployeeData
          );
        }

        queryClient.setQueryData(
          [
            QUERY_KEYS.dependentDetails,
            updatedDependent?.countryCode?.toString(),
            updatedDependent?.clientInternalNumberGos?.toString(),
            updatedDependent?.employeeLoginEmailAddress,
            updatedDependent?.employeeDependentTag?.toString(),
          ],
          updatedDependent
        );

        queryClient.setQueryData(
          [
            QUERY_KEYS.companyEmployeeViews,
            updatedDependent?.countryCode?.toString(),
            updatedDependent?.clientInternalNumberGos?.toString(),
            onlyActiveImplementations,
          ],
          updatedEmployeeViewData
        );
      }
    },
    onError: (error) => {
      // handle error
      handleAddAxiosErrorNotification(error);
    },
  });
}
