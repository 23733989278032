import { AppSpinner } from "components/AppSpinner/AppSpinner";
import { PageContent } from "components/styled";
import { Subheader } from "components/Subheader/Subheader";
import useNeedsAttentionData from "hooks/useNeedsAttentionData";
import { useAtom, useAtomValue } from "jotai";
import { ROUTES } from "library/constants";
import { EManageHrTabView } from "library/types";
import { getNeedsAttentionTableColumns } from "pages/HrPage/components/ManagePageView/EmployeesView/EmployeesTable/columnsNeedsAttention";
import { EmployeesTable } from "pages/HrPage/components/ManagePageView/EmployeesView/EmployeesTable/EmployeesTable";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { selectedHrTabState } from "store";
import {
  searchEmployeeFilterValueState,
  selectedCompanyState,
} from "store/UIHrPage";

const HrNeedsAttentionPage = () => {
  const { t } = useTranslation();

  const selectedCompany = useAtomValue(selectedCompanyState);
  const location = useLocation();
  const selectedTab = useAtomValue(selectedHrTabState);

  const [searchEmployeeFilterValue, setSearchEmployeeFilterValue] = useAtom(
    searchEmployeeFilterValueState
  );

  const {
    needsAttentionData,
    needsAttentionEmployeeTree,
    loadingNeedsAttentionData,
  } = useNeedsAttentionData({
    countryCode: selectedCompany?.countryCode || 0,
    clientInternalNumberGOS: selectedCompany?.clientInternalNumberGos || "0",
    enabled: true,
    searchEmployeeFilterValue: searchEmployeeFilterValue,
  });

  const handleSearchEmployeeFilterValue = useCallback(
    (value) => {
      setSearchEmployeeFilterValue(value);
    },
    [setSearchEmployeeFilterValue]
  );

  const columns = getNeedsAttentionTableColumns({
    t,
  });

  return (
    <>
      {loadingNeedsAttentionData ? <AppSpinner /> : null}

      <Subheader
        searchFilterValue={searchEmployeeFilterValue}
        isHrPage
        showSearchFilter={selectedTab === EManageHrTabView.employees}
        items={needsAttentionData || []}
        setSearchFilterValue={handleSearchEmployeeFilterValue}
        chatBotDisabled={!selectedCompany}
        showNeedsAttention={location.pathname.includes(ROUTES.HR)}
        needsAttentionCount={needsAttentionData?.length || 0}
      />

      <PageContent>
        <EmployeesTable
          companyEmployees={needsAttentionEmployeeTree}
          columns={columns}
          selectionMode="single"
          stickyColumnsLeft={1}
          showDeleteOption={false}
          showManagePackageEligibilityOption={false}
        />
      </PageContent>
    </>
  );
};

export default HrNeedsAttentionPage;
