import useNotificationHandler from "hooks/useNotificationHandler";
import { MUTATION_KEYS, QUERY_KEYS } from "library/shared";
import { Attachment, IEmployee, IEmployeeView } from "library/types";
import { useMutation, useQueryClient } from "react-query";
import api from "service/api";
import { useInvalidateCompanyEmployeeViews } from "./useGetCompanyEmployeeViews";
import { useInvalidateNeedsAttentionData } from "./useGetNeedsAttentionData";

type Params = {
  countryCode: number | string;
  clientInternalNumberGOS: number | string;
  employeeEmail: string;
  rowVersion: string;
};
const deleteEmployee = async ({
  countryCode,
  clientInternalNumberGOS,
  employeeEmail,
  rowVersion,
}: Params) => {
  const response = await api.patch(
    `/employee/delete/${countryCode}/${clientInternalNumberGOS}/${employeeEmail}`,
    {
      rowVersion,
    }
  );
  return response.data as IEmployee;
};

export function useDeleteEmployee(
  refetch = true,
  onlyActiveImplementations = true
) {
  const queryClient = useQueryClient();

  const invalidateCompanyEmployees = useInvalidateCompanyEmployeeViews();
  const { handleAddAxiosErrorNotification } = useNotificationHandler();
  const invalidateNeedsAttentionData = useInvalidateNeedsAttentionData();

  return useMutation(deleteEmployee, {
    mutationKey: MUTATION_KEYS.deleteEmployee,
    onSuccess: (deletedEmployee) => {
      invalidateNeedsAttentionData();
      if (refetch) {
        invalidateCompanyEmployees();
      } else {
        const previousEmployeeData = queryClient.getQueryData<IEmployeeView[]>([
          QUERY_KEYS.companyEmployeeViews,
          deletedEmployee?.countryCode?.toString(),
          deletedEmployee?.clientInternalNumberGos?.toString(),
          onlyActiveImplementations,
        ]);

        const updatedEmployeeData = previousEmployeeData?.filter(
          (employee: IEmployeeView) => {
            return (
              employee.employeeLoginEmailAddress !==
              deletedEmployee.employeeLoginEmailAddress
            );
          }
        );
        queryClient.setQueryData(
          [
            QUERY_KEYS.companyEmployeeViews,
            deletedEmployee?.countryCode?.toString(),
            deletedEmployee?.clientInternalNumberGos?.toString(),
            onlyActiveImplementations,
          ],
          updatedEmployeeData
        );
      }
    },
    onError: (error) => {
      // handle error
      handleAddAxiosErrorNotification(error);
    },
  });
}
