import { Button } from "@fluentui/react-components";
import { DeleteRegular, ImageAdd24Regular } from "@fluentui/react-icons";
import { SaveIcon } from "@fluentui/react-icons-mdl2";
import { Flex } from "components/styled";
import { UploadFileList } from "components/Upload/UploadFileList";
import { useAppTheme } from "hooks/useAppTheme";
import { IFormattedFile } from "library/types";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useAddImage } from "state/queries/useAddImage";
import styled from "styled-components";
import { AddImageItem } from "./AddImageItem";
import { extractFileNameFromUrl } from "library/utils";

type Props = {
  allImages: string[];
};

export function AddImages({ allImages }: Props) {
  const { t } = useTranslation();
  const theme = useAppTheme();
  const [newAttachList, setNewAttachList] = useState<IFormattedFile[]>([]);

  const { mutateAsync: onAddImage, isLoading: uploadingImages } = useAddImage();

  const deleteFromList = useCallback((attachment) => {
    setNewAttachList((prev) => {
      const filteredAttachmentList = prev.filter((attach) => {
        return attach.name !== attachment?.name;
      });
      return filteredAttachmentList;
    });
  }, []);

  return (
    <Container>
      <Wrap>
        <Flex justify="space-between" wrap="nowrap">
          <UploadFileList
            addIcon={<ImageAdd24Regular />}
            selectedFileList={newAttachList}
            setFieldValue={(_, uploadedList) => {
              const filteredDuplicatesFileList = (uploadedList || []).filter(
                (file) =>
                  !allImages?.some((image) => {
                    const imageName = extractFileNameFromUrl(image);

                    return imageName === file.name;
                  })
              );
              setNewAttachList((prev) => {
                return [...prev, ...filteredDuplicatesFileList];
              });
            }}
            fieldName=""
            label=""
          />
          {newAttachList.length > 1 && (
            <>
              <Button
                style={{
                  minWidth: "fit-content",
                }}
                icon={
                  <SaveIcon
                    style={{
                      color: theme.palette.themePrimary,
                      opacity: uploadingImages ? "0.5" : "1",
                    }}
                  />
                }
                disabled={uploadingImages}
                appearance="outline"
                onClick={async (e) => {
                  try {
                    const promiseArray = [] as Promise<any>[];

                    newAttachList.forEach((attachment) => {
                      promiseArray.push(
                        onAddImage({
                          payload: {
                            ImageContent: attachment?.base64String || "",
                            ImageName: attachment?.name || "",
                            ImageMimeType: attachment?.type || "",
                          },
                        })
                      );
                    });
                    Promise.all(promiseArray).then((values) => {
                      setNewAttachList([]);
                      toast.success(t("hbh.imageUploadedSuccessfully.label"));
                    });
                  } catch (error) {
                    console.log(error, "error in add images");
                  }
                }}
              >
                {t("hbh.saveAll.label")}
              </Button>
              <Button
                style={{
                  minWidth: "fit-content",
                }}
                disabled={uploadingImages}
                onClick={() => {
                  setNewAttachList([]);
                }}
                icon={<DeleteRegular />}
              >
                {t("greco.clear")}
              </Button>
            </>
          )}
        </Flex>
        <AttachmentList>
          {newAttachList?.map((attachment) => {
            return (
              <AddImageItem
                key={attachment?.name}
                attachment={attachment}
                deleteFromList={deleteFromList}
                uploadingImages={uploadingImages}
              />
            );
          })}
        </AttachmentList>
      </Wrap>
    </Container>
  );
}

const Container = styled.div`
  margin: 10px 0 20px;
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px;
`;

const AttachmentList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  //   border: 1px solid ${({ theme }) => theme.palette.neutralLight};
  max-height: 300px;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
`;
