import { EditableTable } from "components/EditableTable/EditableTable";
import { TableSkeleton } from "components/Skeletons/TableSkeleton";
import { useAtom, useAtomValue } from "jotai";
import { columnNames } from "library/constants";
import { genUUID } from "library/utils";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetUserAssignments } from "state/queries/useGetUserAssignments";
import {
  onlyActiveImplementationsState,
  selectedCompanyState,
} from "store/UIHrPage";
import styled from "styled-components";
import { defaultColumnSizingOptions, getSecurityViewColumns } from "./columns";
import { useGetBenefitImplementations } from "state/queries/useGetBenefitImplementations";
import { OnlyActiveSwitch } from "components/OnlyActiveSwitch/OnlyActiveSwitch";
import { useGetBenefitImplementationSecurities } from "state/queries/useGetBenefitImplementationSecurities";
import { IBenefitImplementationSecurity, IUserAssignment } from "library/types";
import { AppSpinner } from "components/AppSpinner/AppSpinner";
import { Button } from "@fluentui/react-components";
import { useUpdateBenefitImplementationSecurities } from "state/queries/useUpdateBenefitImplementationSecurities";

export type SecurityTableRowType = {
  user: IUserAssignment;
  permissions: IBenefitImplementationSecurity[];
};

export function SecurityView() {
  const { t } = useTranslation();
  const selectedCompany = useAtomValue(selectedCompanyState);

  const [updates, setUpdates] = useState<IBenefitImplementationSecurity[]>([]);
  const [markedRows, setMarkedRows] = useState<string[]>([]);
  const [markedColumns, setMarkedColumns] = useState<string[]>([]);

  const {
    data: userAssignements,
    isLoading: userAssignmentsLoading,
    isRefetching,
  } = useGetUserAssignments({
    countryCode: selectedCompany?.countryCode || null,
    clientInternalNumberGOS: selectedCompany?.clientInternalNumberGos || "",
    enabled: !!selectedCompany,
  });

  const {
    mutate: onBenefitImplementationSecurities,
    isLoading: updatesInProgress,
  } = useUpdateBenefitImplementationSecurities();
  const [onlyActiveImplementations, setOnlyActiveImplementation] = useAtom(
    onlyActiveImplementationsState
  );
  const {
    data: implementedBenefits,
    isLoading: implementationLoading,
    isRefetching: implementationRefetching,
  } = useGetBenefitImplementations({
    countryCode: Number(selectedCompany?.countryCode),
    clientInternalNumberGOS: Number(selectedCompany?.clientInternalNumberGos),
    benefitCategoryTag: 0,
    onlyActive: onlyActiveImplementations,
  });

  const {
    data: benefitImplementationSecurities,
    isLoading: implementationSecuritiesLoading,
    isRefetching: implementationSecuritiesRefetching,
  } = useGetBenefitImplementationSecurities({
    countryCode: Number(selectedCompany?.countryCode),
    clientInternalNumberGOS: Number(selectedCompany?.clientInternalNumberGos),
  });

  const onSetOnlyActiveImplementation = useCallback(
    (value) => {
      setOnlyActiveImplementation(value);
    },
    [setOnlyActiveImplementation]
  );

  const columns = getSecurityViewColumns({
    userAssignements: userAssignements || [],
    implementedBenefits,
    t,
    updates,
    setUpdates,
    markedRows,
    setMarkedRows,
    markedColumns,
    setMarkedColumns,
  });

  const tableItems: SecurityTableRowType[] = useMemo(() => {
    let retVal: SecurityTableRowType[] = [];
    if (!userAssignements) return retVal;
    retVal = userAssignements.map((item) => {
      return {
        id: genUUID(),
        user: { ...item },
        permissions:
          benefitImplementationSecurities?.filter((sec) => {
            return sec.hbhUserAssignmentId === item.hbhUserAssignmentId;
          }) || [],
      };
    });
    return retVal;
  }, [userAssignements, benefitImplementationSecurities]);

  const dataLoading =
    isRefetching ||
    userAssignmentsLoading ||
    implementationLoading ||
    implementationRefetching ||
    implementationSecuritiesLoading ||
    implementationSecuritiesRefetching ||
    updatesInProgress;

  if (!updates) return null;

  function updateSecurityFields() {
    if (updates.length > 0) {
      onBenefitImplementationSecurities(
        {
          countryCode: Number(selectedCompany?.countryCode),
          clientInternalNumberGOS: Number(
            selectedCompany?.clientInternalNumberGos
          ),
          securities: updates,
        },
        {
          onSuccess: () => {
            setUpdates([]);
          },
          onError: (error) => {
            console.error("Failed to update security fields:", error);
          },
        }
      );
    }
  }
  function cancelUpdates() {
    setUpdates([]);
    setMarkedRows([]);
    setMarkedColumns([]);
  }

  return (
    <Container>
      <FlexContainer>
        <OnlyActiveSwitch
          value={onlyActiveImplementations}
          setValue={onSetOnlyActiveImplementation}
        />
        <div>
          <Button
            appearance="subtle"
            disabled={dataLoading || updates.length === 0}
            onClick={() => {
              cancelUpdates();
            }}
            style={{ marginLeft: "10px" }}
          >
            {t("hbh.security.cancelChanges.label")}
          </Button>
          <Button
            appearance="primary"
            disabled={dataLoading || updates.length === 0}
            onClick={() => {
              updateSecurityFields();
            }}
          >
            {t("hbh.security.saveChanges.label")}
          </Button>
        </div>
      </FlexContainer>
      <div
        style={{
          marginTop: "10px",
        }}
      >
        {dataLoading ? (
          <TableSkeleton rowItems={10} />
        ) : (
          <EditableTable
            defaultColumnSizingOptions={defaultColumnSizingOptions}
            defaultItems={tableItems}
            columns={columns}
            columnNames={columnNames}
            selectionMode={"multiselect"}
            withPagination={false}
            onRowClick={(row) => undefined}
            stickyColumnsLeft={1}
            stickyColumnsRight={0}
            stickyRowsTop={1}
            stickyRowsBottom={0}
          />
        )}
      </div>
    </Container>
  );
}

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Container = styled.div`
  table {
    .fui-TableBody {
      //   .fui-TableRow:nth-of-type(odd) {
      //     background: ${({ theme }) => theme.palette.white};
      //   }
      //   .fui-TableRow:nth-of-type(even) {
      //     background: ${({ theme }) => theme.palette.white};
      //   }
    }
  }
`;
