import useNotificationHandler from "hooks/useNotificationHandler";
import { MUTATION_KEYS, QUERY_KEYS } from "library/shared";
import { IEmployee, IEmployeeDependent, IEmployeeView } from "library/types";
import { useMutation, useQueryClient } from "react-query";
import api from "service/api";
import { useInvalidateCompanyEmployeeViews } from "./useGetCompanyEmployeeViews";
import { useInvalidateNeedsAttentionData } from "./useGetNeedsAttentionData";

type Params = {
  countryCode: number | string;
  clientInternalNumberGOS: number | string;
  employeeDependentTag: number | string;

  rowVersion: string;
  employeeLoginEmailAddress: string;
};

const deleteEmployeeDependent = async ({
  countryCode,
  clientInternalNumberGOS,
  employeeDependentTag,
  employeeLoginEmailAddress,
  rowVersion,
}: Params) => {
  const response = await api.patch(
    `/employee/dependent/delete/${countryCode}/${clientInternalNumberGOS}/${employeeLoginEmailAddress}/${employeeDependentTag}`,
    {
      rowVersion,
    }
  );
  return response.data as IEmployeeDependent;
};

export function useDeleteEmployeeDependent(
  refetch = true,
  onlyActiveImplementations = true
) {
  const queryClient = useQueryClient();
  const invalidateCompanyEmployees = useInvalidateCompanyEmployeeViews();
  const { handleAddAxiosErrorNotification } = useNotificationHandler();
  const invalidateNeedsAttentionData = useInvalidateNeedsAttentionData();

  return useMutation(deleteEmployeeDependent, {
    mutationKey: MUTATION_KEYS.deleteEmployeeDependent,
    onSuccess: (deletedDependent) => {
      invalidateNeedsAttentionData();
      if (refetch) {
        invalidateCompanyEmployees();
        queryClient.invalidateQueries({
          queryKey: QUERY_KEYS.employeeDetails,
        });
      } else {
        const previousEmployeeViewData = queryClient.getQueryData<
          IEmployeeView[]
        >([
          QUERY_KEYS.companyEmployeeViews,
          deletedDependent?.countryCode?.toString(),
          deletedDependent?.clientInternalNumberGos?.toString(),
          onlyActiveImplementations,
        ]);

        const previousEmployeeData = queryClient.getQueryData<IEmployee>([
          QUERY_KEYS.employeeDetails,
          deletedDependent?.countryCode?.toString(),
          deletedDependent?.clientInternalNumberGos?.toString(),
          deletedDependent?.employeeLoginEmailAddress,
        ]);

        const updatedEmployeeData = {
          ...previousEmployeeData,
          dependents: [
            ...(previousEmployeeData?.dependents || []).filter(
              ({ employeeDependentTag }) => {
                return (
                  deletedDependent.employeeDependentTag !== employeeDependentTag
                );
              }
            ),
          ],
        };

        const updatedEmployeeViewData = previousEmployeeViewData
          ?.filter((employee: IEmployeeView) => {
            return (
              employee.employeeDependentTag !==
              deletedDependent.employeeDependentTag
            );
          })
          .map((employee) => {
            if (
              employee.employeeLoginEmailAddress ===
              deletedDependent.employeeLoginEmailAddress
            ) {
              return {
                ...employee,
                selected: true,
                expanded: true,
              };
            }
            return employee;
          });

        queryClient.setQueryData(
          [
            QUERY_KEYS.employeeDetails,
            deletedDependent?.countryCode?.toString(),
            deletedDependent?.clientInternalNumberGos?.toString(),
            deletedDependent?.employeeLoginEmailAddress,
          ],
          updatedEmployeeData
        );

        queryClient.setQueryData(
          [
            QUERY_KEYS.companyEmployeeViews,
            deletedDependent?.countryCode?.toString(),
            deletedDependent?.clientInternalNumberGos?.toString(),
            onlyActiveImplementations,
          ],
          updatedEmployeeViewData
        );
      }
    },
    onError: (error) => {
      // handle error
      handleAddAxiosErrorNotification(error);
    },
  });
}
