import useNotificationHandler from "hooks/useNotificationHandler";
import { MUTATION_KEYS, QUERY_KEYS } from "library/shared";
import { IEmployee, IEmployeeView } from "library/types";
import { useMutation, useQueryClient } from "react-query";
import api from "service/api";
import { useInvalidateCompanyEmployeeViews } from "./useGetCompanyEmployeeViews";
import { useInvalidateEmployeeDetails } from "./useGetEmployeeDetails";
import { use } from "i18next";
import { useInvalidateNeedsAttentionData } from "./useGetNeedsAttentionData";
import { useLocation } from "react-router-dom";
import { ROUTES } from "library/constants";

type Params = {
  countryCode: number | string;
  clientInternalNumberGOS: number | string;
  employeeEmail: string;
  payload: IEmployee;
};

const updateEmployee = async ({
  countryCode,
  clientInternalNumberGOS,
  employeeEmail,
  payload,
}: Params) => {
  const response = await api.patch(
    `/employee/${countryCode}/${clientInternalNumberGOS}/${employeeEmail}`,
    payload
  );

  return response.data as IEmployee;
};

export function useUpdateEmployee(
  refetch = true,
  onlyActiveImplementations = true
) {
  const { handleAddAxiosErrorNotification } = useNotificationHandler();
  const queryClient = useQueryClient();

  const invalidateCompanyEmployees = useInvalidateCompanyEmployeeViews();
  const invalidateEmployeeDetails = useInvalidateEmployeeDetails();
  const invalidateNeedsAttentionData = useInvalidateNeedsAttentionData();

  return useMutation(updateEmployee, {
    mutationKey: MUTATION_KEYS.createEmployee,
    onSuccess: (updatedEmployee) => {
      invalidateNeedsAttentionData();
      if (refetch) {
        invalidateCompanyEmployees();
        invalidateEmployeeDetails();
      } else {
        const previousEmployeeViewData = queryClient.getQueryData<
          IEmployeeView[]
        >([
          QUERY_KEYS.companyEmployeeViews,
          updatedEmployee?.countryCode?.toString(),
          updatedEmployee?.clientInternalNumberGos?.toString(),
          onlyActiveImplementations,
        ]);

        const updatedEmployeeViewData = previousEmployeeViewData?.map(
          (employee: IEmployeeView) => {
            if (
              employee.employeeLoginEmailAddress ===
                updatedEmployee.employeeLoginEmailAddress &&
              !employee.isDependent
            ) {
              return {
                ...employee,
                ...updatedEmployee,
                expanded: true,
                selected: true,
              };
            }
            return employee;
          }
        );

        queryClient.setQueryData(
          [
            QUERY_KEYS.companyEmployeeViews,
            updatedEmployee?.countryCode?.toString(),
            updatedEmployee?.clientInternalNumberGos?.toString(),
            onlyActiveImplementations,
          ],
          updatedEmployeeViewData
        );
        queryClient.setQueryData(
          [
            QUERY_KEYS.employeeDetails,
            updatedEmployee?.countryCode?.toString(),
            updatedEmployee?.clientInternalNumberGos?.toString(),
            updatedEmployee?.employeeLoginEmailAddress,
          ],
          updatedEmployee
        );
      }
    },
    onError: (error) => {
      // handle error
      handleAddAxiosErrorNotification(error);
    },
  });
}
