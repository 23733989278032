import { Button, Text } from "@fluentui/react-components";
import { ArrowUploadRegular } from "@fluentui/react-icons";
import { ClearIcon } from "@fluentui/react-icons-mdl2";
import { AvatarWithPreview } from "components/AvatarWithPreview/AvatarWithPreview";
import FormFieldLabel from "components/FormFieldLabel/FormFieldLabel";
import { useAppTheme } from "hooks/useAppTheme";
import { IFormattedFile } from "library/types";
import {
  extractFileNameFromUrl,
  formatFile,
  getExtensionFromUrl,
} from "library/utils";
import { ManageImages } from "pages/AdminPage/components/ManageImages/ManageImages";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

type Props = {
  fieldName: string;
  setFieldValue: (name: string, value: IFormattedFile[] | null) => void;
  label: string;
  selectedFileList?: IFormattedFile[] | null;
  addIcon?: React.JSX.Element;
  disabled?: boolean;
  onlyUploadButton?: boolean;
};

export const UploadFileList = ({
  fieldName,
  setFieldValue,
  label,
  selectedFileList,
  addIcon,
  disabled,
  onlyUploadButton,
}: Props) => {
  const { palette } = useAppTheme();
  const { t } = useTranslation();

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFilesChange = async (event) => {
    const fileList = Array.from(event.target.files);
    const formattedFiles = await Promise.all((fileList as any).map(formatFile));
    const filteredDuplicatesFileList = formattedFiles.filter(
      (file) =>
        !selectedFileList?.some(
          (selectedFile) => selectedFile.name === file.name
        )
    );
    setFieldValue(fieldName, filteredDuplicatesFileList);
  };

  return (
    <Container>
      {label && <FormFieldLabel label={label} required={false} />}
      <Wrap>
        <Button
          style={{
            paddingLeft: "0",
          }}
          disabled={disabled}
          appearance="transparent"
          icon={addIcon ? addIcon : <ArrowUploadRegular />}
          onClick={() => {
            if (!fileInputRef.current) return;
            fileInputRef.current.click();
          }}
        >
          {selectedFileList?.length
            ? t("hbh.uploadNew.label")
            : t("hbh.upload.label")}
          <input
            ref={fileInputRef}
            onChange={handleFilesChange}
            type="file"
            accept="*"
            multiple
            hidden
          />
        </Button>
      </Wrap>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;

const Wrap = styled.div`
  display: flex;
  align-items: center;
`;
