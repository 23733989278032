import { useAtomValue } from "jotai";
import { IEmployeeEnrollment, SelectItem } from "library/types";
import { isAfterOrOnToday, isBeforeOrOnToday } from "library/utils";
import { useMemo } from "react";
import { useGetCompanyBenefitPackages } from "state/queries/useGetCompanyBenefitPackages";
import {
  onlyActiveImplementationsState,
  selectedCompanyState,
} from "store/UIHrPage";

type Props = {
  isAddMode: boolean;
  employeeLoginEmailAddress: string;
  employeeEligibilities: IEmployeeEnrollment[];
  isDependent?: boolean;
  benefitPackageTag?: number;
};
export function useAddEligibilityOptions({
  isAddMode,
  employeeLoginEmailAddress,
  employeeEligibilities,
  isDependent,
  benefitPackageTag,
}: Props): {
  options: SelectItem[];

  optionsLoading: boolean;
} {
  const onlyActiveImplementations = useAtomValue(
    onlyActiveImplementationsState
  );

  const selectedCompany = useAtomValue(selectedCompanyState);

  const { data: companyPackages, isLoading: companyPackagesLoading } =
    useGetCompanyBenefitPackages({
      countryCode: Number(selectedCompany?.countryCode),
      clientInternalNumberGOS: Number(selectedCompany?.clientInternalNumberGos),
      enabled: !!isAddMode && !!selectedCompany,
    });

  const filteredOptions = useMemo(() => {
    if (!companyPackages || !employeeEligibilities) return [];

    const getOnlyActiveFilterValue = (item) => {
      if (onlyActiveImplementations) {
        return isBeforeOrOnToday(item?.validFrom);
      }
      return true;
    };

    const validPackages = companyPackages.filter(
      (item) =>
        isAfterOrOnToday(item?.validTo) && getOnlyActiveFilterValue(item)
    );

    if (benefitPackageTag) {
      return validPackages
        .filter(
          (packageItem) => packageItem.benefitPackageTag === benefitPackageTag
        )
        .map((item) => ({
          ...item,
          value: item?.benefitPackageTag || "",
          text: item?.name || "",
        }));
    }

    return validPackages
      .filter((packageItem) => {
        const enrollments = employeeEligibilities
          .filter(
            (employeeEnrollment) =>
              employeeEnrollment.benefitPackageTag ===
              packageItem.benefitPackageTag
          )
          .sort(
            (a, b) =>
              new Date(b.dateOfEnrollment ?? "").getTime() -
              new Date(a.dateOfEnrollment ?? "").getTime()
          ); // Sort by dateOfEnrollment

        const lastEnrollment = enrollments[0]; // Get the last enrollment

        return !lastEnrollment || lastEnrollment.dateOfCancellation !== null;
      })
      .map((item) => ({
        ...item,
        value: item?.benefitPackageTag || "",
        text: item?.name || "",
      }));
  }, [
    benefitPackageTag,
    companyPackages,
    employeeEligibilities,
    onlyActiveImplementations,
  ]);

  return {
    options: isAddMode ? filteredOptions : [],
    optionsLoading: companyPackagesLoading,
  };
}
