import useNotificationHandler from "hooks/useNotificationHandler";
import { MUTATION_KEYS } from "library/shared";
import { useMutation } from "react-query";
import api from "service/api";

const getChatSessionId = async (documentList: string[]) => {
  const response = await api.post(`/GenerateVectorBase`, documentList);

  return response.data;
};

export function useGetChatSessionId() {
  const { handleAddAxiosErrorNotification } = useNotificationHandler();

  return useMutation(getChatSessionId, {
    mutationKey: MUTATION_KEYS.chatSessionId,

    onError: (error) => {
      // handle error
      handleAddAxiosErrorNotification(error);
    },
  });
}
