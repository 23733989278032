import { tokens } from "@fluentui/react-theme";
import FormFieldLabel from "components/FormFieldLabel/FormFieldLabel";
import {
  ErrorMessageContainer,
  FormFieldContainer,
  StyledErrorMessage,
} from "components/styled";
import { useAppTheme } from "hooks/useAppTheme";
import { IBenefitPackageView, SelectItem } from "library/types";
import Select from "react-select";
import { CustomPackageOption } from "./CustomPackageOption";
import { formatPackageOptions } from "./helpers";

type Props = {
  name: string;
  label?: string;
  options?: (IBenefitPackageView & SelectItem)[];
  placeholder?: string;
  value: SelectItem | null;
  setFieldValue: (name: string, value: SelectItem) => void;
  error: string;
  noErrorSection?: boolean;
  required?: boolean;
  noLabel?: boolean;
  disabled?: boolean;
} & Partial<Select>;

export const PackageSelectField = ({
  label = "",
  options = [],
  name = "",
  value,
  placeholder = "",
  error = "",
  noErrorSection = false,
  required = false,
  noLabel = false,
  setFieldValue,
  disabled = false,
  ...props
}: Props) => {
  const theme = useAppTheme();

  const data = formatPackageOptions(options);

  const onChange = (option) => {
    setFieldValue(name, option);
  };

  return (
    <FormFieldContainer>
      <FormFieldLabel label={"Package"} required={true} />
      <Select
        isDisabled={disabled}
        value={value}
        onChange={onChange}
        closeMenuOnSelect={true}
        components={{ Option: CustomPackageOption }}
        styles={{
          container: (baseStyles) => ({
            ...baseStyles,
            position: "relative",
          }),
          control: (baseStyles, state) => ({
            ...baseStyles,
            background: "transparent",
            border: "none",
            borderRadius: "0",
            height: "32px !important",
            minHeight: "32px !important",
            borderBottom: error
              ? `2px solid ${theme.palette.redDark} !important`
              : state.isFocused
              ? `2px solid ${theme.palette.themePrimary} !important`
              : `1px solid ${tokens.colorNeutralStrokeAccessible} !important`,
            boxShadow: "none",
            ...(state.isDisabled && {
              borderBottom: `1px solid ${tokens.colorNeutralStrokeAccessible} !important`,
            }),
          }),
          menu: (p) => ({
            ...p,
            background: theme.palette.neutralLighter,
          }),
          menuList: (p) => ({
            ...p,
            maxHeight: "500px",
            scrollbarWidth: "thin",
          }),
          option: (p, state) => ({
            ...p,
            background:
              state.isSelected || state.isFocused
                ? theme.palette.neutralLighter
                : theme.palette.white,
            "&:hover": {
              background: state?.isDisabled ? "" : theme.palette.neutralLighter,
            },
            padding: 0,
            lineHeight: "10px",
            color: theme.palette.black,
            fontWeight: 400,
          }),

          input: (p) => ({
            ...p,
            margin: "4px",
            color: (function () {
              if (disabled) {
                return theme.palette.neutralSecondaryAlt;
              }

              return theme.palette.black;
            })(),
          }),
          valueContainer: (p, state) => ({
            ...p,
            padding: "1px 8px !important",
          }),
          placeholder: (p, state) => ({
            ...p,
            padding: "1px 8px !important",
          }),
          singleValue: (p, state) => ({
            ...p,
            fontSize: "14px",
            marginLeft: "5px",
            fontWeight: 400,
            color: theme.palette.black,
          }),
          dropdownIndicator: (p, state) => ({
            ...p,
            ...(state.isDisabled && { visibility: "hidden" }),
          }),

          indicatorSeparator: (p, state) => ({
            ...p,
            visibility: "hidden",
          }),
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
            overflow: "visible",
          }),
        }}
        options={data}
        {...props}
      />
      <ErrorMessageContainer>
        <StyledErrorMessage>{error}</StyledErrorMessage>
      </ErrorMessageContainer>
    </FormFieldContainer>
  );
};
