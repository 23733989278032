import { AppSpinner } from "components/AppSpinner/AppSpinner";
import CheckboxField from "components/controls/CheckboxField";
import DatePickerField from "components/controls/DatePickerField";
import { PackageValidationFields } from "components/PackageValidationFields/PackageValidationFields";
import { Grid } from "components/styled";
import { useFormikContext } from "formik";
import { IEmployeeView } from "library/types";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSuggestDates } from "state/queries/useSuggestDates";
import { PackageEditableFields } from "../../PackageEditableFields";
import { PackageSelectField } from "./PackageSelectField/PackageSelectField";
import { useAddEligibilityOptions } from "./useAddEligibilityOptions";
import { useParams } from "react-router-dom";

type Props = {
  isAddMode?: boolean;
  packageUser?: IEmployeeView;
  benefitPackageTag?: number;
};

export function EligibilityFormFields({
  packageUser,
  benefitPackageTag,
}: Props) {
  const { t } = useTranslation();
  const employeeDateOfEmployment = packageUser?.dateOfEmployment;
  const employeeDateOfExit = packageUser?.dateOfExit;
  const params = useParams();
  const packageId = params.packageId;

  const {
    mutate: calculateDates,
    data: calculatedDates,
    isLoading: calculatedDatesLoading,
  } = useSuggestDates();

  const { values, setFieldValue, errors } = useFormikContext<FormData>();
  const { options: packagesOptions } = useAddEligibilityOptions({
    isAddMode: true,
    employeeLoginEmailAddress: packageUser?.employeeLoginEmailAddress || "",
    employeeEligibilities: packageUser?.enrollments || [],
    benefitPackageTag,
  });

  const packageOptionsAsSelectItem = packagesOptions.map((option) => {
    return {
      ...option,
      text: option.name,
      label: option.name,
      value: option.benefitPackageTag,
    };
  });

  useEffect(() => {
    if (calculatedDates) {
      setFieldValue("dateOfEligibility", calculatedDates.dateOfEligibility);
      setFieldValue(
        "endDateOfEligibility",
        calculatedDates.endDateOfEligibility
      );
      setFieldValue(
        "cancellationPossibility",
        calculatedDates.cancellationPossibility
      );
    }
  }, [calculatedDates]);

  useEffect(() => {
    // if (values?.["isAutoEnrollment"] === undefined) {
    setFieldValue("isAutoEnrollment", values["package"]?.isAutoEnrollment);
    // }
  }, [values["package"]?.isAutoEnrollment]);

  useEffect(() => {
    setFieldValue("isUpgradeable", values["package"]?.isUpgradeable);
    setFieldValue(
      "requiresEnrollmentApproval",
      values["package"]?.requiresEnrollmentApproval
    );
    setFieldValue(
      "requiresCancellationApproval",
      values["package"]?.requiresCancellationApproval
    );
  }, [values["package"]]);

  useEffect(() => {
    if (packageId !== null && packageOptionsAsSelectItem?.length === 1) {
      setFieldValue("package", packageOptionsAsSelectItem[0]);
      const packageData: any = packageOptionsAsSelectItem[0];
      calculateDates({
        countryCode: packageData.countryCode,
        clientInternalNumberGOS: packageData.clientInternalNumberGos,
        benefitImplementationTag: packageData.benefitImplementationTag,
        benefitPackageTag: packageData.benefitPackageTag,
        employeeEmail: packageUser?.employeeLoginEmailAddress || "",
        isAutoEnrollment: packageData?.isAutoEnrollment,
        payload: {},
      });
    }
  }, [packageId, packageUser, packagesOptions]);
  return (
    <>
      {calculatedDatesLoading && <AppSpinner />}
      <Grid $gridColumns={3}>
        <PackageSelectField
          disabled={!!benefitPackageTag}
          required
          error={errors["package"]}
          setFieldValue={(name, packageData) => {
            setFieldValue(name, packageData);
            calculateDates({
              countryCode: packageData.countryCode,
              clientInternalNumberGOS: packageData.clientInternalNumberGos,
              benefitImplementationTag: packageData.benefitImplementationTag,
              benefitPackageTag: packageData.benefitPackageTag,
              employeeEmail: packageUser?.employeeLoginEmailAddress || "",
              isAutoEnrollment: packageData?.isAutoEnrollment,
              payload: {},
            });
          }}
          value={values["package"]}
          label={t("hbh.package.label")}
          options={packageOptionsAsSelectItem}
          name={"package"}
        />
        <DatePickerField
          required
          minDate={
            calculatedDates?.dateOfEligibility
              ? new Date(calculatedDates?.dateOfEligibility)
              : undefined
          }
          maxDate={
            calculatedDates?.endDateOfEligibility
              ? new Date(calculatedDates?.endDateOfEligibility)
              : undefined
          }
          error={errors["dateOfEligibility"]}
          name="dateOfEligibility"
          value={values["dateOfEligibility"] as Date}
          setFieldValue={setFieldValue}
          label={t("hbh.eligibilityDate.label")}
        />
        <DatePickerField
          required
          minDate={new Date(calculatedDates?.dateOfEligibility || "")}
          error={errors["endDateOfEligibility"]}
          name="endDateOfEligibility"
          value={values["endDateOfEligibility"] as Date}
          setFieldValue={setFieldValue}
          label={t("hbh.endEligibilityDate.label")}
        />
        <DatePickerField
          required={
            Number(values["package"]?.["cancellationPossibilityDateType"]) !== 1
          }
          error={errors["cancellationPossibility"]}
          name="cancellationPossibility"
          value={values["cancellationPossibility"] as Date}
          setFieldValue={setFieldValue}
          label={t("hbh.cancellationPossibility.label")}
        />
        <CheckboxField
          disabled={Boolean(!values["package"])}
          name="isAutoEnrollment"
          value={values["isAutoEnrollment"]}
          setFieldValue={(name, value) => {
            setFieldValue(name, value);
            if (value) {
              calculateDates({
                countryCode: values["package"].countryCode,
                clientInternalNumberGOS:
                  values["package"].clientInternalNumberGos,
                benefitImplementationTag:
                  values["package"].benefitImplementationTag,
                benefitPackageTag: values["package"].benefitPackageTag,
                employeeEmail: packageUser?.employeeLoginEmailAddress || "",
                isAutoEnrollment: value,
                payload: {
                  cancellationPossibility: values["cancellationPossibility"],
                  dateOfEligibility: values["dateOfEligibility"],
                  endDateOfEligibility: values["endDateOfEligibility"],
                },
              });
            }
          }}
          label={t("hbh.isAutoEnrollment.label")}
        />
      </Grid>
      <Grid>
        <CheckboxField
          name="isUpgradeable"
          value={values["isUpgradeable"]}
          setFieldValue={setFieldValue}
          label={t("hbh.isUpgradeable.label")}
        />
        <CheckboxField
          name="requiresEnrollmentApproval"
          value={values["requiresEnrollmentApproval"]}
          setFieldValue={setFieldValue}
          label={t("hbh.requiresEnrollmentApproval.label")}
        />
        <CheckboxField
          name="requiresCancellationApproval"
          value={values["requiresCancellationApproval"]}
          setFieldValue={setFieldValue}
          label={t("hbh.requiresCancellationApproval.label")}
        />
      </Grid>
      <Grid>
        <PackageEditableFields />
      </Grid>
      <PackageValidationFields
        isDependent={packageUser?.isDependent}
        packageData={{
          ...values["package"],
          dateOfEmployment: employeeDateOfEmployment,
        }}
      />
    </>
  );
}
