import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  makeStyles,
} from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";
import { RemoveFilterIcon, SelectAllIcon } from "@fluentui/react-icons-mdl2";
import { AppSpinner } from "components/AppSpinner/AppSpinner";
import { ActionButton, Flex } from "components/styled";
import { devices } from "library/constants";
import { QUERY_KEYS } from "library/shared";
import { IBenefitCategory, SelectItem } from "library/types";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useCreateMultipleBenefitCategories } from "state/queries/useCreateMultipleBenefitCategories";
import { useGetBenefitCategories } from "state/queries/useGetBenefitCategories";
import styled from "styled-components";
import { SelectBenefitCategoriesTable } from "./SelectBenefitCategoriesTable";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  country: SelectItem;
  clientInternalNumberGOS?: number;
  withRootCountryCode?: boolean;
  clientName?: string;
};

const useStyles = makeStyles({
  body: {
    display: "flex",
    flexDirection: "column",
  },
  content: {},
});

export const SelectBenefitCategoriesDialog = ({
  open,
  setOpen,
  country,
  clientInternalNumberGOS = 0,
  withRootCountryCode,
  clientName = "",
}: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const styles = useStyles();
  const title = t("hbh.addBenefitsFor.label", {
    object: withRootCountryCode ? country.text : clientName,
  });

  const {
    mutate: onCreateMultipleBenefitCategories,
    isLoading: createMultipleCategoriesInProgress,
  } = useCreateMultipleBenefitCategories();

  const { data: rootBenefitCategories, isLoading: getBenefitCatInProgress } =
    useGetBenefitCategories({
      countryCode: withRootCountryCode ? 0 : country.value,
      clientInternalNumberGOS: 0,
    });
  const [includedBenefitCategories, setIncludedBenefitCategories] = useState<
    IBenefitCategory[]
  >([]);

  const isLoading =
    createMultipleCategoriesInProgress || getBenefitCatInProgress;

  useEffect(() => {
    if (rootBenefitCategories) {
      setIncludedBenefitCategories(rootBenefitCategories.benefitCategories);
    }
  }, [rootBenefitCategories]);

  const handleSetIncludedBenefitCategories = useCallback((data) => {
    setIncludedBenefitCategories(data);
  }, []);

  if (isLoading) return <AppSpinner />;

  if (!rootBenefitCategories) return null;

  return (
    <Dialog
      modalType="alert"
      open={open}
      onOpenChange={(event, data) => setOpen(data.open)}
    >
      <StyledDialogSurface
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <DialogBody className={styles.content}>
          <DialogTitle
            action={
              <DialogTrigger action="close">
                <Button
                  appearance="subtle"
                  aria-label="close"
                  icon={<Dismiss24Regular />}
                />
              </DialogTrigger>
            }
          >
            {title}
          </DialogTitle>
          <DialogContent className={styles.content}>
            <Flex
              justify="space-between"
              style={{
                marginTop: "10px",
              }}
            >
              <ActionButton
                noBorder
                icon={<SelectAllIcon />}
                appearance="subtle"
                type=""
                onClick={() => {
                  const data = includedBenefitCategories.map((category) => {
                    return {
                      ...category,
                      included: true,
                    };
                  });
                  setIncludedBenefitCategories(data);
                }}
              >
                {t("hbh.selectAll.label")}
              </ActionButton>
              <ActionButton
                noBorder
                icon={<RemoveFilterIcon />}
                appearance="subtle"
                onClick={() => {
                  const data = includedBenefitCategories.map((category) => {
                    return {
                      ...category,
                      included: false,
                    };
                  });
                  setIncludedBenefitCategories(data);
                }}
              >
                {t("hbh.clearAll.label")}
              </ActionButton>
            </Flex>

            <SelectBenefitCategoriesTable
              rootBenefitCategories={rootBenefitCategories.benefitCategories}
              includedBenefitCategories={includedBenefitCategories}
              setIncludedBenefitCategories={handleSetIncludedBenefitCategories}
            />
          </DialogContent>
          <DialogActions>
            <Button
              type="submit"
              appearance="primary"
              style={{
                paddingLeft: "25px",
                paddingRight: "25px",
              }}
              onClick={async () => {
                const categoriesToCreate = includedBenefitCategories
                  .filter((category) => category.included)
                  ?.map((category) => {
                    return {
                      ...category,
                      benefitCategoryTag: 0,
                      benefitCategoryParentTag: category.benefitCategoryTag,
                    };
                  });
                onCreateMultipleBenefitCategories(
                  {
                    countryCode: country.value,
                    clientInternalNumberGOS,
                    payload: categoriesToCreate,
                  },
                  {
                    onSuccess: () => {
                      setOpen(false);

                      queryClient.refetchQueries({
                        queryKey: [
                          QUERY_KEYS.benefitCategories,
                          Number(country.value),
                          clientInternalNumberGOS,
                        ],
                      });
                    },
                  }
                );
              }}
            >
              {t("greco.save")}
            </Button>
          </DialogActions>
        </DialogBody>
      </StyledDialogSurface>
    </Dialog>
  );
};

const StyledDialogSurface = styled(DialogSurface)`
  margin-left: 10px;
  margin-right: 10px;
  width: 95%;
  min-width: 95%;
  max-height: 98vh;
  padding: 15px;

  @media only screen and ${devices.md} {
    width: 500px;
    min-width: 500px;
    padding: 24px;

    margin-left: auto;
    margin-right: auto;
  }
`;
