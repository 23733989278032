import InputField from "components/controls/InputField";
import SelectField from "components/controls/SelectField";
import { TextareaField } from "components/controls/TextareaField";
import { Grid } from "components/styled";
import { UploadFile } from "components/Upload/UploadFile";
import { useFormikContext } from "formik";
import { useTaxonomyOptions } from "hooks/useTaxonomyOptions";
import { ECheckedValue, EInsurerConsentType, ETaxonomy } from "library/types";
import { useTranslation } from "react-i18next";

export function ConsentDependencyFields() {
  const { t } = useTranslation();
  const { values, setFieldValue, errors } = useFormikContext<FormData>();
  const consentOptions = useTaxonomyOptions(ETaxonomy.ConsentType);
  const deliveryTypeOptions = useTaxonomyOptions(ETaxonomy.DeliveryType);

  const selectedInsurerConsentType = values["insurerConsentType"];
  const consentNeeded = values["consentsNeeded"] === ECheckedValue.Yes;

  return (
    <>
      <Grid>
        <SelectField
          required={consentNeeded}
          error={errors["insurerConsentType"]}
          setFieldValue={setFieldValue}
          value={values["insurerConsentType"]}
          label={t("hbh.typeOfConsentInsurer.label")}
          options={consentOptions}
          name={"insurerConsentType"}
          showEmptyOption
        />

        {selectedInsurerConsentType?.value ===
          EInsurerConsentType.URL_TO_INSURER && (
          <InputField
            required
            error={errors["insurerConsentUrl"]}
            label={t("hbh.consentUrl.label")}
            name="insurerConsentUrl"
            value={values["insurerConsentUrl"]}
            setFieldValue={setFieldValue}
          />
        )}
        {selectedInsurerConsentType?.value ===
          EInsurerConsentType.INSURER_FORM && (
          <>
            <SelectField
              required
              error={errors["insurerConsentDeliveryType"]}
              setFieldValue={setFieldValue}
              value={values["insurerConsentDeliveryType"]}
              label={t("hbh.insurerConsentDeliveryType.label")}
              options={deliveryTypeOptions}
              name={"insurerConsentDeliveryType"}
              showEmptyOption
            />
            <UploadFile
              isDocument
              setFieldValue={setFieldValue}
              fieldName="insurerForm"
              defaultValue={values["insurerForm"]}
              label={t("hbh.consentDocument.label")}
            />
          </>
        )}
      </Grid>
      {selectedInsurerConsentType?.value ===
        EInsurerConsentType.I_AGREE_TEXT && (
        <TextareaField
          required
          label={t("hbh.iAgreeText.label")}
          name={"insurerConsentText"}
          value={values["insurerConsentText"] as string}
          setFieldValue={setFieldValue}
          error={errors["insurerConsentText"]}
          rows={3}
          resize="vertical"
        />
      )}
    </>
  );
}
