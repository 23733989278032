import { QUERY_KEYS } from "library/shared";
import { IClientAndBenefitCategories } from "library/types";
import { UseQueryResult, useQuery, useQueryClient } from "react-query";
import api from "service/api";

type Params = {
  countryCode: number | string | null;
  clientInternalNumberGOS: number | string;
  enabled?: boolean;
};

const getBenefitCategories = async ({
  countryCode,
  clientInternalNumberGOS,
}: Params) => {
  if (countryCode === null || clientInternalNumberGOS === null) return;

  const response = await api.get(
    `/benefitcategory/${countryCode}/${clientInternalNumberGOS}`
  );

  return response.data;
};

export function useGetBenefitCategories({
  countryCode,
  clientInternalNumberGOS,
  enabled = true,
}: Params): UseQueryResult<IClientAndBenefitCategories> {
  return useQuery<IClientAndBenefitCategories, Error>(
    [
      QUERY_KEYS.benefitCategories,
      countryCode?.toString(),
      clientInternalNumberGOS?.toString(),
    ],
    () => getBenefitCategories({ countryCode, clientInternalNumberGOS }),
    {
      enabled: enabled,
      //staleTime: 0,
      refetchOnMount: true,
      //   onError: (error: any) => {
      //     errorHandler({
      //       label: "Error",
      //       type: notificationTypes.error,
      //       value: "Error while loading offer item data",
      //       errors: error?.message,
      //       fieldName: "",
      //     });
      //   },
    }
  );
}

export const useInvalidateBenefitCategories = () => {
  const queryClient = useQueryClient();

  return () =>
    queryClient.invalidateQueries({ queryKey: QUERY_KEYS.benefitCategories });
};
export const useRefetchBenefitCategories = () => {
  const queryClient = useQueryClient();

  return () =>
    queryClient.refetchQueries({ queryKey: QUERY_KEYS.benefitCategories });
};
