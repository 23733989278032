import useNotificationHandler from "hooks/useNotificationHandler";
import { MUTATION_KEYS, QUERY_KEYS } from "library/shared";
import { IEmployee } from "library/types";
import { useMutation, useQueryClient } from "react-query";
import api from "service/api";
import { useInvalidateEmployeeDetails } from "./useGetEmployeeDetails";

type Params = {
  countryCode: number | string;
  employeeLoginEmailAddress: string;
  clientInternalNumberGOS: number | string;
  attachmentName: string;
  rowVersion: string;
};

const deleteAttachment = async ({
  countryCode,
  clientInternalNumberGOS,
  employeeLoginEmailAddress,
  attachmentName,
  rowVersion,
}: Params) => {
  const response = await api.patch(
    `/employee/attachment/delete/${countryCode}/${clientInternalNumberGOS}/${employeeLoginEmailAddress}/${attachmentName}`,
    {
      rowVersion,
    }
  );
  return response.data;
};

export function useDeleteEmployeeAttachment(refetch = true) {
  const queryClient = useQueryClient();
  const invalidateEmployeeDetails = useInvalidateEmployeeDetails();

  const { handleAddAxiosErrorNotification } = useNotificationHandler();

  return useMutation(deleteAttachment, {
    mutationKey: MUTATION_KEYS.deleteEmployeeAttachment,
    onSuccess: (deletedAttach) => {
      if (refetch) {
        invalidateEmployeeDetails();
      } else {
        const previousEmployeeData = queryClient.getQueryData<IEmployee>([
          QUERY_KEYS.employeeDetails,
          deletedAttach?.countryCode?.toString(),
          deletedAttach?.clientInternalNumberGos?.toString(),
          deletedAttach?.attachmentObjectTag,
        ]);
        const updatedEmployeeData = {
          ...previousEmployeeData,
          attachments: [
            ...(previousEmployeeData?.attachments || []).filter(
              (item) => item.attachmentName !== deletedAttach.attachmentName
            ),
          ],
        };
        queryClient.setQueryData(
          [
            QUERY_KEYS.employeeDetails,
            deletedAttach?.countryCode?.toString(),
            deletedAttach?.clientInternalNumberGos?.toString(),
            deletedAttach?.attachmentObjectTag,
          ],
          updatedEmployeeData
        );
      }
    },
    onError: (error) => {
      // handle error
      handleAddAxiosErrorNotification(error);
    },
  });
}
