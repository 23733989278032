import {
  IBenefitPackageView,
  IEmployeeEnrollmentBase,
  IEmployeeView,
} from "library/types";
import styled from "styled-components";
import { DeleteEmployeeEnrollment } from "./DeleteEmployeeEnrollment";
import { EditEmployeeEnrollment } from "./EditEmployeeEnrollment/EditEmployeeEnrollment";
import { StatusActions } from "./StatusActions/StatusActions";

type Props = {
  packageData?: IBenefitPackageView;
  enrollment?: IEmployeeEnrollmentBase;
  packageUser?: IEmployeeView;
  disabled?: boolean;
  showDeleteOption?: boolean;
  showManagePackageEligibilityOption?: boolean;
};

export function EmployeeEnrollmentsActions({
  packageData,
  packageUser,
  enrollment,
  disabled,
  showDeleteOption,
  showManagePackageEligibilityOption,
}: Props) {
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <ActionsWrap>
        <StatusActions
          packageData={packageData}
          packageUser={packageUser}
          employeeEnrollment={enrollment}
          disabled={disabled}
        />
        {showManagePackageEligibilityOption && (
          <EditEmployeeEnrollment
            packageData={packageData}
            packageUser={packageUser}
            employeeEnrollment={enrollment}
            disabled={disabled}
          />
        )}
        {showDeleteOption && (
          <DeleteEmployeeEnrollment
            packageData={packageData}
            packageUser={packageUser}
            enrollment={enrollment}
            disabled={disabled}
          />
        )}
      </ActionsWrap>
    </div>
  );
}

const ActionsWrap = styled.div`
  display: flex;
  gap: 5px;
  width: 100%;
`;
