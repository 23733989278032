import { Button } from "@fluentui/react-components";
import { ArrowExportRegular } from "@fluentui/react-icons";
import { ManageAttachmentsDialog } from "components/ManageAttachmentsDialog/ManageAttachmentsDialog";
import { ActionButton, Grid } from "components/styled";
import { useTaxonomyOptions } from "hooks/useTaxonomyOptions";
import {
  Attachment,
  EAttachmentType,
  ETaxonomy,
  IEmployeeEnrollment,
  IFormattedFile,
  IFormattedFileExtended,
} from "library/types";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useCreateEnrollmentAttachment } from "state/queries/useCreateEnrollmentAttachment";
import { useDeleteEnrollmentAttachment } from "state/queries/useDeleteEnrollmentAttachment";
import { useUpdateEnrollmentAttachment } from "state/queries/useUpdateEnrollmentAttachment";

type Props = {
  enrollment?: IEmployeeEnrollment;
  packageName?: string;
};

export function EnrollmentConsents({ enrollment, packageName }: Props) {
  const { t } = useTranslation();
  const [manageDocumentOpen, setManageDocumentOpen] = useState(false);
  const attachmentTypeOptions = useTaxonomyOptions(ETaxonomy.AttachmentType);

  const consentAttachment = attachmentTypeOptions.find(
    (item) => item.code === EAttachmentType.EMPLOYEE_CONSENT
  )?.value;

  const onManageDocumentsOpenStateChange = useCallback((state) => {
    setManageDocumentOpen(state);
  }, []);

  const { mutate: onDeleteAttachment, isLoading: deleteAttchInProgress } =
    useDeleteEnrollmentAttachment();

  const {
    mutateAsync: onCreateAttachment,
    isLoading: createAttachmentInProgress,
  } = useCreateEnrollmentAttachment();

  const { mutate: onUpdateAttachment, isLoading: updateInProgress } =
    useUpdateEnrollmentAttachment();

  const handleDeleteAttachment = useCallback(
    (attach: Attachment) => {
      onDeleteAttachment(
        {
          attachmentName: attach.attachmentName || "",
          benefitImplementationTag: Number(
            enrollment?.benefitImplementationTag
          ),
          benefitPackageTag: Number(enrollment?.benefitPackageTag),
          employeeEnrollmentTag: Number(enrollment?.employeeEnrollmentTag),
          employeeLoginEmailAddress:
            enrollment?.employeeLoginEmailAddress || "",
          clientInternalNumberGOS: enrollment?.clientInternalNumberGos || 0,
          countryCode: enrollment?.countryCode || 0,

          rowVersion: attach.rowVersion || "",
        },
        {
          onSuccess: () => {
            toast.success(t("hbh.documentDeletedSuccessfully.label"));
          },
        }
      );
    },
    [enrollment]
  );

  const handleUpdateAttachment = useCallback(
    ({
      attachment,
      newValues,
    }: {
      attachment: Attachment;
      newValues: {
        attachmentName: string;
        attachmentSummary: string;
        attachmentAbstract: string;
        isHiddenForEmployee: boolean;
      };
    }) => {
      onUpdateAttachment(
        {
          attachmentName: attachment.attachmentName || "",
          benefitImplementationTag: Number(
            enrollment?.benefitImplementationTag
          ),
          benefitPackageTag: Number(enrollment?.benefitPackageTag),
          employeeEnrollmentTag: Number(enrollment?.employeeEnrollmentTag),
          employeeLoginEmailAddress:
            enrollment?.employeeLoginEmailAddress || "",
          clientInternalNumberGOS: enrollment?.clientInternalNumberGos || 0,
          countryCode: enrollment?.countryCode || 0,
          payload: {
            ...attachment,
            // rowVersion: item.rowVersion || "",
            attachmentName: newValues?.attachmentName,
            attachmentSummary: newValues?.attachmentSummary,
            attachmentAbstract: newValues?.attachmentAbstract,
            // isHiddenForEmployee: newValues?.isHiddenForEmployee,
            attachmentType: consentAttachment,
          },
        },
        {
          onSuccess: () => {
            toast.success(t("hbh.documentUpdatedSuccessfully.label"));
          },
        }
      );
    },
    [enrollment]
  );
  const handleCreateAttachment = useCallback(
    (
      newAttach: IFormattedFile | null,
      isHiddenForEmployee?: boolean,
      newAttachList?: IFormattedFileExtended[],
      onSuccessFn?: () => void
    ) => {
      try {
        if (newAttachList) {
          const promiseArray = [] as Promise<any>[];
          newAttachList.forEach((attachment) => {
            promiseArray.push(
              onCreateAttachment({
                benefitImplementationTag: Number(
                  enrollment?.benefitImplementationTag
                ),
                benefitPackageTag: Number(enrollment?.benefitPackageTag),
                employeeEnrollmentTag: Number(
                  enrollment?.employeeEnrollmentTag
                ),
                employeeLoginEmailAddress:
                  enrollment?.employeeLoginEmailAddress || "",
                clientInternalNumberGOS:
                  enrollment?.clientInternalNumberGos || 0,
                countryCode: enrollment?.countryCode || 0,
                payload: {
                  attachmentName: attachment?.name || "",
                  attachmentContent: attachment?.base64String || "",
                  attachmentMimeType: attachment?.type || "",
                  //   isHiddenForEmployee,
                  attachmentType: consentAttachment,

                  // rowVersion: item.rowVersion || "",
                },
              })
            );
          });

          Promise.all(promiseArray).then(() => {
            onSuccessFn && onSuccessFn();
            toast.success(t("hbh.documentCreatedSuccessfully.label"));
          });
          return;
        }
        onCreateAttachment(
          {
            benefitImplementationTag: Number(
              enrollment?.benefitImplementationTag
            ),
            benefitPackageTag: Number(enrollment?.benefitPackageTag),
            employeeEnrollmentTag: Number(enrollment?.employeeEnrollmentTag),
            employeeLoginEmailAddress:
              enrollment?.employeeLoginEmailAddress || "",
            clientInternalNumberGOS: enrollment?.clientInternalNumberGos || 0,
            countryCode: enrollment?.countryCode || 0,
            payload: {
              attachmentName: newAttach?.name || "",
              attachmentContent: newAttach?.base64String || "",
              attachmentMimeType: newAttach?.type || "",
              //   isHiddenForEmployee,
              attachmentType: consentAttachment,

              // rowVersion: item.rowVersion || "",
            },
          },
          {
            onSuccess: () => {
              onSuccessFn && onSuccessFn();

              toast.success(t("hbh.documentCreatedSuccessfully.label"));
            },
          }
        );
      } catch (error) {
        console.log(error, "error in benefit card list");
      }
    },
    [enrollment]
  );

  const enrollmentsAttachments = enrollment?.attachments || [];
  const attachNumber = enrollmentsAttachments.length;
  return (
    <>
      {/* <AddAttachments
        title="hbh.consentDocuments.label"
        attachHiddenForEmployee={true}
        attachItemFilterValue={consentAttachment}
        attachType={consentAttachment}
      /> */}

      <ActionButton
        icon={<ArrowExportRegular />}
        noBorder
        appearance="subtle"
        onClick={() => {
          onManageDocumentsOpenStateChange(true);
        }}
      >
        {t("hbh.consentDocuments.label")}
        {attachNumber
          ? ` (${attachNumber} ${t("hbh.items.label")})`
          : ` (${t("hbh.noData.label")}) `}
      </ActionButton>
      {manageDocumentOpen && enrollment && (
        <ManageAttachmentsDialog
          showIsAttachHiddenForEmployeeField={false}
          attachmentActionsState={{
            createAttachmentInProgress,
            onCreateAttachment: handleCreateAttachment,
            updateInProgress,
            onUpdateAttachment: handleUpdateAttachment,
            deleteInProgress: deleteAttchInProgress,
            onDeleteAttachment: handleDeleteAttachment,
          }}
          item={{ ...enrollment, benefitPackageName: packageName }}
          open={manageDocumentOpen}
          setOpen={onManageDocumentsOpenStateChange}
        />
      )}
    </>
  );
}
