import { Button } from "@fluentui/react-components";
import { PersonEdit24Regular, SendPersonRegular } from "@fluentui/react-icons";
import { ActionButton, StyledTooltip } from "components/styled";
import { useAppTheme } from "hooks/useAppTheme";
import { useRoleType } from "hooks/useRoleType";
import { EmployeeRowItem, IEmployeeView } from "library/types";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useResendEmployeeInvitation } from "state/queries/useResendEmployeeInvitation";
import styled from "styled-components";
import { DeletePackageUser } from "./DeletePackageUser";
import { EmployeeEligibility } from "./EmployeeEligibility/EmployeeEligibility";
import { ManageEmployee } from "./ManageEmployee/ManageEmployee";
import { EmployeeDependentsDialog } from "./ManageEmployee/UpdateEmployeeFields/EmployeeDependentsDialog/EmployeeDependentsDialog";
import { useParams } from "react-router-dom";
import { ResendEmployeeInvitation } from "./ResendEmployeeInvitation";
import { ExportConsents } from "pages/HrPage/components/ExportConsents/ExportConsents";

type Props = {
  employeeData: EmployeeRowItem;
  dependentData?: IEmployeeView;
  countryCode?: number;
  stopPropagation?: boolean;
  clientInternalNumberGOS?: number;
  showResendInvitation?: boolean;
  showDeleteOption?: boolean;
  showManagePackageEligibilityOption?: boolean;
  showExportConsents?: boolean;
};

export function EmployeeActions({
  employeeData,
  dependentData,
  stopPropagation,
  showResendInvitation = true,
  showExportConsents = true,
  showDeleteOption = true,
  showManagePackageEligibilityOption = true,
}: Props) {
  const { t } = useTranslation();
  const { palette } = useAppTheme();
  const [manageDependentsOpen, setManageDependentsOpen] = useState(false);

  const isReadOnlyUser = useRoleType()?.hasReadOnlyRole;

  const params = useParams();
  const packageId = params.packageId;

  const employeeHasPackageEnrollment = useMemo(() => {
    if (packageId !== undefined) {
      return (
        employeeData.enrollments?.some(
          (el) => el.benefitPackageTag === Number(packageId)
        ) ?? false
      );
    }
    return false;
  }, [employeeData.enrollments, packageId]);

  const dependentHasPackageEnrollment = useMemo(() => {
    if (packageId !== undefined) {
      return (
        dependentData?.enrollments?.some(
          (el) => el.benefitPackageTag === Number(packageId)
        ) ?? false
      );
    }
    return false;
  }, [employeeData.enrollments, packageId]);

  const { mutate: onResendInvitation, isLoading: sendInvitationInProgress } =
    useResendEmployeeInvitation();

  const onManageDependentsOpen = useCallback((value) => {
    setManageDependentsOpen(value);
  }, []);

  const disableResendInvitationEmail =
    !employeeData?.selected || sendInvitationInProgress;

  const dependentNeedsAttention = dependentData?.needsAttentionInformation;

  const isNewDependent = dependentNeedsAttention
    ?.toLowerCase()
    ?.includes("new");

  return (
    <div
      onClick={(e) => {
        if (employeeData.selected) {
          e.stopPropagation();
        }
      }}
    >
      <ActionsWrap>
        {dependentData || !showResendInvitation ? null : (
          <ResendEmployeeInvitation employeeData={employeeData} />
        )}
        {dependentData || !showExportConsents ? null : (
          <ExportConsents
            employeeEmail={employeeData?.employeeLoginEmailAddress}
            employeeName={`${employeeData?.firstName} ${employeeData?.lastName}`}
          />
        )}
        {dependentData ? (
          <StyledTooltip
            relationship="label"
            content={{
              children: t("hbh.edit.label"),
            }}
          >
            <ActionButton
              disabled={
                isReadOnlyUser ||
                (!showManagePackageEligibilityOption &&
                  !dependentNeedsAttention)
              }
              icon={<PersonEdit24Regular />}
              style={{
                ...(dependentNeedsAttention
                  ? (() => {
                      if (isNewDependent) {
                        return { color: palette.green };
                      }
                      return { color: palette.yellowDark };
                    })()
                  : {}),
                minWidth: 24,
                padding: 1,
              }}
              noBorder
              appearance="subtle"
              onClick={(e) => {
                setManageDependentsOpen(true);
              }}
            />
          </StyledTooltip>
        ) : (
          <ManageEmployee
            item={employeeData}
            disabled={!!employeeData.disabled || isReadOnlyUser}
            showManagePackageEligibilityOption={
              showManagePackageEligibilityOption
            }
          />
        )}

        {/* <EmployeeEnrollments item={item} /> */}
        {showManagePackageEligibilityOption && (
          <EmployeeEligibility
            employeeData={employeeData}
            dependentData={dependentData}
            disabled={
              (employeeData !== undefined &&
                dependentData === undefined &&
                employeeHasPackageEnrollment) ||
              (dependentData !== undefined && dependentHasPackageEnrollment)
            }
          />
        )}
        {showDeleteOption && (
          <DeletePackageUser
            employeeData={employeeData}
            dependentData={dependentData}
            closeDialog={() => {
              onManageDependentsOpen(false);
            }}
          />
        )}
      </ActionsWrap>

      {manageDependentsOpen && dependentData && (
        <EmployeeDependentsDialog
          singleDependentEdit
          employeeData={employeeData}
          open={manageDependentsOpen}
          setOpen={onManageDependentsOpen}
          dependentData={dependentData}
        />
      )}
    </div>
  );
}

const ActionsWrap = styled.div`
  display: flex;
  gap: 5px;
  width: 100%;
  justify-content: center;
`;
