import { useAtomValue } from "jotai";
import { SelectItem } from "library/types";
import { useMemo } from "react";
import { useGetCompanyBenefitPackages } from "state/queries/useGetCompanyBenefitPackages";
import { selectedCompanyState, selectedCountryState } from "store/UIHrPage";

type Props = {
  isAddMode: boolean;
  employeeLoginEmailAddress: string;
  dependent?: any;
  enrollments: any[];
  eligibilities: any[];
  benefitPackageTag?: number;
};
export function useAddEnrollmentPackageOptions({
  isAddMode,
  enrollments,
  eligibilities,
  benefitPackageTag,
}: Props): {
  options: SelectItem[];
  optionsLoading: boolean;
} {
  const selectedCountry = useAtomValue(selectedCountryState);
  const selectedCompany = useAtomValue(selectedCompanyState);

  const { data: companyPackages, isLoading: companyPackagesLoading } =
    useGetCompanyBenefitPackages({
      countryCode: Number(selectedCountry?.value),
      clientInternalNumberGOS: Number(selectedCompany?.clientInternalNumberGos),
      enabled: !!isAddMode && !!selectedCountry,
    });

  const filteredOptions = useMemo(() => {
    if (!companyPackages || !enrollments || !eligibilities) return [];

    if (benefitPackageTag) {
      return companyPackages
        .filter(
          (packageItem) => packageItem.benefitPackageTag === benefitPackageTag
        )
        .map((item) => ({
          value: item?.benefitPackageTag || "",
          text: item?.name || "",
          ...item,
        }));
    }

    return companyPackages
      .filter(
        (packageItem) =>
          !enrollments.some(
            (employeeEnrollment) =>
              employeeEnrollment.benefitPackageTag ===
              packageItem.benefitPackageTag
          ) &&
          eligibilities.some(
            (employeeEligibility) =>
              employeeEligibility.benefitPackageTag ===
              packageItem.benefitPackageTag
          )
      )
      .map((item) => ({
        value: item?.benefitPackageTag || "",
        text: item?.name || "",
        ...item,
      }));
  }, [companyPackages, enrollments, eligibilities]);

  return {
    options: isAddMode ? filteredOptions : [],
    optionsLoading: companyPackagesLoading,
  };
}
