import React from "react";
import { useAtom, useAtomValue } from "jotai";
import { useAppTheme } from "hooks/useAppTheme";
import GenericTree from "components/GenericTree/GenericTree";
import { colorList } from "pages/HrPage/components/BulkManagementView/colorList";
import { ICompanyInfosInfo } from "library/types";
import {
  expandedBenefitsAtom,
  periodFromDateAtom,
  periodToDateAtom,
  selectedBenefitsAtom,
} from "store/UIHrPageDashboard";
import { Button, Tooltip } from "@fluentui/react-components";
import { useTranslation } from "react-i18next";
import {
  ArrowRight16Regular,
  ArrowDownRight16Regular,
  LineFlowDiagonalUpRight16Filled,
  ArrowFlowDiagonalUpRight16Filled,
  ArrowFlowDiagonalUpRight16Regular,
  Flow32Filled,
  Flow16Filled,
  Flow16Regular,
} from "@fluentui/react-icons";
import moment from "moment";

/**
 * PackagesTree Component - Uses GenericTree to render benefits hierarchy.
 */

interface Props {
  companyInfo?: ICompanyInfosInfo;
  isLoading: boolean;
}

const FilterPackages = ({ companyInfo, isLoading }: Props) => {
  const [selectedItems, setSelectedItems] = useAtom(selectedBenefitsAtom);
  const [expandedItems, setExpandedItems] = useAtom(expandedBenefitsAtom);
  const { t } = useTranslation();
  const periodFromDate = useAtomValue(periodFromDateAtom);
  const periodToDate = useAtomValue(periodToDateAtom);

  const handleSelectDeselectAll = () => {
    if (selectedItems.size === treeData.length) {
      // Deselect all
      setSelectedItems(new Set());
    } else {
      // Select all
      const allIds = treeData.map((node) => node.id);
      setSelectedItems(new Set(allIds));
    }
  };

  const filteredEmployeeEnrollments = React.useMemo(() => {
    return companyInfo?.employeeEnrollments
      ?.filter((en) => {
        return en.dateOfEnrollment !== null;
      })

      .map((en) => {
        const dol = en.dateOfEligibility
          ? new Date(en.dateOfEligibility)
          : null;
        const doe = en.dateOfEnrollment ? new Date(en.dateOfEnrollment) : null;
        const doc = en.dateOfCancellation
          ? new Date(en.dateOfCancellation)
          : null;
        const ps = new Date(periodFromDate);
        const pe = new Date(periodToDate);

        const isNewlyEligible = dol ? dol >= ps && dol <= periodToDate : false;
        const isNewlyEnrolled = doe ? doe >= ps && doe <= periodToDate : false;
        const isNewlyCancelled = doc ? doc >= ps && doc <= periodToDate : false;

        const isActiveInPeriod = doe
          ? (doe < ps &&
              (doc === null || doc >= ps) &&
              (doc === null || doc < pe)) ||
            (doe >= ps && doe < pe && (doc === null || doc >= pe)) ||
            (doe >= ps && (doc === null || doc < pe)) ||
            (doe < ps && (doc === null || doc >= pe))
          : false;

        return {
          ...en,
          isNewlyEligible,
          isNewlyEnrolled,
          isNewlyCancelled,
          isActiveInPeriod,
        };
      });
  }, [companyInfo, periodFromDate, periodToDate]);

  const treeData = React.useMemo(() => {
    const result: any[] = [];
    if (!companyInfo) {
      return result;
    }
    // Creating category nodes
    const categories = {};
    companyInfo.benefitCategories?.forEach((benefitPackage) => {
      const categoryId = benefitPackage.benefitCategoryTag + "_category";
      if (!categories[categoryId]) {
        categories[categoryId] = {
          id: categoryId,
          content: benefitPackage.name,
          parentId: undefined,
          dataItem: benefitPackage,
        };
        result.push(categories[categoryId]);
      }
    });

    // Creating implementation nodes
    const implementations: any = {};
    companyInfo.benefitImplementations
      ?.filter(
        (item) =>
          moment(item.validFrom).isBefore(moment(periodToDate)) &&
          moment(item.validTo).isAfter(moment(periodFromDate))
      )
      .forEach((benefitPackage) => {
        const implementationId =
          benefitPackage.benefitImplementationTag + "_implementation";
        const categoryId = benefitPackage.benefitCategoryTag + "_category";
        if (!implementations[implementationId]) {
          implementations[implementationId] = {
            id: implementationId,
            content: benefitPackage.name,
            parentId: categoryId,
            dataItem: benefitPackage,
          };
          result.push(implementations[implementationId]);
        }
      });

    // Creating package nodes
    companyInfo.benefitPackages?.forEach((benefitPackage) => {
      const eligibleCount = companyInfo.employeeEnrollments?.filter(
        (enrol) => enrol.benefitPackageTag === benefitPackage.benefitPackageTag
      ).length;
      const enrolledCount = companyInfo.employeeEnrollments?.filter(
        (enrol) =>
          enrol.benefitPackageTag === benefitPackage.benefitPackageTag &&
          enrol.dateOfEnrollment !== null
      ).length;

      const isNewlyEligibleCount = filteredEmployeeEnrollments?.filter(
        (enrol: any) =>
          enrol.benefitPackageTag === benefitPackage.benefitPackageTag &&
          enrol.isNewlyEligible
      ).length;

      const isNewlyEnrolledCount = filteredEmployeeEnrollments?.filter(
        (enrol: any) =>
          enrol.benefitPackageTag === benefitPackage.benefitPackageTag &&
          enrol.isNewlyEnrolled
      ).length;

      const isNewlyCancelledCount = filteredEmployeeEnrollments?.filter(
        (enrol: any) =>
          enrol.benefitPackageTag === benefitPackage.benefitPackageTag &&
          enrol.isNewlyCancelled
      ).length;

      const isActiveInPeriodCount = filteredEmployeeEnrollments?.filter(
        (enrol: any) =>
          enrol.benefitPackageTag === benefitPackage.benefitPackageTag &&
          enrol.isActiveInPeriod
      ).length;

      result.push({
        id: benefitPackage.benefitPackageTag + "_package",
        content: (
          <div>
            <div>
              {benefitPackage.name}
              <Tooltip
                content={t("hbh.dashboard.enrolledEligible.label")}
                relationship="label"
              >
                <span>{" (" + enrolledCount + "/" + eligibleCount + ")"}</span>
              </Tooltip>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                fontSizeAdjust: "0.5",
                opacity: 0.6,
              }}
            >
              <Tooltip
                content={t("hbh.dashboard.newlyEligible.label")}
                relationship="description"
                withArrow
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "4px",
                  }}
                >
                  <ArrowFlowDiagonalUpRight16Regular />
                  <span style={{ marginRight: "4px" }}>
                    {isNewlyEligibleCount}
                  </span>
                </div>
              </Tooltip>
              <Tooltip
                content={t("hbh.dashboard.newlyEnrolled.label")}
                relationship="description"
                withArrow
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "4px",
                  }}
                >
                  <ArrowFlowDiagonalUpRight16Filled />
                  <span style={{ marginRight: "4px" }}>
                    {isNewlyEnrolledCount}
                  </span>
                </div>
              </Tooltip>
              <Tooltip
                content={t("hbh.dashboard.activeInPeriod.label")}
                relationship="description"
                withArrow
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "4px",
                  }}
                >
                  <Flow16Filled />
                  <span style={{ marginRight: "4px" }}>
                    {isActiveInPeriodCount}
                  </span>
                </div>
              </Tooltip>
              <Tooltip
                content={t("hbh.dashboard.newlyCanceled.label")}
                relationship="description"
                withArrow
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "4px",
                  }}
                >
                  <Flow16Regular />
                  <span style={{ marginRight: "4px" }}>
                    {isNewlyCancelledCount}
                  </span>
                </div>
              </Tooltip>
            </div>
          </div>
        ),
        parentId: benefitPackage.benefitImplementationTag + "_implementation",
        dataItem: benefitPackage,
      });
    });

    return result;
  }, [companyInfo, filteredEmployeeEnrollments]);

  React.useEffect(() => {
    if (companyInfo) {
      const allIds = treeData.map((node) => node.id);
      setSelectedItems(new Set(allIds));
    }
  }, [companyInfo, treeData]);

  const colorAssignments = React.useMemo(() => {
    const retVal: any = {};
    if (!companyInfo) {
      return retVal;
    }

    let colorGroupIndex = 0;
    let colorIndex = 0;

    companyInfo.benefitPackages?.forEach((item) => {
      const colorGroup: any = colorList[colorGroupIndex];
      const color = colorGroup?.colors[colorIndex] ?? {
        colorName: "",
        hexValue: "#ffffff",
        textColor: "#000000",
      };

      retVal[item.benefitPackageTag as number] = {
        colorGroup: colorGroup.toneGroupName,
        color: color.colorName,
        hexValue: color.hexValue,
        textColor: color.textColor,
      };

      colorIndex++;

      if (colorIndex >= colorGroup.colors.length) {
        colorIndex = 0;
        colorGroupIndex = (colorGroupIndex + 1) % colorList.length;
      }
    });

    return retVal;
  }, [companyInfo]);
  return (
    <GenericTree
      selectedItems={selectedItems}
      expandedItems={expandedItems}
      isLoading={false} // isLoading}
      treeData={treeData}
      onNodeSelect={(id, selectedItems) => setSelectedItems(selectedItems)}
      onNodeExpand={(id, expandedItems) => setExpandedItems(expandedItems)}
      colorAssignments={colorAssignments}
      heightOffset={600}
      rowHeight="auto"
      childRowHeight="auto"
      additionalButtons={
        <>
          <Button
            size="small"
            appearance="primary"
            title={
              selectedItems.size === treeData.length
                ? t("hbh.filterPackages.deselectAll.title")
                : t("hbh.filterPackages.selectAll.title")
            }
            onClick={handleSelectDeselectAll}
            disabled={isLoading}
            style={{ marginRight: "8px" }}
          >
            {selectedItems.size === treeData.length
              ? t("hbh.filterPackages.deselectAll.button")
              : t("hbh.filterPackages.selectAll.button")}
          </Button>
        </>
      }
    />
  );
};

export default FilterPackages;
