import { AppSpinner } from "components/AppSpinner/AppSpinner";
import { PageSkeleton } from "components/Skeletons/PageSkeleton";
import { PageContent } from "components/styled";
import { useTaxonomyOptions } from "hooks/useTaxonomyOptions";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { ETaxonomy } from "library/types";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useGetBenefitCategory } from "state/queries/useGetBenefitCategory";
import { useGetBenefitImplementations } from "state/queries/useGetBenefitImplementations";
import { getSingleClient } from "state/queries/useGetSingleClient";
import {
  filteredBenefitImplementationState,
  implementationSearchFilterValueState,
  onlyActiveImplementationsState,
  selectedCompanyState,
  selectedCountryState,
} from "store/UICompanyPage";
import { CompanyImplementationSubheader } from "./components/CompanyImplementationSubheader/CompanyImplementationSubheader";
import { CompanyImplementationSubheaderToolbar } from "./components/CompanyImplementationSubheader/CompanyImplementationSubheaderToolbar";
import { ManageBenefitImplementationsView } from "./components/ManageBenefitImplementationsView/ManageBenefitImplementationsView";

export function CompanyBenefitImplementationPage() {
  const params = useParams();
  const categoryId = params.categoryId;
  const countryCode = params.countryCode;
  const clientInternalNumberGOS = params.clientInternalNumberGOS;
  const countryOptions = useTaxonomyOptions(ETaxonomy.Country);
  const onlyActiveImplementations = useAtomValue(
    onlyActiveImplementationsState
  );

  const searchFilterValue = useAtomValue(implementationSearchFilterValueState);

  const [filteredBenefitImplementation, setFilteredBenefitImplementation] =
    useAtom(filteredBenefitImplementationState);

  const setSelectedCompany = useSetAtom(selectedCompanyState);
  const setSelectedCountry = useSetAtom(selectedCountryState);

  const selectedCountry = countryOptions.find(
    (option) => option.value === Number(countryCode)
  );

  const { data: benefitCategory, isLoading: benefitCategoryLoading } =
    useGetBenefitCategory({
      countryCode: Number(countryCode),
      benefitCategoryTag: Number(categoryId),
      clientInternalNumberGOS: Number(clientInternalNumberGOS),

      enabled: !!countryCode && !!clientInternalNumberGOS && !!categoryId,
    });

  const {
    data: implementedBenefits,
    isLoading: implementationLoading,
    isRefetching: implementationRefetching,
  } = useGetBenefitImplementations({
    countryCode: Number(countryCode),
    clientInternalNumberGOS: Number(clientInternalNumberGOS),
    benefitCategoryTag: Number(categoryId),
    onlyActive: onlyActiveImplementations,
  });

  const getAndSetSelectedCompany = async () => {
    const company = await getSingleClient({
      countryCode: Number(countryCode),
      internalNumber: Number(clientInternalNumberGOS),
    });

    setSelectedCompany(company);
  };

  useEffect(() => {
    if (!implementedBenefits) return;
    setFilteredBenefitImplementation(
      implementedBenefits?.filter((item) =>
        item?.name?.toLowerCase().includes(searchFilterValue)
      )
    );
  }, [
    implementedBenefits,
    searchFilterValue,
    setFilteredBenefitImplementation,
  ]);

  useEffect(() => {
    if (selectedCountry) {
      setSelectedCountry(selectedCountry);
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (countryCode && clientInternalNumberGOS) {
      getAndSetSelectedCompany();
    }
  }, [countryCode, clientInternalNumberGOS]);

  const isLoading = benefitCategoryLoading || implementationLoading;

  if (isLoading || !benefitCategory) return <PageSkeleton />;

  return (
    <>
      {implementationRefetching && <AppSpinner />}
      <CompanyImplementationSubheader
        disabled={!implementedBenefits?.length}
        categoryName={benefitCategory?.name}
        items={filteredBenefitImplementation || []}
      />
      <CompanyImplementationSubheaderToolbar category={benefitCategory} />
      <PageContent>
        <ManageBenefitImplementationsView
          benefitCategory={benefitCategory}
          implementedBenefits={filteredBenefitImplementation || []}
        />
      </PageContent>
    </>
  );
}
