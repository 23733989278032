// /benefitcategory/attachment/{countryCode}/{clientInternalNumberGos}/{benefitCategoryTag}:
import useNotificationHandler from "hooks/useNotificationHandler";
import { MUTATION_KEYS, QUERY_KEYS } from "library/shared";
import { Attachment } from "library/types";
import { useMutation, useQueryClient } from "react-query";
import api from "service/api";
import { useInvalidateEmployeeEnrollment } from "./useGetEmployeeEnrollment";
import { useInvalidateDependentEnrollment } from "./useGetDependentEnrollment";

type Params = {
  countryCode: number | string;
  benefitImplementationTag: number | string;
  benefitPackageTag: number | string;
  employeeEnrollmentTag: number | string;
  employeeLoginEmailAddress: string;

  clientInternalNumberGOS: number | string;
  attachmentName: string;
  rowVersion: string;
};

const deleteAttachment = async ({
  countryCode,
  clientInternalNumberGOS,
  benefitImplementationTag,
  benefitPackageTag,
  employeeEnrollmentTag,
  employeeLoginEmailAddress,
  attachmentName,
  rowVersion,
}: Params) => {
  const response = await api.patch(
    `/employeeenrollment/attachment/delete/${countryCode}/${clientInternalNumberGOS}/${benefitImplementationTag}/${benefitPackageTag}/${employeeLoginEmailAddress}/${employeeEnrollmentTag}/${attachmentName}`,
    {
      rowVersion,
    }
  );
  return response.data as Attachment;
};

export function useDeleteEnrollmentAttachment(
  refetch = true,
  isDependent = false
) {
  const { handleAddAxiosErrorNotification } = useNotificationHandler();
  const invalidateEmployeeEnrollment = useInvalidateEmployeeEnrollment();
  const invalidateDependentEnrollment = useInvalidateDependentEnrollment();
  return useMutation(deleteAttachment, {
    mutationKey: MUTATION_KEYS.deleteEnrollmentAttachment,
    onSuccess: () => {
      if (refetch) {
        if (isDependent) {
          invalidateDependentEnrollment();
          return;
        }
        invalidateEmployeeEnrollment();
      }
      // queryClient.invalidateQueries({ queryKey: QUERY_KEYS.benefitCategories });
    },
    onError: (error) => {
      // handle error
      handleAddAxiosErrorNotification(error);
    },
  });
}
