import {
  Button,
  Checkbox,
  Tab,
  TabList,
  Tooltip,
} from "@fluentui/react-components";
import { devices } from "library/constants";
import { Link } from "react-router-dom";
import styled, { css, CSSProperties } from "styled-components";

export const Flex = styled.div<{
  gap?: CSSProperties["gap"];
  direction?: "row" | "column";
  justify?: "center" | "flex-end" | "flex-start" | "space-between";
  $align?: "center" | "flex-end" | "flex-start" | "normal";
  wrap?: "nowrap" | "wrap";
  width?: CSSProperties["width"];
}>`
  display: flex;
  align-items: ${(p) => p?.$align || "center"};
  gap: ${(p) => p.gap || "5px"};
  width: ${(p) => p.width || "auto"};
  flex-direction: ${(p) => p.direction || "row"};
  justify-content: ${(p) => p.justify || "flex-start"};
  flex-wrap: ${(p) => p.wrap || "wrap"};
`;

export const StyledNavigationBar = styled.div`
  height: 44px;
  background: ${(p) => p.theme.palette.white};
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 10px;
  border-bottom: 1px solid ${(p) => p.theme.palette.neutralLight};
  position: sticky;
  top: 0;
  z-index: 333;
`;

export const StyledCheckbox = styled(Checkbox)<{ background?: string }>`
  & > label > div {
    width: 16px;
    height: 16px;
    margin-top: 2px;
  }
  & > label > span {
    font-size: 14px;
    font-weight: 600;
  }
`;

export const CenteredCell = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledLink = styled(Link)`
  flex: 1;
  text-align: left;
  min-width: 0;
  text-decoration: underline;

  color: ${(p) => p.theme.palette.neutralPrimary};
  &:visited {
    color: ${(p) => p.theme.palette.neutralPrimary};
  }
  &:hover {
    color: ${(p) => p.theme.palette.themePrimary};
  }
`;

export const RightSection = styled.div`
  margin-left: auto;
`;

export const StyledErrorMessage = styled.span`
  color: ${({ theme }) => theme.semanticColors.errorText};
  font-size: 12;
`;

export const ErrorMessageContainer = styled.span`
  height: 20px;
`;

export const FormFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2px;
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const PageContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 10px 10px 0;
  margin: 5px auto 0;
  box-sizing: border-box;
  gap: 0px;
  @media only screen and ${devices.md} {
    justify-content: flex-start;
    padding: 10px 20px;
    width: 100%;
  }
`;

export const ActionButton = styled(Button)<{ noBorder?: boolean }>`
  ${({ disabled, noBorder, theme }) =>
    !disabled &&
    css`
      display: flex;
      align-items: center;

      max-width: fit-content;
      border: 1px solid
        ${() => (noBorder ? "transparent" : theme.palette.themePrimary)};
      color: ${(props) => props.theme.palette.themePrimary};
      background: transparent;
      height: fit-content;
    `}
`;

export const RemoveButton = styled(Button)`
  ${({ disabled }) =>
    !disabled &&
    css`
      display: flex;
      align-items: center;

      max-width: fit-content;
      border: 1px solid ${({ theme }) => theme.palette.red};
      color: ${(props) => props.theme.palette.red};
      background: transparent;
      height: fit-content;
      &:hover {
        opacity: 0.7;
        background: ${({ theme }) => theme.palette.red};
        color: ${(props) => props.theme.palette.white};
      }
    `}
`;

export const Grid = styled.div<{ $gridColumns?: number }>`
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
  margin-top: 20px;
  @media only screen and ${devices.md} {
    grid-template-columns: ${({ $gridColumns }) =>
      `repeat(${$gridColumns || 3}, 1fr)`};
  }
`;

export const Section = styled.div`
  flex: 1;
  min-width: 0;
  padding: 20px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  height: calc(
    100vh - 210px
  ); /* Adjust based on the height of Subheader or other fixed components */
  overflow: hidden; /* Prevent content overflow */

  @media (max-width: 768px) {
    width: 100%;
    height: auto; /* Allow it to expand in height on smaller screens */
  }
`;

export const FooterSection = styled.div`
  flex: 1;
  min-width: 0;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  overflow: hidden; /* Prevent content overflow */
  margin-top: 10px;

  @media (max-width: 768px) {
    width: 100%;
    height: auto; /* Allow it to expand in height on smaller screens */
  }
`;

export interface SectionItemProps {
  isflexible?: string;
}

export const SectionItem = styled.div<SectionItemProps>`
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;

  /* If isflexible prop is passed, the section becomes flexible */
  flex: ${({ isflexible }) => (isflexible === "true" ? "1" : "none")};
  display: flex;
  flex-direction: column;
  overflow: hidden; /* Ensure contents like the tree don't overflow */

  &:last-child {
    margin-bottom: 0; /* No margin for the last section */
  }
`;
export const ScrollableTree = styled.div`
  flex: 1;
  overflow-y: auto;
`;

export const SectionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const StyledTooltip = styled(Tooltip)`
  font-size: 14px;
  line-height: 20px;
  width: 300px;
  max-width: 300px;
`;

export const ToolbarContainer = styled.div<{ $mobileTop?: string }>`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  width: 100%;
  margin: auto;
  position: sticky;
  overflow: hidden;
  z-index: 90;
  top: ${({ $mobileTop }) => $mobileTop || "52px"};
  background-color: ${(props) => props.theme.palette.white};
  border-top: 1px solid ${(props) => props.theme.palette.neutralLight};
  // border-bottom: 1px solid ${(props) => props.theme.palette.neutralLight};
  box-shadow: 16px 0px 32px 0px ${(props) => props.theme.palette.neutralLight};
  max-height: 100%;
  box-sizing: border-box;
  justify-content: space-between;
  gap: 5px;
  @media only screen and ${devices.md} {
    flex-direction: row;
    padding: 10px 50px;

    top: 52px;
    height: 52px;
  }
`;

export const StyledTab = styled(Tab)`
  padding: 7px 10px;

  &:after {
    background-color: ${(props) => props.theme.palette.themePrimary};
  }
`;

export const CardListWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  width: 100%;
  flex-wrap: wrap;
  flex-direction: column;
  box-sizing: border-box;
  gap: 20px;
  width: 100%;

  @media only screen and ${devices.md} {
    grid-template-columns: repeat(2, 1fr);

    flex-direction: row;

    justify-content: flex-start;
    gap: 30px;
  }
  @media only screen and ${devices.lg} {
    grid-template-columns: repeat(3, 1fr);
  }
  @media only screen and ${devices.xl} {
    grid-template-columns: repeat(4, 1fr);
  }
  @media only screen and ${devices["2xl"]} {
    grid-template-columns: repeat(5, 1fr);
  }
`;

export const StyledTabList = styled(TabList)`
  overflow-x: auto;
  padding: 10px 0px;

  @media only screen and ${devices.md} {
    padding: 0;
  }
`;

export const PaginationWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  width: 100%;
  padding-right: 10px;
`;
