import { Button } from "@fluentui/react-components";
import {
  DeleteRegular,
  EyeTrackingOffRegular,
  EyeTrackingRegular,
} from "@fluentui/react-icons";
import { ButtonWithTooltip } from "components/ButtonWithTooltip/ButtonWithTooltip";
import { Flex } from "components/styled";
import { UploadFileList } from "components/Upload/UploadFileList";
import { useFormikContext } from "formik";
import { useTaxonomyOptions } from "hooks/useTaxonomyOptions";
import { EAttachmentType, ETaxonomy } from "library/types";
import { genUUID } from "library/utils";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

type Props = {
  attachHiddenForEmployee?: boolean;
  attachItemFilterValue?: any;
  title?: string;
  attachType?: number;
};

export function AddAttachments({
  attachHiddenForEmployee,
  attachItemFilterValue,
  attachType,
  title,
}: Props) {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<FormData>();

  const attachmentTypeOptions = useTaxonomyOptions(ETaxonomy.AttachmentType);

  const normalAttach = attachmentTypeOptions.find(
    (item) => item.code === EAttachmentType.NORMAL
  )?.value;

  const attachmentList = values["attachments"] || [];

  const attachmentType = attachType || normalAttach;

  return (
    <div>
      <Title>{t(title ? title : "hbh.documentList.label")}</Title>
      <AddNewAttachmentList>
        <UploadFileList
          selectedFileList={attachmentList.map((attach) => ({
            name: attach.attachmentName,
            base64String: attach.attachmentContent,
            type: attach.attachmentMimeType,
            attachmentType,
          }))}
          setFieldValue={(_, uploadedList) => {
            const updatedAttachments = [
              ...attachmentList,
              ...(uploadedList || []).map((newAttach) => ({
                attachmentName: newAttach?.name,
                attachmentContent: newAttach?.base64String || "",
                attachmentMimeType: newAttach?.type || "",
                attachmentType,
              })),
            ];
            setFieldValue("attachments", updatedAttachments);
          }}
          fieldName=""
          label=""
        />
      </AddNewAttachmentList>
      <AttachmentList>
        {attachmentList?.map((attachment) => {
          if (attachItemFilterValue) {
            if (attachment.attachmentType !== attachItemFilterValue) {
              return null;
            }
          }
          return (
            <AttachmentItem key={genUUID()}>
              <div>{attachment?.attachmentName}</div>
              <Flex wrap="nowrap">
                {!attachHiddenForEmployee && (
                  <ButtonWithTooltip
                    appearance="subtle"
                    tooltipContent={
                      attachment?.isHiddenForEmployee
                        ? t("hbh.isHiddenForEmployee.label")
                        : t("hbh.isVisibleForEmployee.label")
                    }
                    onClick={() => {
                      const filteredAttachmentList = attachmentList.map(
                        (item) => {
                          if (
                            item?.attachmentName === attachment?.attachmentName
                          ) {
                            return {
                              ...item,
                              isHiddenForEmployee: !item.isHiddenForEmployee,
                            };
                          }
                          return item;
                        }
                      );
                      setFieldValue("attachments", filteredAttachmentList);
                    }}
                    icon={
                      attachment?.isHiddenForEmployee ? (
                        <EyeTrackingOffRegular />
                      ) : (
                        <EyeTrackingRegular />
                      )
                    }
                  />
                )}
                <Button
                  onClick={() => {
                    const filteredAttachmentList = attachmentList.filter(
                      (attach) => {
                        return (
                          attach.attachmentName !== attachment?.attachmentName
                        );
                      }
                    );
                    setFieldValue("attachments", filteredAttachmentList);
                  }}
                  icon={<DeleteRegular />}
                />
              </Flex>
            </AttachmentItem>
          );
        })}
      </AttachmentList>
    </div>
  );
}

const Title = styled.div`
  font-weight: 600;
  text-decoration: underline;
  font-size: 16px;
`;
const AttachmentList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  //   border: 1px solid ${({ theme }) => theme.palette.neutralLight};
  max-height: 300px;
  overflow-y: auto;
`;

const AttachmentItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  margin-bottom: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.neutralLight};
`;

const AddNewAttachmentList = styled.div`
  display: flex;
  width: fit-content;
  padding: 5px;
  margin-top: 10px;
`;
