import { Button } from "@fluentui/react-components";
import {
  DeleteRegular,
  EyeTrackingOffRegular,
  EyeTrackingRegular,
} from "@fluentui/react-icons";
import { SaveIcon } from "@fluentui/react-icons-mdl2";
import { ButtonWithTooltip } from "components/ButtonWithTooltip/ButtonWithTooltip";
import { Flex } from "components/styled";
import { useAppTheme } from "hooks/useAppTheme";
import { IFormattedFile, IFormattedFileExtended } from "library/types";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

type Props = {
  attachment: IFormattedFileExtended;
  createAttachmentInProgress?: boolean;
  deleteFromList: (attachment: IFormattedFileExtended) => void;
  showIsAttachHiddenForEmployeeField: boolean;
  updateAttachList: (attachment: IFormattedFileExtended) => void;
  onCreateAttachment: (
    attach: IFormattedFile & {
      isHiddenForEmployee?: boolean;
    },
    isHiddenForEmployee?: boolean,
    newAttachList?: IFormattedFileExtended[],
    onSuccessFn?: () => void
  ) => void;
};

export function AddAttachItem({
  attachment,
  deleteFromList,
  createAttachmentInProgress,
  showIsAttachHiddenForEmployeeField,
  onCreateAttachment,
  updateAttachList,
}: Props) {
  const theme = useAppTheme();
  const { t } = useTranslation();

  const isAttachHiddenForEmployee = attachment?.isAttachHiddenForEmployee;
  return (
    <Container>
      <div>{attachment?.name}</div>
      <Flex wrap="nowrap">
        {showIsAttachHiddenForEmployeeField && (
          <ButtonWithTooltip
            disabled={createAttachmentInProgress}
            appearance="subtle"
            tooltipContent={
              isAttachHiddenForEmployee
                ? t("hbh.isHiddenForEmployee.label")
                : t("hbh.isVisibleForEmployee.label")
            }
            onClick={() => {
              const updatedAttachment = {
                ...attachment,
                isAttachHiddenForEmployee: !isAttachHiddenForEmployee,
              };
              updateAttachList(updatedAttachment);
            }}
            icon={
              isAttachHiddenForEmployee ? (
                <EyeTrackingOffRegular />
              ) : (
                <EyeTrackingRegular />
              )
            }
          />
        )}
        <Button
          disabled={createAttachmentInProgress}
          style={{
            padding: "5px 15px",
          }}
          icon={
            <SaveIcon
              style={{
                color: theme.palette.themePrimary,
                opacity: createAttachmentInProgress ? "0.5" : "1",
              }}
            />
          }
          // disabled={updateInProgress}
          appearance="outline"
          size="small"
          onClick={async (e) => {
            e.stopPropagation();
            onCreateAttachment(
              attachment,
              isAttachHiddenForEmployee,
              undefined,
              () => {
                deleteFromList(attachment);
              }
            );
          }}
        />
        <Button
          disabled={createAttachmentInProgress}
          onClick={() => {
            deleteFromList(attachment);
          }}
          icon={<DeleteRegular />}
        />
      </Flex>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  margin-bottom: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.neutralLight};
`;
