import { atom } from "jotai";
import {
  IBenefitPackage,
  IClient,
  IGrecoClient,
  SelectItem,
} from "library/types";

export const selectedCompanyState = atom<IGrecoClient | IClient | null>(null);
export const selectedCountryState = atom<SelectItem | null>(null);

export const packagesSearchFilterValueState = atom<string>("");
export const filteredPackagesState = atom<IBenefitPackage[] | undefined>(
  undefined
);
