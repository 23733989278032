import { PackagesView } from "components/PackagesView/PackagesView";
import {
  filteredPackagesState,
  packagesSearchFilterValueState,
  selectedCompanyState,
  selectedCountryState,
} from "store/UICompanyPage";

export function CompanyPackagesPage() {
  return (
    <>
      <PackagesView
        filteredPackageAtom={filteredPackagesState}
        searchFilterAtom={packagesSearchFilterValueState}
        selectedCompanyAtom={selectedCompanyState}
        selectedCountryAtom={selectedCountryState}
      />
    </>
  );
}
