import { components } from "swagger/types";
import { TableRowId } from "@fluentui/react-components";

export interface SupportedLanguage {
  isGrECoLanguage: boolean;
  code: string;
  isDeleted: boolean;
  id: number;
}

export type TaxonomyKey = any;

export type Taxonomy = {
  code: string;
  id: number;
};

export enum ETaxonomy {
  Country = "Country",
  Currency = "Currency",
  ConsentType = "ConsentType",
  DependentType = "DependentType",
  EligibleType = "EligibleType",
  PaymentMethod = "PaymentMethod",
  PaymentType = "PaymentType",
  EligibleDateType = "EligibleDateType",
  OffboardingType = "OffboardingType",
  OffboardingDateType = "OffboardingDateType",
  Gender = "Gender",
  SicCode = "SicCode",
  HbhUserRoleType = "HbhUserRoleType",
  ApprovalStatus = "ApprovalStatus",
  DeliveryType = "DeliveryType",
  AttachmentType = "AttachmentType",
}

export enum EAttachmentType {
  EMPLOYEE_CONSENT = "AttachmentType.EMPLOYEE_CONSENT",
  NORMAL = "AttachmentType.NORMAL",
}

export enum EInsurerConsentType {
  INSURER_FORM = 1,
  URL_TO_INSURER = 2,
  I_AGREE_TEXT = 3,
}

export interface IClientAndBenefitCategories {
  client: IClient;
  benefitCategories: IBenefitCategory[];
}

export interface ICommonAttributes {
  rowVersion?: string; // required
  backgroundPictureName?: string;
  backgroundPictureContent?: string;
  backgroundPictureMimeType?: string;
  backgroundPicturePath?: string;
  lastModifiedAt?: string; // readOnly
  lastModifiedByUserId?: string; // readOnly
  lastModifiedByUserName?: string; // readOnly
  lastModifiedByUserEmail?: string; // readOnly
  createdAt?: string; // readOnly
  createdByUserId?: string; // readOnly
  createdByUserName?: string; // readOnly
  createdByUserEmail?: string; // readOnly
  attachments?: Attachment[];
}

export interface INotification {
  fieldName?: string;
  text: string;
  id: string;
  handleClearNotification?: (message: INotification) => void;
  type: "error" | "info" | "warning" | "success";
  // notify: INotify;
}

export interface INotify {
  notifyText: string;
  type: string;
  notifyCode: string;
  label: string;
}

export interface IErrorHandler {
  value: string;
  type: string;
  label: string;
  fieldName?: string;
  errors?: any;
}

export interface IUser {
  "@odata.context": string;
  businessPhones?: any[];
  displayName?: string;
  givenName?: string;
  jobTitle?: string | null;
  mail?: string | null;
  mobilePhone?: string;
  officeLocation?: string | null;
  preferredLanguage?: string;
  surname?: string;
  userPrincipalName?: string;
  id?: string;
  photoMedium?: string;
}

export enum ECheckedValue {
  Yes = "yes",
  No = "no",
}

export enum EPaidBy {
  Percentage = "Percentage",
  Amount = "Amount",
}

export type SelectItem = {
  text: string;
  value: string | number;
  Icon?: JSX.Element;
  [x: string]: any;
};

export type TBenefitFields = {
  benefit: string;
};

export enum EBenefitTab {
  list = "list",
  grid = "grid",
}
export enum EApprovalStatus {
  APPROVED = "ApprovalStatus.APPROVED",
  REJECTED = "ApprovalStatus.REJECTED",
  PENDING = "ApprovalStatus.PENDING",
}

export enum EAppSettings {
  language = "Language",
  darkMode = "Dark_Mode",
  theme = "Theme",
  benefitViewMode = "Benefit_View_Mode",
}

export enum EManageHrTabView {
  summary = "Summary",
  summaryPbi = "Analytics",
  benefits = "Benefits",
  employees = "Employees",
  bulk = "Bulk Management",
  actionHistory = "Action History",
  userAssignments = "User Assignments",
  security = "Security",
}

export enum EAdminTabView {
  benefits = "Benefits",
  analytics = "Analytics",
}

export enum EImageView {
  list = "list",
  details = "details",
  tiles = "tiles",
  largeImages = "largeImages",
  smallImages = "smallImages",
  mediumImages = "mediumImages",
}

export enum ECountryTabView {
  benefits = "Benefits",
  analytics = "Analytics",
}

export enum ECompanyTabView {
  benefits = "Benefits",
  analytics = "Analytics",
}

export type IFormattedFile = {
  name: string | null;
  path?: string | null;
  base64String: string | null;
  type: string | null;
};
export type IFormattedFileExtended = IFormattedFile & {
  isAttachHiddenForEmployee: boolean;
};

export interface IFile {
  base64Str?: string;
  name: string;
}

export type IGrecoClient = {
  id: number;
  internalNumber: number;
  name: string;
  countryCodeId: number;
  currencyCodeId: number;
  isCompany?: boolean;
  label?: string;
  value?: string;
  clientInternalNumberGos?: number;
  currencyCode?: string;
};

export interface IBackendError {
  statusCode: number;
  errorCode: string;
  type: string;
  correlationID: string;
  messageCode: string;
  messageVariables: any[];
  statusDescription: string;
  message: string;
  formatedMessage: string;
  exceptionMessage: string;
  stackTrace: string;
}

export interface IInsurer {
  Id: number;
  InternalNumber: number;
  Name: string;
  CountryCodeId: number;
  IsCompany: boolean;
}
export interface IBenefitCategory
  extends Partial<components["schemas"]["BenefitCategory"]> {
  coverPhoto?: IFormattedFile | null;
  currencyCode: number;
  included?: boolean;
}

export interface IClient
  extends Partial<components["schemas"]["Client"]>,
    SelectItem {}

export interface IExcelReportResponse
  extends Partial<components["schemas"]["FileResponse"]>,
    SelectItem {}

export interface Attachment
  extends Partial<components["schemas"]["Attachment"]> {}

export interface IImportExcelTemplateRequest
  extends Partial<components["schemas"]["ImportExcelTemplateRequest"]> {}

export interface ITaxonomy
  extends Partial<components["schemas"]["TaxonomyData"]> {}

export enum ERole {
  SuperAdmin = "SuperAdmin",
  GlobalAdmin = "GlobalAdmin",
  ReadonlyGlobalAdmin = "ReadonlyGlobalAdmin",
  CountryAdmin = "CountryAdmin",
  ReadonlyCountryAdmin = "ReadonlyCountryAdmin",
  CompanyAdmin = "CompanyAdmin",
  ReadonlyCompanyAdmin = "ReadonlyCompanyAdmin",
  HRCompanyAdmin = "HRCompanyAdmin",
  ReadonlyHRCompanyAdmin = "ReadonlyHRCompanyAdmin",
  B2CUser = "B2CUser",
}
export interface IAuthorization
  extends Partial<components["schemas"]["UserAuthorizationInfo"]> {}

export interface IBenefitImplementation
  extends Partial<components["schemas"]["BenefitImplementation"]> {
  coverPhoto: IFormattedFile | null;
  insurerForm?: IFormattedFile | null;
}

export interface IBenefitPackage
  extends Partial<components["schemas"]["BenefitPackage"]> {
  coverPhoto: IFormattedFile | null;
}

export type IRowItem = IBenefitImplementation &
  IBenefitCategory & {
    updateState: (
      item:
        | IBenefitCategory
        | (IBenefitImplementation & {
            isEdited?: boolean;
          }),
      reset?: boolean,
      disabled?: boolean
    ) => void;
    selectionMode?: "single" | "multiselect";
    defaultState?: (IBenefitCategory | IBenefitImplementation) & {
      coverPhoto?: IFormattedFile | null;
    } & {
      insurerForm?: IFormattedFile | null;
    };
    benefitCategoryName?: string;
    coverPhoto?: {
      name: string;
    };
    insurerForm?: {
      name: string;
    };
    isEdited?: boolean;
    selected?: boolean;
    id: number;
    disabled?: boolean;
    index: number;
    firstName?: string;
    lastName?: string;
  };

export interface PowerBIEmbedMeta {
  reportId: string;
  embedUrl: string;
  accessToken: string;
  embedTokenExpiry: Date;
}
export interface ITransformedBenefitPackages {
  "61": IBenefitPackageView;
  "69": IBenefitPackageView;
}

export interface ICompanySummaryInfo
  extends Partial<components["schemas"]["CompanySummaryInfo"]> {}

export interface ICompanyInfosInfo
  extends Partial<components["schemas"]["CompanyInfos"]> {}
export interface IBenefitImplementationSummaryInfo
  extends Partial<components["schemas"]["BenefitImplementationSummaryInfo"]> {}

export interface IBenefitImplementationSecurity
  extends Partial<components["schemas"]["BenefitImplementationSecurity"]> {}

export interface IBenefitPackageSummaryInfo
  extends Partial<components["schemas"]["BenefitPackageSummaryInfo"]> {}

export interface IEmployee extends Partial<components["schemas"]["Employee"]> {
  id?: string | number;
  isDependent?: boolean;
}

export interface IEmployeeView
  extends Partial<components["schemas"]["EmployeeView"]> {
  id?: string | number;
  isDependent?: boolean;
  expanded?: boolean;
  dependentList?: IEmployeeView[];
  enrollments?: any[];
  needsAttention?: boolean;
}

export interface IEmployeeEnrollment
  extends Partial<components["schemas"]["EmployeeEnrollment"]> {
  attachments?: Attachment[];
  benefitPackageName?: string;
}

export interface IEmployeeDependent
  extends Partial<components["schemas"]["EmployeeDependent"]> {}

export interface IBenefitPackageView
  extends Partial<components["schemas"]["BenefitPackageView"]> {
  benefitCategoryName?: string;
}

export interface ICompanyAction
  extends Partial<components["schemas"]["CompanyAction"]> {}
export interface IEmployeeEnrollmentBase
  extends Partial<components["schemas"]["EmployeeEnrollmentBase"]> {
  employeeLoginEmailAddress?: string;
  clientInternalNumberGos?: number;
  countryCode?: number;
  attachments?: Attachment[];
  benefitPackageName?: string;
}

export type EmployeeRowItem = IEmployeeView &
  IEmployee &
  IEmployeeEnrollment & {
    updateState: (
      item: EmployeeRowItem,
      reset?: boolean,
      disabled?: boolean,
      expanded?: boolean
    ) => void;
    selectionMode?: "single" | "multiselect";
    selected?: boolean;
    id?: number | string;
    disabled?: boolean;
    expanded?: boolean;
    name?: string; //benefitPackageName
    dateOfEligibility: string | null;
    benefitCategoryName: string;
    employeeLoginEmailAddress: string;
    endDateOfEligibility: string | null;
    cancellationPossibility?: string | null;
    benefitPackageTag?: number;
    isAutoEnrollment?: boolean;
    cancellationDate: string;
    dateOfExit?: string;
    isInactive?: boolean;
    showSubrow?: boolean;
    rowId: string;
    employeeB2coid?: string;
    toggleRow: (e: React.SyntheticEvent, rowId: TableRowId) => void;
    updateAllState: (data: any) => void;
  };

export interface IUserAssignment
  extends Partial<components["schemas"]["HbhUserAssignment"]> {
  isReadOnly?: boolean;
}

export interface IEmployeeEnrollmentCalculatedDates
  extends Partial<components["schemas"]["EmployeeEnrollmentCalculatedDates"]> {}

export interface IUserSettings
  extends Partial<components["schemas"]["UserSettings"]> {}
export interface IUserSettingsPayload
  extends Partial<components["schemas"]["UserSettingsRequest"]> {}

export interface IAddImagePayload
  extends Partial<components["schemas"]["ImagesRequest"]> {}
export interface IPolicy
  extends Partial<components["schemas"]["PolicyVersionBasicSearch"]> {}

export interface IChatbotMessageHistory
  extends Partial<components["schemas"]["MessageHistory"]> {}

export interface IChatbotMessage
  extends Partial<components["schemas"]["ChatBotRequest"]> {}
