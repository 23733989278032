import { Button, Link, Text } from "@fluentui/react-components";
import { ArrowUploadRegular } from "@fluentui/react-icons";
import { ClearIcon } from "@fluentui/react-icons-mdl2";
import { AvatarWithPreview } from "components/AvatarWithPreview/AvatarWithPreview";
import FormFieldLabel from "components/FormFieldLabel/FormFieldLabel";
import { useAppTheme } from "hooks/useAppTheme";
import { IFormattedFile } from "library/types";
import {
  downloadFile,
  extractFileNameFromUrl,
  formatFile,
  getExtensionFromUrl,
} from "library/utils";
import { ManageImages } from "pages/AdminPage/components/ManageImages/ManageImages";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

type Props = {
  fieldName: string;
  setFieldValue: (name: string, value: IFormattedFile | null) => void;
  label: string;
  defaultValue?: IFormattedFile | null;
  addIcon?: React.JSX.Element;
  disabled?: boolean;
  isUploadAppImages?: boolean;
  isDocument?: boolean;
};

export const UploadFile = ({
  fieldName,
  setFieldValue,
  label,
  defaultValue,
  addIcon,
  disabled,
  isUploadAppImages,
  isDocument,
}: Props) => {
  const { palette } = useAppTheme();
  const { t } = useTranslation();
  const [selectedFile, setSelectedFile] = useState<IFormattedFile | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = async (event, image?: any) => {
    if (image) {
      const fileName = extractFileNameFromUrl(image);
      const type = getExtensionFromUrl(image);
      const formattedFile = {
        name: fileName,
        base64String: "",
        path: image,
        type,
      };
      setSelectedFile(formattedFile);
      setFieldValue(fieldName, formattedFile);

      console.log("Formatted file:", formattedFile);
      return;
    }

    const file = event.target.files[0];

    const formattedFile = await formatFile(file);
    setSelectedFile(formattedFile);
    setFieldValue(fieldName, formattedFile);
    console.log("Formatted file:", formattedFile);
  };

  useEffect(() => {
    setSelectedFile(defaultValue || null);
  }, [defaultValue]);

  return (
    <Container>
      <FormFieldLabel label={label} required={false} />
      <Wrap>
        {selectedFile?.path ? (
          isDocument ? (
            <Link target="_blank" href={selectedFile.path}>
              {selectedFile?.path?.slice(0, 10)}
            </Link>
          ) : (
            <AvatarWithPreview
              style={{
                display: "inline-block",
              }}
              size={28}
              shape="square"
              name={selectedFile?.path?.slice(0, 10)}
              imgSrc={selectedFile?.path}
            />
          )
        ) : (
          <Text>{selectedFile?.name}</Text>
        )}
        {selectedFile && (
          <Button
            style={{
              paddingLeft: "0",
            }}
            disabled={disabled}
            appearance="transparent"
            icon={
              <ClearIcon
                style={{
                  transform: "scale(0.6)",
                  color: palette.redDark,
                }}
              />
            }
            onClick={() => {
              setFieldValue(fieldName, null);
              setSelectedFile(null);
            }}
          />
        )}
        {isUploadAppImages ? (
          <ManageImages onSelectImage={handleFileChange} />
        ) : (
          <Button
            style={{
              paddingLeft: "0",
            }}
            disabled={disabled}
            appearance="transparent"
            icon={addIcon ? addIcon : <ArrowUploadRegular />}
            onClick={() => {
              if (!fileInputRef.current) return;
              fileInputRef.current.click();
            }}
          >
            {selectedFile ? t("hbh.uploadNew.label") : t("hbh.upload.label")}
            <input
              ref={fileInputRef}
              onChange={handleFileChange}
              type="file"
              accept="*"
              hidden
            />
          </Button>
        )}
      </Wrap>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;

const Wrap = styled.div`
  display: flex;
  align-items: center;
`;
