import useNotificationHandler from "hooks/useNotificationHandler";
import { MUTATION_KEYS } from "library/shared";
import { Attachment } from "library/types";
import { useMutation } from "react-query";
import api from "service/api";
import { useInvalidateDependentEnrollment } from "./useGetDependentEnrollment";
import { useInvalidateEmployeeEnrollment } from "./useGetEmployeeEnrollment";

type Params = {
  countryCode: number | string;
  benefitImplementationTag: number | string;
  benefitPackageTag: number | string;
  employeeEnrollmentTag: number | string;
  employeeLoginEmailAddress: string;
  clientInternalNumberGOS: number | string;
  payload: Attachment;
};
const createAttachment = async ({
  countryCode,
  clientInternalNumberGOS,
  benefitImplementationTag,
  benefitPackageTag,
  employeeEnrollmentTag,
  employeeLoginEmailAddress,

  payload,
}: Params) => {
  const response = await api.post(
    `/employeeenrollment/attachment/${countryCode}/${clientInternalNumberGOS}/${benefitImplementationTag}/${benefitPackageTag}/${employeeLoginEmailAddress}/${employeeEnrollmentTag}`,
    payload
  );
  return response.data as Attachment;
};
export function useCreateEnrollmentAttachment(
  refetch = true,
  isDependent = false
) {
  const { handleAddAxiosErrorNotification } = useNotificationHandler();
  const invalidateEmployeeEnrollment = useInvalidateEmployeeEnrollment();
  const invalidateDependentEnrollment = useInvalidateDependentEnrollment();
  return useMutation(createAttachment, {
    mutationKey: MUTATION_KEYS.createEnrollmentAttachment,
    onSuccess: () => {
      if (refetch) {
        if (isDependent) {
          invalidateDependentEnrollment();
        } else {
          invalidateEmployeeEnrollment();
        }
      }
    },
    onError: (error) => {
      // handle error
      handleAddAxiosErrorNotification(error);
    },
  });
}
