import React from "react";
import {
  Avatar,
  TableCellLayout,
  TableColumnSizingOptions,
  Text,
  createTableColumn,
  Button,
} from "@fluentui/react-components";
import { Flex, StyledTooltip } from "components/styled";
import { format, formatDate } from "date-fns";
import { TFunction } from "i18next";
import { ACTION_HISTORY_TRANSLATION_KEYS } from "library/constants";
import {
  IBenefitImplementation,
  IBenefitImplementationSecurity,
  IUserAssignment,
} from "library/types";
import { SecurityCell } from "./SecurityCell";
import { SecurityTableRowType } from "./SecurityView";
import {
  SelectObjectSkewDismissRegular,
  SelectObjectSkewRegular,
} from "@fluentui/react-icons";

export type SecurityCellType = {
  user: IUserAssignment;
  userAssignements: IUserAssignment[];
  benefitImplementation: IBenefitImplementation;
  permission: number;
  updates: IBenefitImplementationSecurity[];
  setUpdates: (updates: IBenefitImplementationSecurity[]) => void;
};

export const getSecurityViewColumns = ({
  userAssignements,
  implementedBenefits,
  t,
  updates,
  setUpdates,
  markedRows,
  setMarkedRows,
  markedColumns,
  setMarkedColumns,
}: {
  t: TFunction<"translation", undefined>;
  implementedBenefits: IBenefitImplementation[] | undefined;
  updates: IBenefitImplementationSecurity[];
  setUpdates: (updates: IBenefitImplementationSecurity[]) => void;
  markedRows: string[];
  setMarkedRows: (rows: string[]) => void;
  markedColumns: string[];
  setMarkedColumns: (columns: string[]) => void;
}) => [
  createTableColumn({
    columnId: "user",
    renderHeaderCell: () => <>{t("hbh.user.label")}</>,
    renderCell: (item: SecurityTableRowType) => {
      const isMarked = markedRows.includes(item.user.hbhUserAssignmentId);
      return (
        <Flex style={{ justifyContent: "space-between" }}>
          {" "}
          <StyledTooltip
            content={item.user.userName || ""}
            relationship="label"
          >
            <Flex>
              <Avatar name={item.user.userName} />
              <Text truncate block>
                {item.user.userName}
              </Text>
            </Flex>
          </StyledTooltip>
          <StyledTooltip
            content={
              isMarked ? t("hbh.unmarkall.label") : t("hbh.markall.label")
            }
            relationship="label"
          >
            <Button
              appearance="subtle"
              icon={
                isMarked ? (
                  <SelectObjectSkewDismissRegular
                    style={{
                      color: "#888888",
                    }}
                  />
                ) : (
                  <SelectObjectSkewRegular
                    style={{
                      color: "#888888",
                    }}
                  />
                )
              }
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setMarkedRows((prev) =>
                  isMarked
                    ? prev.filter((id) => id !== item.user.hbhUserAssignmentId)
                    : [...prev, item.user.hbhUserAssignmentId]
                );
              }}
            />
          </StyledTooltip>
        </Flex>
      );
    },

    compare: (a, b) => {
      return (a?.user.createdByUserName || "").localeCompare(
        b?.user.createdByUserName || ""
      );
    },
  }),

  ...[
    implementedBenefits?.map((benefit, index) => {
      const isMarked = markedColumns.includes(
        benefit.benefitImplementationTag.toString()
      );
      return createTableColumn({
        columnId: "security" + benefit.benefitImplementationTag,
        renderHeaderCell: () => (
          <Flex style={{ justifyContent: "space-between", width: "100%" }}>
            <div>
              {benefit.name}
              <br />({formatDate(benefit?.validFrom ?? "", "dd.MM.yyyy")}-{" "}
              {formatDate(benefit?.validTo ?? "", "dd.MM.yyyy")})
            </div>
            <StyledTooltip
              content={
                isMarked ? t("hbh.unmarkall.label") : t("hbh.markall.label")
              }
              relationship="label"
            >
              <Button
                appearance="subtle"
                icon={
                  isMarked ? (
                    <SelectObjectSkewDismissRegular
                      style={{
                        color: "#888888",
                      }}
                    />
                  ) : (
                    <SelectObjectSkewRegular
                      style={{
                        color: "#888888",
                      }}
                    />
                  )
                }
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setMarkedColumns((prev) =>
                    isMarked
                      ? prev.filter(
                          (id) =>
                            id !== benefit.benefitImplementationTag.toString()
                        )
                      : [...prev, benefit.benefitImplementationTag.toString()]
                  );
                }}
              />
            </StyledTooltip>
          </Flex>
        ),
        renderCell: (item: SecurityTableRowType) => {
          const isMarkedCell =
            markedRows.includes(item.user.hbhUserAssignmentId) ||
            markedColumns.includes(benefit.benefitImplementationTag.toString());
          return (
            <SecurityCell
              user={item.user}
              benefitImplementation={benefit}
              updates={updates}
              setUpdates={setUpdates}
              permission={
                item.permissions?.find(
                  (el) =>
                    el.benefitImplementationTag ===
                    benefit.benefitImplementationTag
                )?.permission ?? 2
              }
              onChange={(
                newPermission,
                isChanged,
                itemUpdates,
                setItemUpdates
              ) => {
                const existingChanges = [...itemUpdates];
                let entryFound = false;

                // Update or insert new entry for the current cell
                for (let i = 0; i < existingChanges.length; i++) {
                  if (
                    existingChanges[i]?.hbhUserAssignmentId ===
                      item.user.hbhUserAssignmentId &&
                    existingChanges[i]?.benefitImplementationTag ===
                      benefit.benefitImplementationTag
                  ) {
                    entryFound = true;
                    if (!isChanged) {
                      existingChanges.splice(i, 1);
                    } else {
                      if (existingChanges && existingChanges[i]) {
                        existingChanges[i].permission = newPermission;
                      }
                    }
                    break;
                  }
                }
                if (!entryFound && isChanged) {
                  existingChanges.push({
                    hbhUserAssignmentId: item.user.hbhUserAssignmentId,
                    benefitImplementationTag: benefit.benefitImplementationTag,
                    permission: newPermission,
                    userId: item.user.userId,
                  });
                }
                if (markedRows.length > 0 || markedColumns.length > 0) {
                  let rowsToUpdate = markedRows;
                  let columnsToUpdate = implementedBenefits.map((benefit) =>
                    benefit.benefitImplementationTag.toString()
                  );
                  // Apply the change to all marked cells (intersection of marked rows and columns)
                  rowsToUpdate.forEach((rowId) => {
                    columnsToUpdate.forEach((colId) => {
                      let markedEntryFound = false;
                      for (let i = 0; i < existingChanges.length; i++) {
                        if (
                          existingChanges[i]?.hbhUserAssignmentId === rowId &&
                          existingChanges[i]?.benefitImplementationTag ===
                            parseInt(colId)
                        ) {
                          markedEntryFound = true;
                          existingChanges[i].permission = newPermission;
                          break;
                        }
                      }
                      if (!markedEntryFound) {
                        existingChanges.push({
                          hbhUserAssignmentId: rowId,
                          benefitImplementationTag: parseInt(colId),
                          permission: newPermission,
                          userId: item.user.userId,
                        });
                      }
                    });
                  });

                  rowsToUpdate = userAssignements.map(
                    (update) => update.hbhUserAssignmentId
                  );
                  columnsToUpdate = markedColumns;
                  // Apply the change to all marked cells (intersection of marked rows and columns)
                  rowsToUpdate.forEach((rowId) => {
                    columnsToUpdate.forEach((colId) => {
                      let markedEntryFound = false;
                      for (let i = 0; i < existingChanges.length; i++) {
                        if (
                          existingChanges[i]?.hbhUserAssignmentId === rowId &&
                          existingChanges[i]?.benefitImplementationTag ===
                            parseInt(colId)
                        ) {
                          markedEntryFound = true;
                          existingChanges[i].permission = newPermission;
                          break;
                        }
                      }
                      if (!markedEntryFound) {
                        existingChanges.push({
                          hbhUserAssignmentId: rowId,
                          benefitImplementationTag: parseInt(colId),
                          permission: newPermission,
                          userId: item.user.userId,
                        });
                      }
                    });
                  });
                }
                setItemUpdates(existingChanges);
                setMarkedRows([]);
                setMarkedColumns([]);
              }}
              isMarked={isMarkedCell}
            />
          );
        },

        compare: (a: any, b: any) => {
          return (`${a.id}` || "").localeCompare(b?.id || "");
        },
      });
    }),
  ].flat(),
];

export const defaultColumnSizingOptions: TableColumnSizingOptions = {
  user: {
    idealWidth: 400,
    minWidth: 400,
    defaultWidth: 400,
  },
  actionType: {
    idealWidth: 300,
    minWidth: 300,
    defaultWidth: 300,
  },
  documentType: {
    idealWidth: 100,
    minWidth: 100,
    defaultWidth: 100,
  },
  createdAt: {
    idealWidth: 120,
    minWidth: 120,
    defaultWidth: 120,
  },
};
