import { PageSkeleton } from "components/Skeletons/PageSkeleton";
import { PageContent } from "components/styled";
import { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useGetBenefitImplementation } from "state/queries/useGetBenefitImplementation";
import { useGetBenefitImplementationPackages } from "state/queries/useGetBenefitImplementationPackages";
import { getClients } from "state/queries/useGetClients";

import { PackagesToolbar } from "./components/PackagesToolbar/PackagesToolbar";
import { ManagePackagesView } from "./components/ManagePackagesView/ManagePackagesView";
import { PackagesSubheader } from "./components/PackagesSubheader/PackagesSubheader";
import { useTaxonomyOptions } from "hooks/useTaxonomyOptions";
import {
  ETaxonomy,
  IBenefitPackage,
  IClient,
  IGrecoClient,
  SelectItem,
} from "library/types";
import { PrimitiveAtom, useAtom } from "jotai";
import { getSingleClient } from "state/queries/useGetSingleClient";
import { AppSpinner } from "components/AppSpinner/AppSpinner";

type Props = {
  filteredPackageAtom: PrimitiveAtom<IBenefitPackage[] | undefined>;
  searchFilterAtom: PrimitiveAtom<string>;
  selectedCompanyAtom: PrimitiveAtom<IGrecoClient | IClient | null>;
  selectedCountryAtom: PrimitiveAtom<SelectItem | null>;
};
export function PackagesView({
  filteredPackageAtom,
  searchFilterAtom,
  selectedCompanyAtom,
  selectedCountryAtom,
}: Props) {
  const [selectedCompany, setSelectedCompany] = useAtom(selectedCompanyAtom);
  const [selectedCountry, setSelectedCountry] = useAtom(selectedCountryAtom);
  const [filteredPackages, setFilteredPackages] = useAtom(filteredPackageAtom);
  const [searchFilterValue, setSearchFilterValue] = useAtom(searchFilterAtom);
  const params = useParams();
  const implementationId = params.implementationId;
  const categoryId = params.categoryId;
  const countryCode = params.countryCode;
  const clientInternalNumberGOS = params.clientInternalNumberGOS;

  const countryOptions = useTaxonomyOptions(ETaxonomy.Country);

  const {
    data: benefitImplementation,
    isLoading: benefitImplementationLoading,
  } = useGetBenefitImplementation({
    benefitCategoryTag: Number(categoryId),
    countryCode: Number(countryCode),
    benefitImplementationTag: Number(implementationId),
    clientInternalNumberGOS: Number(clientInternalNumberGOS),
  });

  const {
    data: packageList,
    isLoading: packagesLoading,
    isRefetching: packagesRefetching,
  } = useGetBenefitImplementationPackages({
    countryCode: Number(countryCode),

    clientInternalNumberGOS: Number(clientInternalNumberGOS),
    benefitCategoryTag: Number(categoryId),
    benefitImplementationTag: Number(implementationId),
  });

  const handleSearchFilterValue = useCallback(
    (value) => {
      setSearchFilterValue(value);
    },
    [setSearchFilterValue]
  );

  const getAndSetSelectedCompany = async () => {
    const company = await getSingleClient({
      countryCode: Number(countryCode),
      internalNumber: Number(clientInternalNumberGOS),
    });

    setSelectedCompany(company);
  };

  const selectedCountryDefault = countryOptions.find(
    (option) => option.value === Number(countryCode)
  );

  useEffect(() => {
    if (selectedCountryDefault && !selectedCountry) {
      setSelectedCountry(selectedCountryDefault);
    }
  }, [selectedCountry, selectedCountryDefault]);

  useEffect(() => {
    if (countryCode && clientInternalNumberGOS) {
      getAndSetSelectedCompany();
    }
  }, [countryCode, clientInternalNumberGOS]);

  useEffect(() => {
    if (!packageList) return;
    setFilteredPackages(
      packageList?.filter((item) =>
        item?.name?.toLowerCase().includes(searchFilterValue)
      )
    );
  }, [packageList, searchFilterValue, setFilteredPackages]);

  const isLoading = benefitImplementationLoading || packagesLoading;

  if (isLoading || !benefitImplementation) return <PageSkeleton />;

  return (
    <>
      {packagesRefetching && <AppSpinner />}
      <PackagesSubheader
        selectedCompany={selectedCompany}
        selectedCountry={selectedCountry}
        setSelectedCompany={setSelectedCompany}
        disabled={!packageList?.length}
        items={filteredPackages || []}
        benefitImplementation={benefitImplementation}
        searchFilterValue={searchFilterValue}
        setSearchFilterValue={handleSearchFilterValue}
      />
      <PackagesToolbar benefitImplementation={benefitImplementation} />

      <PageContent>
        <ManagePackagesView
          benefitImplementation={benefitImplementation}
          packageList={filteredPackages || []}
        />
      </PageContent>
    </>
  );
}
