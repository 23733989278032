import { useAtom, useAtomValue } from "jotai";
import { EManageHrTabView } from "library/types";
import { selectedHrTabState } from "store";
import styled from "styled-components";
import BulkManagementView from "../BulkManagementView/BulkManagementView";
import { ActionHistoryView } from "./ActionHistoryView/ActionHistoryView";
import { EmployeeView } from "./EmployeesView/EmployeeView";
import { ImplementationsHrView } from "./ImplementationsHrView/ImplementationsHrView";
import { SummaryView } from "./SummaryView/SummaryView";
import { SummaryViewPbi } from "./SummaryView/SummaryViewPbi";
import { UserAssignments } from "./UserAssignments/UserAssignments";
import {
  companyEmployeeViewsAtom,
  onlyActiveImplementationsState,
} from "store/UIHrPage";
import { SecurityView } from "./Security/SecurityView";

export function ManagePageView() {
  const selectedTab = useAtomValue(selectedHrTabState);
  const companyEmployees = useAtomValue(companyEmployeeViewsAtom);
  const [onlyActiveImplementations, setOnlyActiveImplementations] = useAtom(
    onlyActiveImplementationsState
  );

  return (
    <div>
      <TabContent>
        {selectedTab === EManageHrTabView.summary && <SummaryView />}
        {selectedTab === EManageHrTabView.summaryPbi && <SummaryViewPbi />}
        {selectedTab === EManageHrTabView.benefits && <ImplementationsHrView />}
        {selectedTab === EManageHrTabView.employees && (
          <EmployeeView
            onlyActiveImplementations={onlyActiveImplementations}
            setOnlyActiveImplementation={setOnlyActiveImplementations}
            companyEmployees={companyEmployees}
          />
        )}
        {selectedTab === EManageHrTabView.bulk && <BulkManagementView />}
        {selectedTab === EManageHrTabView.actionHistory && (
          <ActionHistoryView />
        )}
        {selectedTab === EManageHrTabView.userAssignments && (
          <UserAssignments />
        )}
        {selectedTab === EManageHrTabView.security && <SecurityView />}
      </TabContent>
    </div>
  );
}

const TabContent = styled.div`
  // margin-top: 20px;
`;
