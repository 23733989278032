import { Button } from "@fluentui/react-components";
import { ArrowClockwiseRegular } from "@fluentui/react-icons";
import { useAtomValue, useSetAtom } from "jotai";
import { QUERY_KEYS } from "library/shared";
import { EManageHrTabView } from "library/types";
import { useIsFetching } from "react-query";
import { useInvalidateBenefitImplementations } from "state/queries/useGetBenefitImplementations";
import { useInvalidateClientsByCountry } from "state/queries/useGetClientsByCountry";
import { useInvalidateCompanyAction } from "state/queries/useGetCompanyActionHistory";
import { useInvalidateCompanyPackages } from "state/queries/useGetCompanyBenefitPackages";
import { useInvalidateCompanyEmployeeViews } from "state/queries/useGetCompanyEmployeeViews";
import { useInvalidateCompanyInfos } from "state/queries/useGetCompanyInfos";
import { useInvalidateGetUserAssignments } from "state/queries/useGetUserAssignments";
import { refreshAnalyticsAtom, selectedHrTabState } from "store";
import { selectedCompanyState } from "store/UIHrPage";

export function HrPackageEmployeesRefreshBtn() {
  const selectedCompany = useAtomValue(selectedCompanyState);

  const selectedTab = useAtomValue(selectedHrTabState);
  const setRefreshAnalyticsAtom = useSetAtom(refreshAnalyticsAtom);
  const invalidateCompanyBenefitPackages = useInvalidateCompanyPackages();
  const invalidateClientsByCountry = useInvalidateClientsByCountry();
  const invalidateCompanyInfos = useInvalidateCompanyInfos();
  const invalidateBenefitImplementations =
    useInvalidateBenefitImplementations();
  const invalidateCompanyEmployeeViews = useInvalidateCompanyEmployeeViews();
  const invalidateCompanyAction = useInvalidateCompanyAction();
  const invalidateGetUserAssignments = useInvalidateGetUserAssignments();

  const isLoadingClients = !!useIsFetching({
    queryKey: QUERY_KEYS.clientsByCountry,
  });

  const handleRefresh = () => {
    if (isLoadingClients) return;
    if (!selectedCompany) {
      invalidateClientsByCountry();
      return;
    }
    if (selectedTab === EManageHrTabView.summary) {
      invalidateCompanyBenefitPackages();
      invalidateClientsByCountry();
      invalidateCompanyInfos();
      invalidateCompanyEmployeeViews();
      return;
    }

    if (selectedTab === EManageHrTabView.benefits) {
      invalidateBenefitImplementations();

      return;
    }
    if (
      selectedTab === EManageHrTabView.employees ||
      selectedTab === EManageHrTabView.bulk
    ) {
      invalidateCompanyEmployeeViews();
      invalidateCompanyBenefitPackages();

      return;
    }
    if (selectedTab === EManageHrTabView.summaryPbi) {
      setRefreshAnalyticsAtom((prev) => !prev);
      return;
    }
    if (selectedTab === EManageHrTabView.actionHistory) {
      invalidateCompanyAction();
      return;
    }
    if (selectedTab === EManageHrTabView.userAssignments) {
      invalidateGetUserAssignments();
      return;
    }
  };

  return (
    <Button
      disabled={!selectedCompany && isLoadingClients}
      onClick={handleRefresh}
      appearance="outline"
      icon={<ArrowClockwiseRegular />}
    />
  );
}
