import { Collapse } from "@fluentui/react-motion-components-preview";
import { useEffect, useState } from "react";

export function CollapseWithDelay({ children }) {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    let timer1 = setTimeout(() => setVisible(true), 1);

    return () => {
      clearTimeout(timer1);
    };
  }, []);

  return <Collapse visible={visible}>{children}</Collapse>;
}
