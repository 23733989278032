import { PackagesView } from "components/PackagesView/PackagesView";
import {
  filteredPackagesState,
  packagesSearchFilterValueState,
  selectedCompanyState,
  selectedCountryState,
} from "store/UiHrPackagesPage";

export function HrPackagesPage() {
  return (
    <>
      <PackagesView
        filteredPackageAtom={filteredPackagesState}
        searchFilterAtom={packagesSearchFilterValueState}
        selectedCompanyAtom={selectedCompanyState}
        selectedCountryAtom={selectedCountryState}
      />
    </>
  );
}

export default HrPackagesPage;
