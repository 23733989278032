import { Button, Link, Text } from "@fluentui/react-components";
import { ArrowUploadRegular } from "@fluentui/react-icons";
import { ClearIcon } from "@fluentui/react-icons-mdl2";
import { Flex } from "components/styled";
import { useAppTheme } from "hooks/useAppTheme";
import { IFormattedFile, IRowItem } from "library/types";
import { formatFile } from "library/utils";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

type Props = {
  item: IRowItem;
  disabled?: boolean;
};

export const InsurerFormCell = ({ item, disabled }: Props) => {
  const { t } = useTranslation();
  const theme = useAppTheme();
  const [showImg, setShowImg] = useState(false);
  const [filePreview, setFilePreview] = useState("");
  const defaultValue = useMemo(() => {
    return item.insurerConsentDocumentPath
      ? {
          name: item.insurerConsentDocumentName || "",
          path: item.insurerConsentDocumentPath || "",
          base64String: item.insurerConsentDocumentContent || "",
          type: item.insurerConsentDocumentMimeType || "",
        }
      : null;
  }, [
    item.insurerConsentDocumentPath,
    item.insurerConsentDocumentName,
    item.insurerConsentDocumentContent,
    item.insurerConsentDocumentMimeType,
  ]);

  const [selectedFile, setSelectedFile] = useState<IFormattedFile | null>(
    defaultValue
  );

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    if (file) {
      const previewFile = URL.createObjectURL(file);
      setFilePreview(previewFile);
    }

    const formattedFile = await formatFile(file);
    setSelectedFile(formattedFile);
    const updatedItem = {
      ...item,
      insurerForm: formattedFile,
      isEdited: true,
    };
    item.updateState(updatedItem);
  };

  const handleMouseEnter = () => {
    setShowImg(true);
  };
  const onMouseLeave = () => {
    setShowImg(false);
  };

  useEffect(() => {
    if (item?.insurerForm?.name === item?.defaultState?.insurerForm?.name) {
      setSelectedFile(defaultValue);
    }
  }, [item]);

  useEffect(() => {
    setSelectedFile(defaultValue);
    setFilePreview("");
  }, [defaultValue]);

  return (
    <Container>
      {selectedFile ? (
        <Flex
          wrap="nowrap"
          justify="center"
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {selectedFile.path ? (
            <Link href={selectedFile.path} target="_blank">
              {selectedFile.path?.slice(0, 10)}...
            </Link>
          ) : (
            <Link href={filePreview} target="_blank">
              {selectedFile.name?.slice(0, 10)}...
            </Link>
          )}
        </Flex>
      ) : null}
      <Flex justify="flex-end">
        {selectedFile && (
          <Button
            size="small"
            appearance="transparent"
            disabled={disabled}
            icon={
              <ClearIcon
                style={{
                  transform: "scale(0.6)",
                  color: theme.palette.redDark,
                }}
              />
            }
            onClick={() => {
              const updatedItem = {
                ...item,
                insurerForm: {
                  name: null,
                  base64String: null,
                  type: null,
                },
                isEdited: !!selectedFile,
              };
              setSelectedFile(null);

              item.updateState(updatedItem);
            }}
          />
        )}
        <Button
          size="small"
          appearance="transparent"
          disabled={disabled}
          icon={
            <>
              <ArrowUploadRegular />
              <input
                ref={fileInputRef}
                onChange={handleFileChange}
                type="file"
                accept="*"
                hidden
              />
            </>
          }
          onClick={() => {
            if (!fileInputRef.current) return;
            fileInputRef.current.click();
          }}
        >
          {selectedFile ? null : (
            <Text
              style={{
                cursor: "pointer",
              }}
            >
              {t("hbh.attachment.upload.label")}
            </Text>
          )}
        </Button>
      </Flex>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
`;
