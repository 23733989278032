import { Button } from "@fluentui/react-components";
import { SendPersonRegular } from "@fluentui/react-icons";
import { StyledTooltip } from "components/styled";
import { useAppTheme } from "hooks/useAppTheme";
import { EmployeeRowItem } from "library/types";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useResendEmployeeInvitation } from "state/queries/useResendEmployeeInvitation";

type Props = {
  employeeData: EmployeeRowItem;
};

export function ResendEmployeeInvitation({ employeeData }: Props) {
  const { t } = useTranslation();
  const { palette } = useAppTheme();

  const { mutate: onResendInvitation, isLoading: sendInvitationInProgress } =
    useResendEmployeeInvitation();

  const disableResendInvitationEmail =
    !employeeData?.selected || sendInvitationInProgress;

  return (
    <StyledTooltip
      relationship="label"
      content={{
        children: t("hbh.resendInvitation.label"),
      }}
    >
      <Button
        icon={
          <SendPersonRegular
            style={{
              ...(disableResendInvitationEmail
                ? {}
                : { color: palette.themePrimary }),

              transform: "scale(1.1)",
            }}
          />
        }
        disabled={disableResendInvitationEmail}
        appearance="transparent"
        size="small"
        onClick={(e) => {
          e.stopPropagation();
          onResendInvitation(
            {
              payload: {
                clientInternalNumberGos:
                  employeeData.clientInternalNumberGos || 0,
                countryCode: employeeData.countryCode || 0,
                emailList: [employeeData.employeeLoginEmailAddress || ""],
              },
            },
            {
              onSuccess: () => {
                toast.success(t("hbh.invitationSentSuccessfully.label"));
              },
            }
          );
        }}
      />
    </StyledTooltip>
  );
}
