import FormFieldLabel from "components/FormFieldLabel/FormFieldLabel";
import {
  ErrorMessageContainer,
  FormFieldContainer,
  StyledErrorMessage,
} from "components/styled";
import debounce from "debounce-promise";
import { useAppTheme } from "hooks/useAppTheme";
import { useAtom } from "jotai";
import { SelectItem } from "library/types";
import { useTranslation } from "react-i18next";
import AsyncSelect from "react-select/async";
import { getClients } from "state/queries/useGetClients";
import { selectedCompanyState } from "store/UICompanyPage";

type Props = {
  selectedCountry: SelectItem | null;
  noLabel?: boolean;
  noErrorSection?: boolean;
};
export function CompanySelect({
  selectedCountry,
  noLabel,
  noErrorSection,
}: Props) {
  const { t } = useTranslation();
  const theme = useAppTheme();
  const [selectedCompany, setSelectedCompany] = useAtom(selectedCompanyState);
  const error = false;

  const disabled = !selectedCountry;

  const onChange = (option) => {
    setSelectedCompany(option);
  };

  return (
    <FormFieldContainer>
      {noLabel ? null : (
        <FormFieldLabel label={t("hbh.selectCompany.label")} required={true} />
      )}
      <AsyncSelect
        key={selectedCountry?.value}
        isDisabled={disabled}
        cacheOptions
        placeholder={t("hbh.selectCompany.label")}
        loadingMessage={() => t("greco.loading")}
        noOptionsMessage={() => t("greco.noResults")}
        // onChange={onChange}

        defaultOptions={false}
        isClearable={true}
        onChange={onChange}
        value={selectedCompany}
        // value={selectedCompany as any}
        loadOptions={debounce(async (input: string) => {
          const trimmedFilter = input.trim();
          if (trimmedFilter.length === 0) return [];
          const clients = await getClients({
            countryCode: selectedCountry?.value || null,
            letters: trimmedFilter,
          });
          const options = (clients || [])?.map((option) => {
            return {
              label: option?.name?.toString(),
              value: option?.internalNumber,
              ...option,
            };
          });

          return options;
        }, 500)}
        name={"country"}
        styles={{
          container: (baseStyles) => ({
            ...baseStyles,
            position: "relative",
          }),
          control: (baseStyles, state) => ({
            ...baseStyles,
            background: theme.palette.themeLighterAlt,
            border: (() => {
              if (state.isFocused) {
                return `1px solid ${theme.palette.neutralLight}`;
              }

              return `1px solid ${theme.palette.neutralLight}`;
            })(),
            borderColor: theme.palette.neutralLight,
            borderRadius: "3px",
            height: "32px !important",
            minHeight: "32px !important",
            borderBottom: (() => {
              if (error) {
                return `2px solid ${theme.palette.redDark} !important`;
              }
              if (state.isFocused) {
                return `2px solid ${theme.palette.themePrimary} !important`;
              }

              return `2px solid ${theme.palette.neutralLight}`;
            })(),
            boxShadow: "none",
            "&:hover": {
              border: `1px solid ${theme.palette.neutralLight}`,
            },
          }),
          menu: (p) => ({
            ...p,
            background: theme.palette.neutralLighter,
            zIndex: 5,
          }),
          option: (p, state) => ({
            ...p,
            background:
              state.isSelected || state.isFocused
                ? theme.palette.neutralLighter
                : theme.palette.white,
            "&:hover": {
              background: theme.palette.neutralLighter,
            },
            color: theme.palette.black,
            fontSize: "14px",
            fontWeight: 400,
          }),
          input: (p) => ({
            ...p,
            margin: "4px",
            color: (function () {
              if (disabled) {
                return theme.palette.neutralSecondaryAlt;
              }

              return theme.palette.black;
            })(),
          }),
          valueContainer: (p, state) => ({
            ...p,
            padding: "1px 8px !important",
          }),
          placeholder: (p, state) => ({
            ...p,
            padding: "1px 8px !important",
          }),
          singleValue: (p, state) => ({
            ...p,
            fontSize: "14px",
            marginLeft: "5px",
            fontWeight: 400,
            color: theme.palette.black,
          }),
          dropdownIndicator: (p, state) => ({
            ...p,
            ...(state.isDisabled && { visibility: "hidden" }),
          }),
          indicatorsContainer: (p, state) => ({
            ...p,
            height: "32px !important",
            minHeight: "32px !important",
          }),
          indicatorSeparator: (p, state) => ({
            ...p,
            visibility: "hidden",
          }),
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
            overflow: "visible",
          }),
        }}
      />
      {noErrorSection ? null : (
        <ErrorMessageContainer>
          <StyledErrorMessage>{error}</StyledErrorMessage>
        </ErrorMessageContainer>
      )}
    </FormFieldContainer>
  );
}
