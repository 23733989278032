import {
  Breadcrumb,
  BreadcrumbButton,
  BreadcrumbDivider,
  BreadcrumbItem,
} from "@fluentui/react-components";
import { DocumentOnePageRegular, GiftRegular } from "@fluentui/react-icons";
import { SearchBoxField } from "components/controls/SearchBoxField";
import { ResultsScore } from "components/ResultsScore/ResultsScore";
import { useIsMobile } from "hooks/useIsMobile";
import { devices, ROUTES } from "library/constants";
import {
  IBenefitImplementation,
  IBenefitPackageView,
  IEmployeeView,
} from "library/types";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { HrPackageEmployeesRefreshBtn } from "./HrPackageEmployeesRefreshBtn";

type Props = {
  items: IEmployeeView[] | undefined[];
  setSearchFilterValue: (value: string) => void;
  disabled?: boolean;
  searchFilterValue: string;
  benefitImplementation: IBenefitImplementation;
  packageData: IBenefitPackageView;
};

export function HrPackageEmployeesSubheader({
  items,
  setSearchFilterValue,
  disabled,
  searchFilterValue,
  benefitImplementation,
  packageData,
}: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isMobile = useIsMobile();

  const handleSearch = useCallback((value) => {
    setSearchFilterValue(value.toLowerCase());
  }, []);

  return (
    <Container>
      <Wrap>
        <HrPackageEmployeesRefreshBtn />
        <SearchBoxField
          disabled={disabled}
          value={searchFilterValue}
          appearance="outline"
          placeholder="Search..."
          onSearch={handleSearch}
          width={isMobile ? "max-content" : "360px"}
        />

        <Breadcrumb aria-label="Breadcrumb default example">
          <BreadcrumbButton
            icon={<DocumentOnePageRegular />}
            onClick={() => {
              navigate(`${ROUTES.HR}`, {});
            }}
          >
            {t("hbh.hr.label")}
          </BreadcrumbButton>
          <BreadcrumbDivider />

          <BreadcrumbItem>
            <BreadcrumbButton
              icon={<GiftRegular />}
              onClick={() => {
                navigate(
                  `${ROUTES.HR}/packages/${benefitImplementation.benefitCategoryTag}/${benefitImplementation?.benefitImplementationTag}/${benefitImplementation?.countryCode}/${benefitImplementation.clientInternalNumberGos}`
                );
              }}
            >
              {packageData?.name}
            </BreadcrumbButton>
          </BreadcrumbItem>
        </Breadcrumb>
      </Wrap>

      <ResultsScore score={items?.length || 0} />
    </Container>
  );
}

const Container = styled.div<{ $showSearchFilter?: boolean }>`
  display: flex;
  width: 100%;
  padding: 10px;
  margin: auto;
  position: sticky;
  z-index: 99;
  top: 0px;
  height: ${(props) => (props.$showSearchFilter ? "auto" : "52px")};
  background-color: ${(props) => props.theme.palette.white};
  box-sizing: border-box;
  justify-content: space-between;
  gap: 5px;
  @media only screen and ${devices.md} {
    padding: 10px 30px;
  }
`;

const Wrap = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 5px;
  @media only screen and ${devices.md} {
  }
`;
