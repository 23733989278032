import useNotificationHandler from "hooks/useNotificationHandler";
import { MUTATION_KEYS, QUERY_KEYS } from "library/shared";
import { Attachment, IEmployeeView } from "library/types";
import { useMutation, useQueryClient } from "react-query";
import api from "service/api";
import { useInvalidateCompanyEmployeeViews } from "./useGetCompanyEmployeeViews";
import { useInvalidateNeedsAttentionData } from "./useGetNeedsAttentionData";

type Params = {
  countryCode: number | string;
  clientInternalNumberGOS: number | string;
  benefitImplementationTag: number | string;
  benefitPackageTag: number | string;
  employeeLoginEmailAddress: string;
  employeeEnrollmentTag: number | string;
  rowVersion: string;
};
const deleteEmployeeEnrollment = async ({
  countryCode,
  clientInternalNumberGOS,
  employeeLoginEmailAddress,
  benefitImplementationTag,
  benefitPackageTag,
  employeeEnrollmentTag,
  rowVersion,
}: Params) => {
  const response = await api.patch(
    `/employeeenrollment/delete/${countryCode}/${clientInternalNumberGOS}/${benefitImplementationTag}/${benefitPackageTag}/${employeeLoginEmailAddress}/${employeeEnrollmentTag}`,
    {
      rowVersion,
    }
  );
  return response.data as Attachment;
};

export function useDeleteEmployeeEnrollment(
  refetch = true,
  onlyActiveImplementations = true
) {
  const queryClient = useQueryClient();
  const invalidateCompanyEmployees = useInvalidateCompanyEmployeeViews();
  const { handleAddAxiosErrorNotification } = useNotificationHandler();
  const invalidateNeedsAttentionData = useInvalidateNeedsAttentionData();

  return useMutation(deleteEmployeeEnrollment, {
    mutationKey: MUTATION_KEYS.deleteEmployeeEnrollment,
    onSuccess: (employeeEnrollment: any) => {
      invalidateNeedsAttentionData();
      if (refetch) {
        invalidateCompanyEmployees();

        queryClient.invalidateQueries({
          queryKey: QUERY_KEYS.companyAction,
        });
      } else {
        const previousEmployeeData = queryClient.getQueryData<IEmployeeView[]>([
          QUERY_KEYS.companyEmployeeViews,
          employeeEnrollment.countryCode.toString(),
          employeeEnrollment.clientInternalNumberGos.toString(),
          onlyActiveImplementations,
        ]);

        const updatedEmployeeData = previousEmployeeData?.map(
          (employee: IEmployeeView) => {
            if (
              employee.employeeLoginEmailAddress ===
                employeeEnrollment.employeeLoginEmailAddress &&
              !employee.isDependent
            ) {
              return {
                ...employee,
                expanded: true,
                selected: true,
                enrollments: [
                  ...(employee.enrollments || []).filter(
                    (enrollment) =>
                      enrollment.employeeEnrollmentTag !==
                      employeeEnrollment.employeeEnrollmentTag
                  ),
                ],
              };
            }
            return employee;
          }
        );
        queryClient.setQueryData(
          [
            QUERY_KEYS.companyEmployeeViews,
            employeeEnrollment.countryCode.toString(),
            employeeEnrollment.clientInternalNumberGos.toString(),
            onlyActiveImplementations,
          ],
          updatedEmployeeData
        );
      }
    },
    onError: (error) => {
      // handle error
      handleAddAxiosErrorNotification(error);
    },
  });
}
