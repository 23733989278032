import useNotificationHandler from "hooks/useNotificationHandler";
import { ROUTES } from "library/constants";
import { QUERY_KEYS } from "library/shared";
import { IEmployeeView } from "library/types";
import { UseQueryResult, useQuery, useQueryClient } from "react-query";
import { useLocation } from "react-router-dom";
import api from "service/api";

type Params = {
  countryCode: number | string | null;
  clientInternalNumberGOS: number | string;
  enabled?: boolean;
};

const getNeedsAttentionData = async ({
  countryCode,
  clientInternalNumberGOS,
}: Params) => {
  if (countryCode === null || clientInternalNumberGOS === null) return;
  const response = await api.get(
    `/needsattention/${countryCode}/${clientInternalNumberGOS}`
  );

  return response.data;
};

export function useGetNeedsAttentionData({
  countryCode,
  clientInternalNumberGOS,
  enabled = true,
}: Params): UseQueryResult<IEmployeeView[]> {
  const { handleAddAxiosErrorNotification } = useNotificationHandler();
  return useQuery<IEmployeeView[], Error>(
    [
      QUERY_KEYS.needsAttentionData,
      countryCode?.toString(),
      clientInternalNumberGOS?.toString(),
    ],
    () =>
      getNeedsAttentionData({
        countryCode,
        clientInternalNumberGOS,
      }),
    {
      // staleTime: 0,
      // refetchOnMount: true,

      // cacheTime: 0,

      enabled: enabled,
      onError: (error: any) => {
        // errorHandler({
        //   label: "Error",
        //   type: notificationTypes.error,
        //   value: "Error while loading offer item data",
        //   errors: error?.message,
        //   fieldName: "",
        // });
        handleAddAxiosErrorNotification(error);
      },
    }
  );
}

export const useInvalidateNeedsAttentionData = () => {
  const queryClient = useQueryClient();
  const location = useLocation();

  return () => {
    if (location.pathname.includes(ROUTES.NEEDS_ATTENTION)) {
      queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.needsAttentionData,
      });
    }
  };
};

export const useInvalidateUnconditionalNeedsAttentionData = () => {
  const queryClient = useQueryClient();

  return () => {
    queryClient.invalidateQueries({
      queryKey: QUERY_KEYS.needsAttentionData,
    });
  };
};
