import useNotificationHandler from "hooks/useNotificationHandler";
import { QUERY_KEYS } from "library/shared";
import { IClient } from "library/types";
import { UseQueryResult, useQuery, useQueryClient } from "react-query";
import api from "service/api";

type Params = {
  countryCode: number;
  internalNumber: number;
  letters?: string;
  enabled?: boolean;
};

export const getSingleClient = async ({
  countryCode,
  internalNumber,
}: Params) => {
  if (countryCode === null) return;

  const response = await api.get(`/client/${countryCode}/${internalNumber}`);
  // /${internalNumber}
  const client = response.data;
  return {
    ...client,
    text: client?.clientName || "",
    label: client?.clientName || "",
    value: client?.clientInternalNumberGos || "",
  };
};

export function useGetSingleClient({
  countryCode,
  internalNumber,
  enabled = true,
}: Params): UseQueryResult<IClient> {
  const { handleAddAxiosErrorNotification } = useNotificationHandler();

  return useQuery<IClient, Error>(
    [
      QUERY_KEYS.singleClient,
      countryCode?.toString(),
      internalNumber?.toString(),
    ],
    () => getSingleClient({ countryCode, internalNumber }),
    {
      refetchOnMount: true,
      enabled: enabled,
      onError: (error: any) => {
        handleAddAxiosErrorNotification(error);
      },
    }
  );
}

export const useInvalidateSingleClient = () => {
  const queryClient = useQueryClient();
  return () =>
    queryClient.invalidateQueries({ queryKey: QUERY_KEYS.singleClient });
};
