import { Button, Spinner } from "@fluentui/react-components";
import { ArrowUndoFilled, Open24Filled } from "@fluentui/react-icons";
import { DeleteIcon, EditIcon, SaveIcon } from "@fluentui/react-icons-mdl2";
import { AppSpinner } from "components/AppSpinner/AppSpinner";
import { ConfirmationDialog } from "components/ConfirmationDialog/ConfirmationDialog";
import { ImplementBenefitDialog } from "components/ImplementBenefitDialog/ImplementBenefitDialog";
import { Flex } from "components/styled";
import { useAppTheme } from "hooks/useAppTheme";
import { LOCATION_STATES, ROUTES } from "library/constants";
import {
  IBenefitCategory,
  IBenefitImplementation,
  IRowItem,
} from "library/types";
import { formatDateFieldForPayload } from "library/utils";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDeleteBenefitImplementation } from "state/queries/useDeleteBenefitImplementation";
import { useUpdateBenefitImplementation } from "state/queries/useUpdateBenefitImplementation";

type Props = {
  item: IRowItem;
  countryCode: number | string;
  benefitCategory?: IBenefitCategory;
  clientInternalNumberGOS?: number | string;
  benefitCategoryTag?: number | string;
  disabled?: boolean;
};

export function ImplementedBenefitActionsCell({
  item,
  countryCode,
  clientInternalNumberGOS = 0,
  benefitCategory,
  disabled,
  benefitCategoryTag = 0,
}: Props) {
  const { t } = useTranslation();
  const location = useLocation();

  const navigate = useNavigate();
  const [editOpen, setEditOpen] = useState(false);

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

  const { palette } = useAppTheme();
  const {
    mutate: updateImplementation,
    data: updatedData,

    isLoading: updateInProgress,
  } = useUpdateBenefitImplementation(false);

  const {
    mutate: deleteImplementation,
    isLoading: deleteImplementationInProgress,
  } = useDeleteBenefitImplementation();

  const toggleDeleteConfirmationState = useCallback(() => {
    setDeleteConfirmationOpen((prev) => !prev);
  }, []);

  const goToPackages = () => {
    const isHrPage = location.pathname === ROUTES.HR;

    const mainPath = isHrPage
      ? `${ROUTES.HR}/packages`
      : `${ROUTES.COMPANY_PROFILE}`;
    navigate(
      `${mainPath}/${item.benefitCategoryTag}/${item.benefitImplementationTag}/${item?.countryCode}/${item.clientInternalNumberGos}`,
      {
        state: { [LOCATION_STATES.PREV_PATH_NAME]: location.pathname },
      }
    );
  };

  const handleEditOpen = useCallback((state) => {
    setEditOpen(state);
  }, []);

  const handleDeleteBenefitImplementation = useCallback(() => {
    if (!item) return;
    deleteImplementation(
      {
        benefitCategoryTag: Number(item.benefitCategoryTag),
        clientInternalNumberGOS: Number(item.clientInternalNumberGos),
        benefitImplementationTag: Number(item.benefitImplementationTag),
        countryCode: Number(item.countryCode),
        rowVersion: item.rowVersion || "",
      },
      {
        onSuccess: () => {
          toggleDeleteConfirmationState();
          toast.success(t("hbh.implementationDeletedSuccessfully.label"));
        },
      }
    );
  }, [deleteImplementation, toggleDeleteConfirmationState, t]);

  const loading = updateInProgress || deleteImplementationInProgress;

  return (
    <>
      <div>
        {!item?.isEdited ? (
          <Flex width={"100%"} justify="center">
            <Button
              style={{
                color: palette.themePrimary,
              }}
              size="small"
              appearance="transparent"
              icon={<EditIcon />}
              onClick={() => {
                setEditOpen(true);
              }}
              disabled={loading || disabled}
            />
            <Button
              style={{
                color: palette.themePrimary,
              }}
              icon={<Open24Filled />}
              disabled={loading}
              appearance="transparent"
              size="small"
              onClick={goToPackages}
            />
            <Button
              style={{
                color: palette.redDark,
              }}
              size="small"
              appearance={"transparent"}
              disabled={loading || disabled}
              icon={<DeleteIcon />}
              onClick={() => {
                setDeleteConfirmationOpen(true);
              }}
            />
          </Flex>
        ) : loading ? (
          <>
            <Spinner size="extra-small" />
            <AppSpinner />
          </>
        ) : (
          <Flex width={"100%"} justify="center" gap={"2px"}>
            <Button
              style={{
                color: palette.themePrimary,
              }}
              icon={<Open24Filled />}
              disabled={loading}
              appearance="transparent"
              size="small"
              onClick={goToPackages}
            />
            <Button
              style={{
                color: palette.themePrimary,
              }}
              icon={<SaveIcon />}
              disabled={loading || disabled}
              appearance="transparent"
              size="small"
              onClick={() => {
                updateImplementation(
                  {
                    countryCode,
                    clientInternalNumberGOS,
                    benefitCategoryTag,
                    benefitImplementationTag: item?.benefitImplementationTag,
                    payload: {
                      ...item,
                      ...((item.coverPhoto?.base64String ||
                        item.coverPhoto?.path) && {
                        backgroundPictureContent: item.coverPhoto?.base64String,
                        backgroundPictureName: item.coverPhoto?.name,
                        backgroundPicturePath: item.coverPhoto?.path,
                        backgroundPictureMimeType: item.coverPhoto?.type,
                      }),
                      ...(!item.coverPhoto?.name && {
                        backgroundPictureContent: null,
                        backgroundPictureName: null,
                        backgroundPicturePath: null,
                        backgroundPictureMimeType: null,
                      }),
                      //
                      ...((item.insurerForm?.base64String ||
                        item.insurerForm?.path) && {
                        insurerConsentDocumentContent:
                          item.insurerForm?.base64String,
                        insurerConsentDocumentName: item.insurerForm?.name,
                        insurerConsentDocumentPath: item.insurerForm?.path,
                        insurerConsentDocumentMimeType: item.insurerForm?.type,
                      }),
                      ...(!item.insurerForm?.name && {
                        insurerConsentDocumentContent: null,
                        insurerConsentDocumentName: null,
                        insurerConsentDocumentPath: null,
                        insurerConsentDocumentMimeType: null,
                      }),

                      validFrom:
                        formatDateFieldForPayload(item?.validFrom) || null,
                      validTo: formatDateFieldForPayload(item?.validTo) || null,
                    } as IBenefitImplementation,
                  },
                  {
                    onSuccess: (benefit) => {
                      const updatedItem = {
                        ...benefit,
                        coverPhoto: {
                          name: benefit.backgroundPicturePath,
                          base64String: benefit.backgroundPictureContent,
                          type: benefit.backgroundPictureMimeType,
                        },
                        insurerForm: {
                          name: benefit.insurerConsentDocumentPath,
                          base64String: benefit.insurerConsentDocumentContent,
                          type: benefit.insurerConsentDocumentMimeType,
                        },
                        defaultState: {
                          ...benefit,

                          coverPhoto: {
                            name: benefit.backgroundPicturePath,
                            base64String: benefit.backgroundPictureContent,
                            type: benefit.backgroundPictureMimeType,
                          },
                          insurerForm: {
                            name: benefit.insurerConsentDocumentPath,
                            base64String: benefit.insurerConsentDocumentContent,
                            type: benefit.insurerConsentDocumentMimeType,
                          },
                          benefitCategoryName: item?.benefitCategoryName,
                          id: benefit.benefitImplementationTag,
                        },
                        benefitCategoryName: item?.benefitCategoryName,

                        rowVersion: benefit.rowVersion,
                        isEdited: false,
                        id: benefit.benefitImplementationTag,
                      };
                      item.updateState(updatedItem as IBenefitImplementation);
                      toast.success(
                        t("hbh.implementationUpdatedSuccessfully.label")
                      );
                    },
                  }
                );
              }}
            />
            <Button
              style={{
                color: palette.redDark,
              }}
              icon={<ArrowUndoFilled />}
              disabled={loading || disabled}
              appearance="transparent"
              size="small"
              onClick={() => {
                const updatedRowItem = updatedData as IRowItem;
                if (updatedRowItem) {
                  const updatedItem = {
                    ...updatedRowItem,
                    coverPhoto: {
                      name: updatedRowItem.backgroundPicturePath,
                      base64String: updatedRowItem.backgroundPictureContent,
                      type: updatedRowItem.backgroundPictureMimeType,
                    },
                    insurerForm: {
                      name: updatedRowItem.insurerConsentDocumentPath,
                      base64String:
                        updatedRowItem.insurerConsentDocumentContent,
                      type: updatedRowItem.insurerConsentDocumentMimeType,
                    },
                    defaultState: {
                      ...updatedRowItem,
                      coverPhoto: {
                        name: updatedRowItem.backgroundPicturePath,
                        base64String: updatedRowItem.backgroundPictureContent,
                        type: updatedRowItem.backgroundPictureMimeType,
                      },
                      insurerForm: {
                        name: updatedRowItem.insurerConsentDocumentPath,
                        base64String:
                          updatedRowItem.insurerConsentDocumentContent,
                        type: updatedRowItem.insurerConsentDocumentMimeType,
                      },
                      benefitCategoryName: item?.benefitCategoryName,
                      id: updatedRowItem.benefitImplementationTag,
                    },
                    benefitCategoryName: item?.benefitCategoryName,

                    isEdited: false,
                    id: updatedRowItem.benefitImplementationTag,
                  };
                  item.updateState(updatedItem as IBenefitImplementation, true);
                  return;
                }

                const updatedItem = {
                  ...item.defaultState,
                  defaultState: item.defaultState,
                  isEdited: false,
                } as any;
                item.updateState(updatedItem, true);
              }}
            />
            <Button
              style={{
                color: palette.redDark,
              }}
              size="small"
              appearance={"transparent"}
              disabled={loading || disabled}
              icon={<DeleteIcon />}
              onClick={() => {
                setDeleteConfirmationOpen(true);
              }}
            />
          </Flex>
        )}
      </div>
      {editOpen && (
        <ImplementBenefitDialog
          category={benefitCategory as IBenefitCategory}
          open={editOpen}
          setOpen={handleEditOpen}
          benefitImplementation={item}
        />
      )}
      {deleteConfirmationOpen && (
        <ConfirmationDialog
          isOpen={deleteConfirmationOpen}
          toggleOpen={toggleDeleteConfirmationState}
          onConfirm={handleDeleteBenefitImplementation}
          description={t("hbh.deleteObject.label", {
            object: item?.name,
          })}
          confirmLabel={t("greco.delete")}
          title={`${t("hbh.deleteBenefitImplementation.label")}: ${
            item?.name
          } `}
        />
      )}
    </>
  );
}
