import { TableCellLayout, createTableColumn } from "@fluentui/react-components";
import { ManagePackageDocuments } from "components/ManagePackageDocuments/ManagePackageDocuments";
import { CoverPhotoCell } from "components/TableCells/CoverPhotoCell";
import { TFunction } from "i18next";
import { columnNames } from "library/constants";
import { ETaxonomy, IBenefitImplementation } from "library/types";
import styled from "styled-components";
import { EditableInputCell } from "../../../TableCells/EditableInputCell";
import { PackageActionCell } from "./PackageActionCell";
import { PaidByCostCell } from "./PaidByCostCell";
import { SelectTaxonomyCell } from "../../../TableCells/SelectTaxonomyCell";
import { SelectWithYesNoOptionsCell } from "./SelectWithYesNoOptionsCell";
import { IPackageRowItem } from "./shared";
import { UsingPercentageForPaidByCell } from "./UsingPercentageForPaidByCell";

type ColumnProps = {
  hasSelection?: boolean;
  benefitImplementation?: IBenefitImplementation;
  isReadOnlyUser?: boolean;
  t: TFunction;
};

type IHeaderItem =
  | {
      toggleAllRows: (e: any) => void;
      allRowsSelected: boolean;
      selectionMode: "single" | "multiselect";
      someRowsSelected: boolean;
      toggleAllKeydown: (e: any) => void;
    }
  | any;

export const getBenefitImplementationPackagesColumns = ({
  hasSelection,
  benefitImplementation,
  isReadOnlyUser,
  t,
}: ColumnProps) => [
  // ...(hasSelection
  //   ? [
  //       createTableColumn({
  //         columnId: "select",
  //         renderHeaderCell: (item: IHeaderItem) => {
  //           if (item.selectionMode === "multiselect") {
  //             return (
  //               <TableSelectionCell
  //                 checked={
  //                   item ? true : item.someRowsSelected ? "mixed" : false
  //                 }
  //                 onClick={(e) => {
  //                   if (item.selectionMode === "multiselect") {
  //                     item.toggleAllRows(e);
  //                   }
  //                 }}
  //                 onKeyDown={item.toggleAllKeydown}
  //                 checkboxIndicator={{ "aria-label": "Select all rows " }}
  //               />
  //             );
  //           }
  //           if (item.selectionMode === "single") {
  //             return <TableSelectionCell type="radio" invisible />;
  //           }
  //           return <></>;
  //         },
  //         renderCell: (item: IPackageRowItem) => {
  //           if (!item.selectionMode) return <></>;
  //           if (item.selectionMode === "multiselect")
  //             return (
  //               <TableSelectionCell
  //                 checked={item.selected}
  //                 checkboxIndicator={{ "aria-label": "Select row" }}
  //               />
  //             );
  //           if (item.selectionMode === "single") {
  //             return (
  //               <TableSelectionCell
  //                 checked={item.selected}
  //                 type="radio"
  //                 radioIndicator={{ "aria-label": "Select row" }}
  //               />
  //             );
  //           }
  //         },

  //         compare: (a, b) => {
  //           return (a?.benefitCategoryTag || 0) - (b?.benefitCategoryTag || 0);
  //         },
  //       }),
  //     ]
  //   : [
  //       createTableColumn({
  //         columnId: "id",
  //         renderHeaderCell: () => <>{columnNames.id}</>,
  //         renderCell: (item: IPackageRowItem) => {
  //           return <TableCellLayout>{item.benefitCategoryTag}</TableCellLayout>;
  //         },

  //         compare: (a, b) => {
  //           return (a?.benefitCategoryTag || 0) - (b?.benefitCategoryTag || 0);
  //         },
  //       }),
  //     ]),
  createTableColumn({
    columnId: "coverPhoto",
    renderHeaderCell: () => <>{t(`hbh.coverPicture.label`)}</>,
    renderCell: (item: IPackageRowItem) => {
      return <CoverPhotoCell item={item} disabled={isReadOnlyUser} />;
    },

    compare: (a, b) => {
      return (a?.coverPhoto?.name || "")?.localeCompare(
        b?.coverPhoto?.name || ""
      );
    },
  }),
  createTableColumn({
    columnId: "name",
    renderHeaderCell: () => <>{t("hbh.name.label")}</>,
    renderCell: (item: IPackageRowItem) => {
      return (
        <EditableInputCell
          item={item}
          fieldName="name"
          readOnly={isReadOnlyUser}
        />
      );
    },

    compare: (a, b) => {
      return (a?.name || "").localeCompare(b?.name || "");
    },
  }),

  // createTableColumn({
  //   columnId: "currencyCode",
  //   renderHeaderCell: () => <>{columnNames.currency}</>,
  //   renderCell: (item: IPackageRowItem) => {
  //     return (
  //       <CurrencyCell currencyCode={benefitImplementation?.currencyCode} />
  //     );
  //   },

  //   compare: (a, b) => {
  //     return a?.currencyCode - b?.currencyCode;
  //   },
  // }),
  createTableColumn({
    columnId: "usingPercentagesForPaidBy",
    renderHeaderCell: () => <>{t("hbh.valueType.label")}</>,
    renderCell: (item: IPackageRowItem) => {
      return (
        <SelectCellWrap>
          <UsingPercentageForPaidByCell item={item} readOnly={isReadOnlyUser} />
        </SelectCellWrap>
      );
    },

    compare: (a, b) => {
      return Number(a?.paidByCompany) - Number(b?.paidByCompany);
    },
  }),
  createTableColumn({
    columnId: "price",
    renderHeaderCell: () => <>{t("hbh.premiumCost.label")}</>,
    renderCell: (item: IPackageRowItem) => {
      return (
        <EditableInputCell
          type="number"
          item={item}
          fieldName="price"
          readOnly={isReadOnlyUser}
        />
      );
    },

    compare: (a, b) => {
      return Number(a?.price) - Number(b?.price);
    },
  }),
  createTableColumn({
    columnId: "paymentMethod",
    renderHeaderCell: () => <>{t("hbh.paymentMethod.label")}</>,
    renderCell: (item: IPackageRowItem) => {
      return (
        <SelectCellWrap>
          <SelectTaxonomyCell
            readOnly={isReadOnlyUser}
            item={item}
            fieldName="paymentMethod"
            taxonomyName={ETaxonomy.PaymentMethod}
          />
        </SelectCellWrap>
      );
    },

    compare: (a, b) => {
      return (a?.policyOrContractNumber || "").localeCompare(
        b?.policyOrContractNumber || ""
      );
    },
  }),
  createTableColumn({
    columnId: "paidByCompany",
    renderHeaderCell: () => <>{t("hbh.paidByCompany.label")}</>,
    renderCell: (item: IPackageRowItem) => {
      return (
        <PaidByCostCell
          item={item}
          readOnly={isReadOnlyUser}
          fieldName="paidByCompany"
          currencyCode={benefitImplementation?.currencyCode}
        />
      );
    },

    compare: (a, b) => {
      return Number(a?.paidByCompany) - Number(b?.paidByCompany);
    },
  }),
  createTableColumn({
    columnId: "paidByEmployee",
    renderHeaderCell: () => <>{t("hbh.paidByEmployee.label")}</>,
    renderCell: (item: IPackageRowItem) => {
      return (
        <PaidByCostCell
          item={item}
          fieldName="paidByEmployee"
          readOnly={isReadOnlyUser}
          currencyCode={benefitImplementation?.currencyCode}
        />
      );
    },

    compare: (a, b) => {
      return Number(a?.paidByEmployee) - Number(b?.paidByEmployee);
    },
  }),

  createTableColumn({
    columnId: "policyOrContractNumber",
    renderHeaderCell: () => <>{t("hbh.policyOrContractNumber.label")}</>,
    renderCell: (item: IPackageRowItem) => {
      return (
        // <SelectCellWrap>
        <TableCellLayout>
          {benefitImplementation?.policyOrContractNumber}
        </TableCellLayout>
        // </SelectCellWrap>
      );
    },

    compare: (a, b) => {
      return (a?.policyOrContractNumber || "").localeCompare(
        b?.policyOrContractNumber || ""
      );
    },
  }),
  createTableColumn({
    columnId: "paymentType",
    renderHeaderCell: () => <>{t("hbh.paymentType.label")}</>,
    renderCell: (item: IPackageRowItem) => {
      return (
        <SelectCellWrap>
          <SelectTaxonomyCell
            readOnly={isReadOnlyUser}
            item={item}
            fieldName="paymentType"
            taxonomyName={ETaxonomy.PaymentType}
          />
        </SelectCellWrap>
      );
    },

    compare: (a, b) => {
      return (a?.policyOrContractNumber || "").localeCompare(
        b?.policyOrContractNumber || ""
      );
    },
  }),
  //eligibility

  createTableColumn({
    columnId: "eligibleFromDateType",
    renderHeaderCell: () => <>{t("hbh.eligibleFromDate.label")}</>,
    renderCell: (item: IPackageRowItem) => {
      return (
        <SelectCellWrap>
          <SelectTaxonomyCell
            readOnly={isReadOnlyUser}
            item={item}
            fieldName="eligibleFromDateType"
            taxonomyName={ETaxonomy.EligibleDateType}
          />
        </SelectCellWrap>
      );
    },

    compare: (a, b) => {
      return a?.eligibleFromDateType || 0 - (b?.eligibleFromDateType || 0);
    },
  }),
  createTableColumn({
    columnId: "eligibleFromType",
    renderHeaderCell: () => <>{t("hbh.eligibleFrom.label")}</>,
    renderCell: (item: IPackageRowItem) => {
      return (
        <SelectCellWrap>
          <SelectTaxonomyCell
            readOnly={isReadOnlyUser}
            item={item}
            fieldName="eligibleFromType"
            taxonomyName={ETaxonomy.EligibleType}
          />
        </SelectCellWrap>
      );
    },

    compare: (a, b) => {
      return a?.eligibleFromType || 0 - (b?.eligibleFromType || 0);
    },
  }),
  createTableColumn({
    columnId: "eligibleFromValue",
    renderHeaderCell: () => <>{t("hbh.eligibleFromValue.label")}</>,
    renderCell: (item: IPackageRowItem) => {
      return (
        <EditableInputCell
          readOnly={isReadOnlyUser}
          item={item}
          fieldName="eligibleFromValue"
          type="number"
        />
      );
    },

    compare: (a, b) => {
      return a?.eligibleFromValue || 0 - (b?.eligibleFromValue || 0);
    },
  }),
  createTableColumn({
    columnId: "eligibleToDateType",
    renderHeaderCell: () => <>{t("hbh.eligibleToDate.label")}</>,
    renderCell: (item: IPackageRowItem) => {
      return (
        <SelectCellWrap>
          <SelectTaxonomyCell
            item={item}
            readOnly={isReadOnlyUser}
            fieldName="eligibleToDateType"
            taxonomyName={ETaxonomy.EligibleDateType}
          />
        </SelectCellWrap>
      );
    },

    compare: (a, b) => {
      return a?.eligibleToDateType || 0 - (b?.eligibleToDateType || 0);
    },
  }),
  createTableColumn({
    columnId: "eligibleToType",
    renderHeaderCell: () => <>{t("hbh.eligibleTo.label")}</>,
    renderCell: (item: IPackageRowItem) => {
      return (
        <SelectCellWrap>
          <SelectTaxonomyCell
            readOnly={isReadOnlyUser}
            item={item}
            fieldName="eligibleToType"
            taxonomyName={ETaxonomy.EligibleType}
          />
        </SelectCellWrap>
      );
    },

    compare: (a, b) => {
      return a?.eligibleToType || 0 - (b?.eligibleToType || 0);
    },
  }),
  createTableColumn({
    columnId: "eligibleToValue",
    renderHeaderCell: () => <>{t("hbh.eligibleToValue.label")}</>,
    renderCell: (item: IPackageRowItem) => {
      return (
        <EditableInputCell
          readOnly={isReadOnlyUser}
          item={item}
          fieldName="eligibleToValue"
          type="number"
        />
      );
    },

    compare: (a, b) => {
      return a?.eligibleToValue || 0 - (b?.eligibleToValue || 0);
    },
  }),

  //cancelation

  createTableColumn({
    columnId: "cancellationPossibilityDateType",
    renderHeaderCell: () => (
      <>{t("hbh.cancellationPossibilityDateType.label")}</>
    ),
    renderCell: (item: IPackageRowItem) => {
      return (
        <SelectCellWrap>
          <SelectTaxonomyCell
            readOnly={isReadOnlyUser}
            item={item}
            fieldName="cancellationPossibilityDateType"
            taxonomyName={ETaxonomy.OffboardingDateType}
          />
        </SelectCellWrap>
      );
    },

    compare: (a, b) => {
      return (
        a?.cancellationPossibilityDateType ||
        0 - (b?.cancellationPossibilityDateType || 0)
      );
    },
  }),
  createTableColumn({
    columnId: "cancellationPossibilityType",
    renderHeaderCell: () => <>{t("hbh.cancellationPossibilityType.label")}</>,
    renderCell: (item: IPackageRowItem) => {
      return (
        <SelectCellWrap>
          <SelectTaxonomyCell
            readOnly={isReadOnlyUser}
            item={item}
            fieldName="cancellationPossibilityType"
            taxonomyName={ETaxonomy.EligibleType}
          />
        </SelectCellWrap>
      );
    },

    compare: (a, b) => {
      return (
        a?.cancellationPossibilityType ||
        0 - (b?.cancellationPossibilityType || 0)
      );
    },
  }),
  createTableColumn({
    columnId: "cancellationPossibilityValue",
    renderHeaderCell: () => <>{t("hbh.cancellationPossibilityValue.label")}</>,
    renderCell: (item: IPackageRowItem) => {
      return (
        <EditableInputCell
          readOnly={isReadOnlyUser}
          item={item}
          fieldName="cancellationPossibilityValue"
          type="number"
        />
      );
    },

    compare: (a, b) => {
      return a?.eligibleFromValue || 0 - (b?.eligibleFromValue || 0);
    },
  }),
  //offboarding

  createTableColumn({
    columnId: "offboardingDateType",
    renderHeaderCell: () => <>{t("hbh.offboardingDateType.label")}</>,
    renderCell: (item: IPackageRowItem) => {
      return (
        <SelectCellWrap>
          <SelectTaxonomyCell
            readOnly={isReadOnlyUser}
            item={item}
            fieldName="offboardingDateType"
            taxonomyName={ETaxonomy.OffboardingDateType}
          />
        </SelectCellWrap>
      );
    },

    compare: (a, b) => {
      return a?.offboardingDateType || 0 - (b?.offboardingDateType || 0);
    },
  }),
  createTableColumn({
    columnId: "offboardingType",
    renderHeaderCell: () => <>{t("hbh.offboardingType.label")}</>,
    renderCell: (item: IPackageRowItem) => {
      return (
        <SelectCellWrap>
          <SelectTaxonomyCell
            readOnly={isReadOnlyUser}
            item={item}
            fieldName="offboardingType"
            taxonomyName={ETaxonomy.EligibleType}
          />
        </SelectCellWrap>
      );
    },

    compare: (a, b) => {
      return a?.offboardingType || 0 - (b?.offboardingType || 0);
    },
  }),
  createTableColumn({
    columnId: "offboardingValue",
    renderHeaderCell: () => <>{t("hbh.offboardingValue.label")}</>,
    renderCell: (item: IPackageRowItem) => {
      return (
        <EditableInputCell
          item={item}
          readOnly={isReadOnlyUser}
          fieldName="offboardingValue"
          type="number"
        />
      );
    },

    compare: (a, b) => {
      return a?.offboardingValue || 0 - (b?.offboardingValue || 0);
    },
  }),

  //
  createTableColumn({
    columnId: "isAutoEnrollment",
    renderHeaderCell: () => <>{t("hbh.isAutoEnrollment.label")}</>,
    renderCell: (item: IPackageRowItem) => {
      return (
        <SelectCellWrap>
          <SelectWithYesNoOptionsCell
            fieldName="isAutoEnrollment"
            item={item}
            readOnly={isReadOnlyUser}
          />
        </SelectCellWrap>
      );
    },

    compare: (a, b) => {
      return (a?.isAutoEnrollment ? 1 : 0) - (b?.isAutoEnrollment ? 1 : 0);
    },
  }),
  createTableColumn({
    columnId: "isUpgradeable",
    renderHeaderCell: () => <>{t("hbh.isUpgradeable.label")}</>,
    renderCell: (item: IPackageRowItem) => {
      return (
        <SelectCellWrap>
          <SelectWithYesNoOptionsCell
            fieldName="isUpgradeable"
            item={item}
            readOnly={isReadOnlyUser}
          />
        </SelectCellWrap>
      );
    },

    compare: (a, b) => {
      return (a?.isUpgradeable ? 1 : 0) - (b?.isUpgradeable ? 1 : 0);
    },
  }),

  createTableColumn({
    columnId: "requiresEnrollmentApproval",
    renderHeaderCell: () => <>{t("hbh.requiresEnrollmentApproval.label")}</>,
    renderCell: (item: IPackageRowItem) => {
      return (
        <SelectCellWrap>
          <SelectWithYesNoOptionsCell
            fieldName="requiresEnrollmentApproval"
            item={item}
            readOnly={isReadOnlyUser}
          />
        </SelectCellWrap>
      );
    },

    compare: (a, b) => {
      return (
        (a?.requiresEnrollmentApproval ? 1 : 0) -
        (b?.requiresEnrollmentApproval ? 1 : 0)
      );
    },
  }),
  createTableColumn({
    columnId: "requiresCancellationApproval",
    renderHeaderCell: () => <>{t("hbh.requiresCancellationApproval.label")}</>,
    renderCell: (item: IPackageRowItem) => {
      return (
        <SelectCellWrap>
          <SelectWithYesNoOptionsCell
            fieldName="requiresCancellationApproval"
            item={item}
            readOnly={isReadOnlyUser}
          />
        </SelectCellWrap>
      );
    },

    compare: (a, b) => {
      return (
        (a?.requiresCancellationApproval ? 1 : 0) -
        (b?.requiresCancellationApproval ? 1 : 0)
      );
    },
  }),

  createTableColumn({
    columnId: "manageDocuments",
    renderHeaderCell: () => <>{t("hbh.documents.label")}</>,
    renderCell: (item: IPackageRowItem) => {
      return (
        <ManagePackageDocuments
          readOnly={isReadOnlyUser}
          item={{
            ...item,
            currencyCode: benefitImplementation?.currencyCode || 0,
          }}
        />
      );
    },

    compare: (a, b) => {
      return (a?.name || "").localeCompare(b?.name || "");
    },
  }),
  createTableColumn({
    columnId: "actionsCell",
    renderHeaderCell: () => <>{columnNames.actions}</>,
    renderCell: (item: IPackageRowItem) => {
      return (
        <PackageActionCell
          readOnly={isReadOnlyUser}
          item={item}
          benefitImplementation={benefitImplementation}
        />
      );
    },

    // compare: (a, b) => {
    //   return a.enrollmentProcessNeeded - b.enrollmentProcessNeeded;
    // },
  }),
];

export const defaultColumnSizingOptions = {
  id: {
    idealWidth: 30,
    minWidth: 30,
    defaultWidth: 30,
  },
  select: {
    idealWidth: 30,
    minWidth: 30,
  },
  name: {
    idealWidth: 200,
    minWidth: 200,
  },

  manageDocuments: {
    idealWidth: 260,
    minWidth: 260,
  },

  policyOrContractNumber: {
    idealWidth: 200,
    minWidth: 200,
  },
  currencyCode: {
    idealWidth: 200,
    minWidth: 200,
  },
  usingPercentagesForPaidBy: {
    idealWidth: 200,
    minWidth: 200,
  },
  paidByCompany: {
    idealWidth: 200,
    minWidth: 200,
  },
  coverPhoto: {
    minWidth: 90,
    defaultWidth: 90,
  },
  paidByEmployee: {
    idealWidth: 200,
    minWidth: 200,
  },
  price: {
    idealWidth: 200,
    minWidth: 200,
  },
  paymentMethod: {
    idealWidth: 200,
    minWidth: 200,
  },
  paymentType: {
    idealWidth: 200,
    minWidth: 200,
  },
  eligibleFromDateType: {
    idealWidth: 200,
    minWidth: 200,
  },
  eligibleFromType: {
    idealWidth: 200,
    minWidth: 200,
  },
  eligibleToDateType: {
    idealWidth: 200,
    minWidth: 200,
  },
  eligibleFromValue: {
    idealWidth: 150,
    minWidth: 150,
  },
  //
  cancellationPossibilityDateType: {
    idealWidth: 200,
    minWidth: 200,
  },
  cancellationPossibilityType: {
    idealWidth: 200,
    minWidth: 200,
  },
  cancellationPossibilityValue: {
    idealWidth: 150,
    minWidth: 150,
  },
  isAutoEnrollment: {
    idealWidth: 150,
    minWidth: 150,
  },
  isUpgradeable: {
    idealWidth: 150,
    minWidth: 150,
  },
  requiresEnrollmentApproval: {
    idealWidth: 200,
    minWidth: 200,
  },
  requiresCancellationApproval: {
    idealWidth: 200,
    minWidth: 200,
  },
  offboardingType: {
    idealWidth: 200,
    minWidth: 200,
  },
  offboardingDateType: {
    idealWidth: 200,
    minWidth: 200,
  },
  offboardingValue: {
    idealWidth: 150,
    minWidth: 150,
  },
  actionsCell: {
    minWidth: 100,
    idealWidth: 100,
    defaultWidth: 100,
  },
};

const SelectCellWrap = styled.div`
  .fui-Select__select,
  .fui-Select__select:focus,
  .fui-Select__select:focus-within {
    border-bottom: none;
  }

  .fui-Combobox,
  .fui-Combobox:focus {
    border-bottom: none;
  }
`;
