import { DocumentArrowDownRegular } from "@fluentui/react-icons";
import { ActionButton, StyledTooltip } from "components/styled";
import { useAtomValue } from "jotai";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useExportEmployeeIConsents } from "state/queries/useExportEmployeeIConsents";
import {
  companyEmployeeViewsAtom,
  onlyActiveImplementationsState,
  selectedCompanyState,
  selectedTableViewEmployeesAtom,
} from "store/UIHrPage";

import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Text,
} from "@fluentui/react-components";
import { AppSpinner } from "components/AppSpinner/AppSpinner";
import CheckboxField from "components/controls/CheckboxField";
import { PrimaryButton } from "components/PrimaryButton/PrimaryButton";
import { useState } from "react";

type Props = {
  employeeEmail?: string;
  employeeName?: string;
};

export function ExportConsents({ employeeEmail, employeeName }: Props) {
  const selectedEmployees = useAtomValue(selectedTableViewEmployeesAtom);
  const companyEmployees = useAtomValue(companyEmployeeViewsAtom);

  const onlyActive = useAtomValue(onlyActiveImplementationsState);
  const company = useAtomValue(selectedCompanyState);
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const [isSalaryDeductionConsent, setIsSalaryDeductionConsent] =
    useState(false);

  const allSelected = selectedEmployees?.length === companyEmployees?.length;

  const singleExport = !!employeeEmail;

  const emailList = singleExport
    ? [employeeEmail]
    : selectedEmployees.map((employee) => employee.employeeLoginEmailAddress);
  const { mutate: onExportConsents, isLoading } = useExportEmployeeIConsents();

  return (
    <>
      <StyledTooltip
        relationship="label"
        content={{
          children: t("hbh.exportConsents.label"),
        }}
      >
        <ActionButton
          noBorder
          appearance={singleExport ? "transparent" : "subtle"}
          disabled={isLoading || !emailList.length}
          icon={<DocumentArrowDownRegular />}
          onClick={() => {
            setOpen(true);
          }}
        >
          {singleExport ? null : t("hbh.exportConsents.label")}
        </ActionButton>
      </StyledTooltip>
      {open && (
        <Dialog modalType="alert" open={open}>
          <DialogSurface>
            {isLoading && <AppSpinner />}
            <DialogBody>
              <DialogTitle
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                {employeeName ? `${employeeName} - ` : ""}{" "}
                {t("hbh.exportConsents.label")}
              </DialogTitle>
              <DialogContent>
                <CheckboxField
                  noErrorSection
                  name="sendInvitationEmail"
                  required
                  value={isSalaryDeductionConsent}
                  setFieldValue={(_, value) => {
                    setIsSalaryDeductionConsent(value);
                  }}
                  label={t("hbh.isSalaryDeductionConsent.label")}
                />
                <Text>{t("hbh.exportConsentsExplained.label")}</Text>
              </DialogContent>
              <DialogActions>
                <DialogTrigger disableButtonEnhancement>
                  <Button
                    appearance="secondary"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    {t("greco.cancel")}
                  </Button>
                </DialogTrigger>
                <PrimaryButton
                  disabled={isLoading}
                  onClick={async () => {
                    onExportConsents(
                      {
                        payload: {
                          clientInternalNumberGos:
                            company?.clientInternalNumberGos || 0,
                          countryCode: company?.countryCode || 0,
                          isSalaryDeductionConsent,
                          emailList: allSelected ? (null as any) : emailList,
                          onlyActive,
                        },
                      },
                      {
                        onSuccess: () => {
                          toast.success(t("greco.success"));
                          setOpen(false);
                        },
                      }
                    );
                  }}
                >
                  {t("hbh.export.label")}
                </PrimaryButton>
              </DialogActions>
            </DialogBody>
          </DialogSurface>
        </Dialog>
      )}
    </>
  );
}
