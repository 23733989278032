import useNotificationHandler from "hooks/useNotificationHandler";
import { MUTATION_KEYS, QUERY_KEYS } from "library/shared";
import { Attachment, IEmployeeEnrollment, IEmployeeView } from "library/types";
import { useMutation, useQueryClient } from "react-query";
import api from "service/api";
import { useInvalidateCompanyEmployeeViews } from "./useGetCompanyEmployeeViews";
import { useInvalidateNeedsAttentionData } from "./useGetNeedsAttentionData";

type Params = {
  countryCode: number;
  clientInternalNumberGOS: number;
  benefitImplementationTag: number;
  benefitPackageTag: number;
  employeeDependentTag: number;
  employeeLoginEmailAddress: string;
  employeeEnrollmentTag: number;
  rowVersion: string;
};
const deleteEmployeeDependentEnrollment = async ({
  countryCode,
  clientInternalNumberGOS,
  employeeLoginEmailAddress,
  employeeDependentTag,
  benefitImplementationTag,
  benefitPackageTag,
  employeeEnrollmentTag,
  rowVersion,
}: Params) => {
  const response = await api.patch(
    `/employeeenrollment/dependent/delete/${countryCode}/${clientInternalNumberGOS}/${benefitImplementationTag}/${benefitPackageTag}/${employeeLoginEmailAddress}/${employeeDependentTag}/${employeeEnrollmentTag}`,
    {
      rowVersion,
    }
  );
  return response.data as IEmployeeEnrollment;
};

export function useDeleteEmployeeDependentEnrollment(
  refetch = true,
  onlyActiveImplementations
) {
  const queryClient = useQueryClient();
  const invalidateCompanyEmployees = useInvalidateCompanyEmployeeViews();
  const { handleAddAxiosErrorNotification } = useNotificationHandler();
  const invalidateNeedsAttentionData = useInvalidateNeedsAttentionData();

  return useMutation(deleteEmployeeDependentEnrollment, {
    mutationKey: MUTATION_KEYS.deleteEmployeeDependentEnrollment,
    onSuccess: (dependentEligibility: any) => {
      invalidateNeedsAttentionData();
      if (refetch) {
        invalidateCompanyEmployees();

        queryClient.invalidateQueries({
          queryKey: QUERY_KEYS.companyAction,
        });
      } else {
        const previousEmployeeData = queryClient.getQueryData<IEmployeeView[]>([
          QUERY_KEYS.companyEmployeeViews,
          dependentEligibility.countryCode.toString(),
          dependentEligibility.clientInternalNumberGos.toString(),
          onlyActiveImplementations,
        ]);

        const updatedEmployeeData = previousEmployeeData?.map(
          (employee: IEmployeeView) => {
            if (
              employee.employeeDependentTag ===
                dependentEligibility.employeeDependentTag &&
              employee.isDependent
            ) {
              return {
                ...employee,
                expanded: true,
                selected: true,
                enrollments: [
                  ...(employee.enrollments || []).filter(
                    (enrollment) =>
                      enrollment.employeeEnrollmentTag !==
                      dependentEligibility.employeeEnrollmentTag
                  ),
                ],
              };
            }
            return employee;
          }
        );
        queryClient.setQueryData(
          [
            QUERY_KEYS.companyEmployeeViews,
            dependentEligibility.countryCode.toString(),
            dependentEligibility.clientInternalNumberGos.toString(),
            onlyActiveImplementations,
          ],
          updatedEmployeeData
        );
      }
    },
    onError: (error) => {
      // handle error
      handleAddAxiosErrorNotification(error);
    },
  });
}
