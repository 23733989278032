import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  makeStyles,
} from "@fluentui/react-components";
import { Dismiss24Regular, PersonRegular } from "@fluentui/react-icons";
import { AppSpinner } from "components/AppSpinner/AppSpinner";
import { PrimaryButton } from "components/PrimaryButton/PrimaryButton";
import { Flex } from "components/styled";
import { Form, Formik } from "formik";
import { useTaxonomyOptions } from "hooks/useTaxonomyOptions";
import { useAtomValue } from "jotai";
import { devices } from "library/constants";
import { EmployeeRowItem, ETaxonomy, IClient, IEmployee } from "library/types";
import {
  formatDateFieldForPayload,
  formatSelectFieldForPayload,
} from "library/utils";
import { useMemo } from "react";
import { toast } from "react-toastify";
import { useCreateEmployee } from "state/queries/useCreateEmployee";
import { useUpdateEmployee } from "state/queries/useUpdateEmployee";
import {
  onlyActiveImplementationsState,
  selectedCompanyState,
} from "store/UIHrPage";
import styled from "styled-components";
import * as yup from "yup";
import { EmployeeFormFields } from "./UpdateEmployeeFields/EmployeeFormFields/EmployeeFormFields";
import { useTranslation } from "react-i18next";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  isAddEmployee?: boolean;
  item?: EmployeeRowItem;
  selectedCompany: {
    countryCode: IClient["countryCode"];
    clientInternalNumberGos: IClient["clientInternalNumberGos"];
  };
};

const isDevEnv = process.env.REACT_APP_ENVIRONMENT === "D";

export function EmployeeFormDialog({
  open,
  setOpen,
  item,
  isAddEmployee,
  selectedCompany,
}: Props) {
  const { t } = useTranslation();
  const onlyActiveImplementations = useAtomValue(
    onlyActiveImplementationsState
  );
  const genderOptions = useTaxonomyOptions(ETaxonomy.Gender);
  const countryOptions = useTaxonomyOptions(ETaxonomy.Country);
  const currencyOptions = useTaxonomyOptions(ETaxonomy.Currency);

  const { mutate: onUpdateEmployee, isLoading: updateEmployeeInProgress } =
    useUpdateEmployee(false, onlyActiveImplementations);

  const { mutate: onCreateEmployee, isLoading: createEmployeeInProgress } =
    useCreateEmployee(false, onlyActiveImplementations);

  const styles = useStyles();

  const handleSubmit = (values) => {
    if (isAddEmployee) {
      const formatDependentsForPayload = values?.dependents?.map(
        (dependent) => {
          return {
            ...dependent,
            dependentType: dependent?.dependentType?.value,
            countryCode: Number(selectedCompany?.countryCode) || 0,
            clientInternalNumberGos:
              selectedCompany?.clientInternalNumberGos || 0,
            dateOfBirth: formatDateFieldForPayload(dependent?.dateOfBirth),
            backgroundPictureName: dependent?.dependentCoverPhoto?.name,
            backgroundPictureContent:
              dependent?.dependentCoverPhoto?.base64String,
            backgroundPictureMimeType: dependent?.dependentCoverPhoto?.type,
            ...(!dependent?.dependentCoverPhoto?.base64String && {
              backgroundPicturePath: dependent?.dependentCoverPhoto?.path,
            }),
            genderCodeId: formatSelectFieldForPayload({
              values: dependent,
              field: "genderCodeId",
            }),
            citizenshipCodeId: formatSelectFieldForPayload({
              values: dependent,
              field: "citizenshipCodeId",
            }),
            employeeLoginEmailAddress: item?.employeeLoginEmailAddress,

            dateOfExit: formatDateFieldForPayload(dependent.dateOfExit),
          };
        }
      );

      onCreateEmployee(
        {
          countryCode: Number(selectedCompany?.countryCode) || 0,
          clientInternalNumberGOS:
            selectedCompany?.clientInternalNumberGos || 0,
          payload: {
            ...values,
            employeeLoginEmailAddress: values?.emailAddress,
            genderCodeId: formatSelectFieldForPayload({
              values,
              field: "genderCodeId",
            }),
            citizenshipCodeId: formatSelectFieldForPayload({
              values,
              field: "citizenshipCodeId",
            }),
            currencyCode: formatSelectFieldForPayload({
              values,
              field: "currencyCode",
            }),
            dateOfEmployment: formatDateFieldForPayload(
              values.dateOfEmployment
            ),
            ...(values.dateOfBirth
              ? { dateOfBirth: formatDateFieldForPayload(values.dateOfBirth) }
              : {}),
            backgroundPictureName: values?.employeeCoverPhoto?.name,
            backgroundPictureContent: values?.employeeCoverPhoto?.base64String,
            backgroundPictureMimeType: values?.employeeCoverPhoto?.type,
            ...(!values?.employeeCoverPhoto?.base64String && {
              backgroundPicturePath: values?.employeeCoverPhoto?.path,
            }),
            dependents: formatDependentsForPayload,

            // attachments: formatAttachmentForPayload,
          },
        },
        {
          onSuccess() {
            toast.success(t("hbh.employeeAddedSuccessfully.label"));
            setOpen(false);
          },
        }
      );
      return;
    }
    onUpdateEmployee(
      {
        countryCode: selectedCompany?.countryCode || 0,
        clientInternalNumberGOS: selectedCompany?.clientInternalNumberGos || 0,
        employeeEmail: item?.employeeLoginEmailAddress || "",
        payload: {
          ...values,
          rowVersion: item?.rowVersion,
          genderCodeId: formatSelectFieldForPayload({
            values,
            field: "genderCodeId",
          }),
          citizenshipCodeId: formatSelectFieldForPayload({
            values,
            field: "citizenshipCodeId",
          }),
          currencyCode: formatSelectFieldForPayload({
            values,
            field: "currencyCode",
          }),
          dateOfEmployment: formatDateFieldForPayload(values.dateOfEmployment),
          dateOfBirth: formatDateFieldForPayload(values.dateOfBirth),
          dateOfExit: formatDateFieldForPayload(values.dateOfExit),
          ...(values.employeeCoverPhoto?.base64String && {
            backgroundPictureContent: values.employeeCoverPhoto?.base64String,
            backgroundPictureName: values.employeeCoverPhoto?.name,
            backgroundPicturePath: values.employeeCoverPhoto?.path,
            backgroundPictureMimeType: values.employeeCoverPhoto?.type,
          }),
          backgroundPicturePath: values.employeeCoverPhoto?.path,
          dependents: values.dependents.map((dependent) => {
            return {
              ...dependent,

              rowVersion: dependent.rowVersion || item?.rowVersion,
            };
          }),
        },
      },
      {
        onSuccess() {
          toast.success(t("hbh.employeeUpdatedSuccessfully.label"));

          setOpen(false);
        },
      }
    );
  };

  const initialValues = useMemo(() => {
    if (isAddEmployee)
      return {
        dateOfEmployment: null,
        dependents: [],
      };

    return {
      //main
      ...item,
      dependents:
        (item?.dependents as IEmployee["dependents"])?.map((dependent) => {
          return {
            ...dependent,
            dependentCoverPhoto: dependent?.backgroundPicturePath
              ? {
                  name: dependent?.backgroundPictureName,
                  path: dependent?.backgroundPicturePath,
                  base64String: dependent?.backgroundPictureContent,
                  type: dependent?.backgroundPictureMimeType,
                }
              : null,
          };
        }) || [],

      //basic
      genderCodeId: genderOptions.find(
        (genderCode) => genderCode.value === item?.genderCodeId
      ),
      citizenshipCodeId: countryOptions.find(
        (country) => country.value === item?.citizenshipCodeId
      ),
      currencyCode: currencyOptions.find(
        (option) => option.value === item?.currencyCode
      ),

      employeeCoverPhoto: item?.backgroundPicturePath
        ? {
            name: item?.backgroundPictureName,
            path: item?.backgroundPicturePath,
            base64String: item?.backgroundPictureContent,
            type: item?.backgroundPictureMimeType,
          }
        : null,
    };
  }, [isAddEmployee, item, genderOptions, countryOptions, currencyOptions]);

  const validationSchema = useMemo(() => {
    if (isAddEmployee) {
      return yup.object().shape({
        firstName: yup.string().required(),
        lastName: yup.string().required(),
        emailAddress: yup.string().required(),
        dateOfEmployment: yup.mixed().required(),
        mobilePhoneNumber: yup.string().required(),
        dateOfBirth: yup.mixed().required(),

        dependents: yup.array().of(
          yup.object().shape({
            dependentType: yup.mixed().required(),
            firstName: yup.string().required(),
            lastName: yup.string().required(),
            dateOfBirth: yup.mixed().required(),
          })
        ),
        // .required("Must have friends") // these constraints are shown if and only if inner constraints are satisfied
        // .min(1, "Minimum of 3 friends"),
      });
    }
    return yup.object().shape({
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      emailAddress: yup.string().required(),
      dateOfEmployment: yup.mixed().required(),
      dateOfBirth: yup.mixed().required(),
      mobilePhoneNumber: yup.string().required(),

      // phoneNumber: yup.string().required(),
      dependents: yup.array().of(
        yup.object().shape({
          dependentType: yup.mixed().required(),
          firstName: yup.string().required(),
          lastName: yup.string().required(),
          // employeeDependentTag: yup.number().required(),
          // dateOfBirth: yup.mixed().required(),

          // emailAddress: yup.string().required(),
        })
      ),
      // .required("Must have friends") // these constraints are shown if and only if inner constraints are satisfied
      // .min(1, "Minimum of 3 friends"),
    });
  }, [isAddEmployee]);

  if (!isAddEmployee && !item) {
    return <AppSpinner />;
  }

  return (
    <Dialog
      modalType="alert"
      open={open}
      onOpenChange={(event, data) => setOpen(data.open)}
    >
      <StyledDialogSurface
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {(updateEmployeeInProgress || createEmployeeInProgress) && (
          <AppSpinner />
        )}
        <Formik
          enableReinitialize
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            handleSubmit(values);
            actions.setSubmitting(false);
          }}
        >
          {({ errors, dirty }) => {
            return (
              <Form>
                <DialogBody className={styles.content}>
                  <DialogTitle
                    action={
                      <DialogTrigger action="close">
                        <Button
                          appearance="subtle"
                          aria-label="close"
                          icon={<Dismiss24Regular />}
                        />
                      </DialogTrigger>
                    }
                  >
                    <Flex width={"100%"} justify="flex-start">
                      <PersonRegular />
                      {isAddEmployee ? (
                        t("hbh.addEmployee.label")
                      ) : (
                        <>
                          <span>{item?.firstName} </span>
                          <span>{item?.lastName}</span>
                          {isDevEnv && (
                            <span>{` (${item?.employeeLoginEmailAddress})`}</span>
                          )}
                        </>
                      )}
                    </Flex>
                  </DialogTitle>
                  <DialogContent className={styles.content}>
                    <EmployeeFormFields
                      employeeDetails={item}
                      isAddEmployee={isAddEmployee}
                    />
                  </DialogContent>
                  <DialogActions>
                    <DialogTrigger disableButtonEnhancement>
                      <Button appearance="secondary">
                        {t("greco.cancel")}
                      </Button>
                    </DialogTrigger>
                    <PrimaryButton
                      style={{
                        paddingLeft: "25px",
                        paddingRight: "25px",
                      }}
                    >
                      {!!item?.needsAttentionInformation && !dirty
                        ? t("hbh.confirm.label")
                        : t("greco.save")}
                    </PrimaryButton>
                  </DialogActions>
                </DialogBody>
              </Form>
            );
          }}
        </Formik>
      </StyledDialogSurface>
    </Dialog>
  );
}

const StyledDialogSurface = styled(DialogSurface)`
  margin-left: 10px;
  margin-right: 10px;
  width: 95%;
  min-width: 95%;
  max-height: 98vh;
  padding: 15px;

  @media only screen and ${devices.md} {
    width: 1200px;
    min-width: 1200px;
    padding: 24px;

    margin-left: auto;
    margin-right: auto;
  }
`;

const useStyles = makeStyles({
  body: {
    display: "flex",
    flexDirection: "column",
    width: "1200px",
  },
  content: {
    maxHeight: "90vh",
    scrollbarWidth: "thin",
  },
});
