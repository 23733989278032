import useNotificationHandler from "hooks/useNotificationHandler";
import { MUTATION_KEYS, QUERY_KEYS } from "library/shared";
import { IEmployee, IEmployeeView } from "library/types";
import { useMutation, useQueryClient } from "react-query";
import api from "service/api";
import { useInvalidateCompanyEmployeeViews } from "./useGetCompanyEmployeeViews";
import { useInvalidateNeedsAttentionData } from "./useGetNeedsAttentionData";

type Params = {
  countryCode: number | string;
  clientInternalNumberGOS: number | string;
  payload: IEmployee;
};

const createEmployee = async ({
  countryCode,
  clientInternalNumberGOS,
  payload,
}: Params) => {
  const response = await api.post(
    `/employee/${countryCode}/${clientInternalNumberGOS}`,
    payload
  );

  return response.data as IEmployee;
};

export function useCreateEmployee(
  refetch = true,
  onlyActiveImplementations = true
) {
  const queryClient = useQueryClient();

  const invalidateCompanyEmployees = useInvalidateCompanyEmployeeViews();
  const { handleAddAxiosErrorNotification } = useNotificationHandler();

  const invalidateNeedsAttentionData = useInvalidateNeedsAttentionData();

  return useMutation(createEmployee, {
    mutationKey: MUTATION_KEYS.createEmployee,
    onSuccess: (newEmployee) => {
      invalidateNeedsAttentionData();
      if (refetch) {
        invalidateCompanyEmployees();
      } else {
        const previousEmployeeData =
          queryClient.getQueryData<IEmployeeView[]>([
            QUERY_KEYS.companyEmployeeViews,
            newEmployee?.countryCode?.toString(),
            newEmployee?.clientInternalNumberGos?.toString(),
            onlyActiveImplementations,
          ]) || [];

        const updatedEmployeeData = [
          ...previousEmployeeData,
          {
            ...newEmployee,
            isDependent: false,
          },
        ];
        queryClient.setQueryData(
          [
            QUERY_KEYS.companyEmployeeViews,
            newEmployee?.countryCode?.toString(),
            newEmployee?.clientInternalNumberGos?.toString(),
            onlyActiveImplementations,
          ],
          updatedEmployeeData
        );
      }
    },
    onError: (error) => {
      // handle error
      handleAddAxiosErrorNotification(error);
    },
  });
}
