// /benefitcategory/attachment/{countryCode}/{clientInternalNumberGos}/{benefitCategoryTag}:
import useNotificationHandler from "hooks/useNotificationHandler";
import { MUTATION_KEYS, QUERY_KEYS } from "library/shared";
import { Attachment, IEmployee } from "library/types";
import { useMutation, useQueryClient } from "react-query";
import api from "service/api";
import { useInvalidateEmployeeDetails } from "./useGetEmployeeDetails";

type Params = {
  countryCode: number | string;
  employeeLoginEmailAddress: string;
  clientInternalNumberGOS: number | string;
  payload: Attachment;
};

const createEmployeeAttachment = async ({
  countryCode,
  clientInternalNumberGOS,
  employeeLoginEmailAddress,
  payload,
}: Params) => {
  const response = await api.post(
    `/employee/attachment/${countryCode}/${clientInternalNumberGOS}/${employeeLoginEmailAddress}`,
    payload
  );

  return response.data;
};

export function useCreateEmployeeAttachment(refetch = true) {
  const queryClient = useQueryClient();
  const invalidateEmployeeDetails = useInvalidateEmployeeDetails();
  const { handleAddAxiosErrorNotification } = useNotificationHandler();

  return useMutation(createEmployeeAttachment, {
    mutationKey: MUTATION_KEYS.createEmployeeAttachment,
    onSuccess: (newAttachment: Attachment) => {
      if (refetch) {
        invalidateEmployeeDetails();
      } else {
        const previousEmployeeData = queryClient.getQueryData<IEmployee>([
          QUERY_KEYS.employeeDetails,
          newAttachment?.countryCode?.toString(),
          newAttachment?.clientInternalNumberGos?.toString(),
          newAttachment?.attachmentObjectTag,
        ]);
        //is false when stale time is 0 on employee details req
        const updatedEmployeeData = {
          ...previousEmployeeData,
          attachments: [
            ...(previousEmployeeData?.attachments || []),
            newAttachment,
          ],
        };
        queryClient.setQueryData(
          [
            QUERY_KEYS.employeeDetails,
            newAttachment?.countryCode?.toString(),
            newAttachment?.clientInternalNumberGos?.toString(),
            newAttachment?.attachmentObjectTag,
          ],
          updatedEmployeeData
        );
      }
    },
    onError: (error) => {
      // handle error
      handleAddAxiosErrorNotification(error);
    },
  });
}
