import useNotificationHandler from "hooks/useNotificationHandler";
import { MUTATION_KEYS, QUERY_KEYS } from "library/shared";
import { IEmployeeEnrollment, IEmployeeView } from "library/types";
import { useMutation, useQueryClient } from "react-query";
import api from "service/api";
import { useInvalidateCompanyEmployeeViews } from "./useGetCompanyEmployeeViews";

type Params = {
  countryCode: number | string;
  clientInternalNumberGOS: number | string;
  benefitImplementationTag: number | string;
  benefitPackageTag: number | string;
  payload: IEmployeeEnrollment;
  employeeEmail: string;
};

const createDependentEligibility = async ({
  countryCode,
  clientInternalNumberGOS,
  employeeEmail,
  benefitImplementationTag,
  benefitPackageTag,
  payload,
}: Params) => {
  const response = await api.post(
    `/employeeenrollment/dependent/${countryCode}/${clientInternalNumberGOS}/${benefitImplementationTag}/${benefitPackageTag}/${employeeEmail}`,
    payload
  );
  return response.data as IEmployeeEnrollment;
};

export function useCreateDependentEligibility(
  refetch = true,
  onlyActiveImplementations = true
) {
  const queryClient = useQueryClient();
  const invalidateCompanyEmployees = useInvalidateCompanyEmployeeViews();
  const { handleAddAxiosErrorNotification } = useNotificationHandler();

  return useMutation(createDependentEligibility, {
    mutationKey: MUTATION_KEYS.createDependentEligibility,
    onSuccess: (dependentEligibility: any) => {
      if (refetch) {
        invalidateCompanyEmployees();
      } else {
        const previousEmployeeData = queryClient.getQueryData<IEmployeeView[]>([
          QUERY_KEYS.companyEmployeeViews,
          dependentEligibility.countryCode.toString(),
          dependentEligibility.clientInternalNumberGos.toString(),
          onlyActiveImplementations,
        ]);

        const updatedEmployeeData = previousEmployeeData?.map(
          (employee: IEmployeeView) => {
            if (
              employee.employeeDependentTag ===
                dependentEligibility.employeeDependentTag &&
              employee.isDependent
            ) {
              return {
                ...employee,
                expanded: true,
                selected: true,
                enrollments: [
                  ...(employee.enrollments || []),
                  dependentEligibility,
                ],
              };
            }
            return employee;
          }
        );
        queryClient.setQueryData(
          [
            QUERY_KEYS.companyEmployeeViews,
            dependentEligibility.countryCode.toString(),
            dependentEligibility.clientInternalNumberGos.toString(),
            onlyActiveImplementations,
          ],
          updatedEmployeeData
        );
        return dependentEligibility;
      }
    },
    onError: (error) => {
      // handle error
      handleAddAxiosErrorNotification(error);
    },
  });
}
