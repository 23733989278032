import { Switch } from "@fluentui/react-components";
import { AppSpinner } from "components/AppSpinner/AppSpinner";
import { OnlyActiveSwitch } from "components/OnlyActiveSwitch/OnlyActiveSwitch";
import { QUERY_KEYS } from "library/shared";
import { IEmployeeView } from "library/types";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useIsFetching } from "react-query";
import { EmployeesTable } from "./EmployeesTable/EmployeesTable";

import { Flex } from "components/styled";
import ResendInvitations from "../../HrPageToolbar/ResendInvitations";
import { getEnrolledEmployeesTableColumns } from "./EmployeesTable/columns";
import { ExportConsents } from "../../ExportConsents/ExportConsents";

type Props = {
  companyEmployees: IEmployeeView[] | undefined;
  onlyActiveImplementations: boolean;
  setOnlyActiveImplementation: (value) => void;
  onlyEnrolledEmployees?: boolean;
  setOnlyEnrolledEmployees?: (value) => void;
};

export function EmployeeView({
  companyEmployees,
  onlyActiveImplementations,
  setOnlyActiveImplementation,
  onlyEnrolledEmployees,
  setOnlyEnrolledEmployees,
}: Props) {
  const { t } = useTranslation();

  const isFetchingCompanyEmployeeViews = useIsFetching({
    queryKey: QUERY_KEYS.companyEmployeeViews,
  });

  const onSetOnlyActiveImplementation = useCallback(
    (value) => {
      setOnlyActiveImplementation(value);
    },
    [setOnlyActiveImplementation]
  );
  const columns = getEnrolledEmployeesTableColumns({
    t,
  });
  return (
    <div>
      {isFetchingCompanyEmployeeViews ? <AppSpinner /> : null}
      <Flex justify="space-between">
        <div style={{ display: "flex" }}>
          <OnlyActiveSwitch
            value={onlyActiveImplementations}
            setValue={onSetOnlyActiveImplementation}
          />
          <div>
            {onlyEnrolledEmployees !== null &&
              setOnlyEnrolledEmployees &&
              setOnlyEnrolledEmployees !== null && (
                <Switch
                  checked={onlyEnrolledEmployees}
                  onChange={(ev) => {
                    setOnlyEnrolledEmployees(ev.currentTarget.checked);
                  }}
                  label={t("hbh.onlyEnrolled.label")}
                />
              )}
          </div>
        </div>
        <Flex>
          <ExportConsents />
          <ResendInvitations />
        </Flex>
      </Flex>
      <EmployeesTable
        companyEmployees={companyEmployees}
        columns={columns}
        selectionMode="multiselect"
      />
    </div>
  );
}
