import {
  DocumentCheckmarkRegular,
  DocumentDismissRegular,
} from "@fluentui/react-icons";
import { AppSpinner } from "components/AppSpinner/AppSpinner";
import { ButtonWithTooltip } from "components/ButtonWithTooltip/ButtonWithTooltip";
import { ConfirmationDialog } from "components/ConfirmationDialog/ConfirmationDialog";
import DatePickerField from "components/controls/DatePickerField";
import { TextareaField } from "components/controls/TextareaField";

import { useAppTheme } from "hooks/useAppTheme";
import { useTaxonomyOptions } from "hooks/useTaxonomyOptions";
import { useAtomValue } from "jotai";
import {
  ETaxonomy,
  IBenefitPackageView,
  IEmployeeEnrollmentBase,
  IEmployeeView,
} from "library/types";
import {
  formatDateFieldForPayload,
  getEnrollmentStatusData,
} from "library/utils";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useUpdateDependentEnrollmentStatus } from "state/queries/useUpdateDependentEnrollmentStatus";
import { useUpdateEmployeeEnrollmentStatus } from "state/queries/useUpdateEmployeeEnrollmentStatus";
import { onlyActiveImplementationsState } from "store/UIHrPage";
import styled from "styled-components";

type Props = {
  packageData?: IBenefitPackageView;
  packageUser?: IEmployeeView;
  employeeEnrollment?: IEmployeeEnrollmentBase;
  disabled?: boolean;
};

export function StatusActions({
  packageData,
  packageUser,
  employeeEnrollment,
  disabled,
}: Props) {
  const theme = useAppTheme();
  const { t } = useTranslation();
  const [rejectionComment, setRejectionComment] = useState("");
  const [dateOfApproval, setDateOfApproval] = useState("");

  const enrollmentStatusOptions = useTaxonomyOptions(ETaxonomy.ApprovalStatus);

  const onlyActiveImplementations = useAtomValue(
    onlyActiveImplementationsState
  );

  const [confirmStatusActionState, setConfirmStatusActionState] = useState({
    isOpen: false,
    approve: false,
    rejectionComment: "",
  });

  const data = getEnrollmentStatusData({
    employeeEnrollment,
    t,
    enrollmentStatusOptions,
  });

  const {
    mutate: onUpdateEmployeeEnrollmentStatus,
    isLoading: employeeEnrollmentUpdateInProgress,
  } = useUpdateEmployeeEnrollmentStatus(false, onlyActiveImplementations);
  const {
    mutate: onUpdateDependentEnrollmentStatus,
    isLoading: dependentEnrollmentUpdateInProgress,
  } = useUpdateDependentEnrollmentStatus(false, onlyActiveImplementations);

  const toggleConfirmStatusActionOpen = useCallback(() => {
    setConfirmStatusActionState((prev) => {
      return {
        ...prev,
        isOpen: !prev.isOpen,
      };
    });
  }, []);

  const updateEnrollmentStatus = ({
    isDependent,
    approve,
    rejectionComment,
  }) => {
    if (isDependent) {
      onUpdateDependentEnrollmentStatus(
        {
          countryCode: packageData?.countryCode || 0,
          clientInternalNumberGos: packageData?.clientInternalNumberGos || 0,
          employeeEmail: packageUser?.employeeLoginEmailAddress || "",
          employeeEnrollmentTag: employeeEnrollment?.employeeEnrollmentTag || 0,
          benefitImplementationTag: packageData?.benefitImplementationTag || 0,
          benefitPackageTag: packageData?.benefitPackageTag || 0,
          approve,
          employeeDependentTag: packageUser?.employeeDependentTag || 0,
          payload: {
            rowVersion: employeeEnrollment?.rowVersion || "",
            ...(approve
              ? { dateOfApproval: formatDateFieldForPayload(dateOfApproval) }
              : { rejectionComment }),
          },
        },
        {
          onSuccess: () => {
            toast.success(
              approve
                ? t("hbh.actionApprovedSuccessfully.label")
                : t("hbh.actionRejectedSuccessfully.label")
            );
            toggleConfirmStatusActionOpen();
          },
          onSettled: () => {
            toggleConfirmStatusActionOpen();
          },
        }
      );
      return;
    }
    onUpdateEmployeeEnrollmentStatus(
      {
        countryCode: packageData?.countryCode || 0,
        clientInternalNumberGos: packageData?.clientInternalNumberGos || 0,
        employeeEmail: packageUser?.employeeLoginEmailAddress || "",
        employeeEnrollmentTag: employeeEnrollment?.employeeEnrollmentTag || 0,
        benefitImplementationTag: packageData?.benefitImplementationTag || 0,
        benefitPackageTag: packageData?.benefitPackageTag || 0,

        approve,
        payload: {
          rowVersion: employeeEnrollment?.rowVersion || "",
          ...(approve
            ? { dateOfApproval: formatDateFieldForPayload(dateOfApproval) }
            : { rejectionComment }),
        },
      },
      {
        onSuccess: () => {
          toast.success(
            approve
              ? t("hbh.actionApprovedSuccessfully.label")
              : t("hbh.actionRejectedSuccessfully.label")
          );

          toggleConfirmStatusActionOpen();
        },
        onSettled: () => {
          toggleConfirmStatusActionOpen();
        },
      }
    );
  };

  useEffect(() => {
    setDateOfApproval(employeeEnrollment?.dateOfEnrollment || "");
  }, [confirmStatusActionState.isOpen]);

  if (!data.hasApproveActions && !data.hasRejectActions) return null;

  const approveLabel = data?.approveLabel;
  const rejectLabel = data?.rejectLabel;

  return (
    <>
      <Container>
        {(employeeEnrollmentUpdateInProgress ||
          dependentEnrollmentUpdateInProgress) && <AppSpinner />}
        <ButtonWithTooltip
          tooltipContent={approveLabel}
          onClick={() => {
            setConfirmStatusActionState({
              isOpen: true,
              approve: true,
              rejectionComment,
            });
          }}
          icon={
            <DocumentCheckmarkRegular
              style={{
                color: theme.palette.greenDark,
              }}
            />
          }
          size="small"
        />
        <ButtonWithTooltip
          tooltipContent={rejectLabel}
          onClick={() => {
            setConfirmStatusActionState({
              isOpen: true,
              approve: false,
              rejectionComment,
            });
          }}
          icon={
            <DocumentDismissRegular
              style={{
                color: theme.palette.redDark,
              }}
            />
          }
          size="small"
        />
      </Container>
      {confirmStatusActionState.isOpen && (
        <ConfirmationDialog
          content={
            confirmStatusActionState?.approve ? (
              <DateOfApprovalWrap>
                <DatePickerField
                  label={t("hbh.dateOfApproval.label")}
                  name={""}
                  value={dateOfApproval ? new Date(dateOfApproval) : null}
                  setFieldValue={(_, value) => {
                    setDateOfApproval(value?.toISOString() || "");
                  }}
                />
              </DateOfApprovalWrap>
            ) : (
              <TextareaField
                label={t("hbh.rejectionComment.label")}
                name="rejectionComment"
                value={rejectionComment}
                setFieldValue={(_, value) => {
                  setRejectionComment(value);
                }}
                error={""}
                rows={3}
                resize="vertical"
                required
              />
            )
          }
          isDisabled={
            employeeEnrollmentUpdateInProgress ||
            dependentEnrollmentUpdateInProgress ||
            (!confirmStatusActionState.approve && !rejectionComment)
          }
          isOpen={confirmStatusActionState.isOpen}
          toggleOpen={toggleConfirmStatusActionOpen}
          onConfirm={() => {
            updateEnrollmentStatus({
              isDependent: packageUser?.isDependent,
              approve: confirmStatusActionState.approve,
              rejectionComment: confirmStatusActionState.approve
                ? ""
                : rejectionComment,
            });
          }}
          title={`${packageData?.name}: ${
            confirmStatusActionState.approve ? approveLabel : rejectLabel
          }`}
          confirmLabel={
            confirmStatusActionState.approve ? approveLabel : rejectLabel
          }
          description={""}
        />
      )}
    </>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  gap: 5px;
`;

const DateOfApprovalWrap = styled.div`
  display: flex;
  width: 300px;
  margin: 20px 0;
`;
