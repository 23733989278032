import useNotificationHandler from "hooks/useNotificationHandler";
import { QUERY_KEYS } from "library/shared";
import { IEmployee } from "library/types";
import { UseQueryResult, useQuery, useQueryClient } from "react-query";
import api from "service/api";

type Params = {
  countryCode: number;
  clientInternalNumberGOS: number;
  employeeEmail: string;
  enabled?: boolean;
};

const getEmployee = async ({
  countryCode,
  clientInternalNumberGOS,
  employeeEmail,
}: Params) => {
  const response = await api.get(
    `/employee/${countryCode}/${clientInternalNumberGOS}/${employeeEmail}`
  );
  return response.data;
};

export function useGetEmployeeDetails({
  countryCode,
  clientInternalNumberGOS,
  employeeEmail,
  enabled = true,
}: Params): UseQueryResult<IEmployee> {
  const { handleAddAxiosErrorNotification } = useNotificationHandler();

  return useQuery<IEmployee, Error>(
    [
      QUERY_KEYS.employeeDetails,
      countryCode.toString(),
      clientInternalNumberGOS.toString(),
      employeeEmail,
    ],
    () => getEmployee({ countryCode, clientInternalNumberGOS, employeeEmail }),
    {
      // cacheTime: 0,
      // staleTime: 0,
      refetchOnMount: true,

      enabled: enabled,
      onError: (error: any) => {
        // errorHandler({
        //   label: "Error",
        //   type: notificationTypes.error,
        //   value: "Error while loading offer item data",
        //   errors: error?.message,
        //   fieldName: "",
        // });
        handleAddAxiosErrorNotification(error);
      },
    }
  );
}

export const useInvalidateEmployeeDetails = () => {
  const queryClient = useQueryClient();

  return () =>
    queryClient.invalidateQueries({
      queryKey: QUERY_KEYS.employeeDetails,
    });
};
