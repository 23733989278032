import { CustomPagination } from "components/CustomPagination/CustomPagination";
import { MainTable } from "components/MainTable/MainTable";
import { Flex, PaginationWrap } from "components/styled";
import { usePaginationConfig } from "hooks/usePaginationConfig";
import { useEffect, useState } from "react";
import styled from "styled-components";

type Props = {
  defaultItems: any[];
  defaultColumnSizingOptions: any;
  columns: any[];
  withPagination?: boolean;
  columnNames: {
    [x: string]: string;
  };
  selectionMode?: "single" | "multiselect";
  tableSize?: "small" | "extra-small" | "medium";
  tableDataAction?: (data: any) => void;
  SubrowComponent?: React.FC<{
    item: {
      updateState: (data: any) => void;
      selectionMode: "single" | "multiselect";
      selected?: boolean;
      [x: string]: any;
    };
    showDeleteOption?: boolean;
    showManagePackageEligibilityOption?: boolean;
  }>;
  onRowClick?: (row) => void;
  stickyColumnsLeft?: number;
  stickyColumnsRight?: number;
  stickyRowsTop?: number;
  stickyRowsBottom?: number;
  showDeleteOption?: boolean;
  showManagePackageEligibilityOption?: boolean;
};

export const EditableTable = ({
  defaultItems,
  defaultColumnSizingOptions,
  columns,
  tableDataAction,
  columnNames,
  onRowClick,
  SubrowComponent,
  withPagination = defaultItems.length > 99,
  selectionMode,
  tableSize = "small",
  stickyColumnsLeft = 3,
  stickyColumnsRight = 1,
  stickyRowsTop = 1,
  stickyRowsBottom = 0,
  showDeleteOption = true,
  showManagePackageEligibilityOption = true,
}: Props) => {
  const [items, setItems] = useState<any>([]);

  const { currentPage, totalPages, from, to, handlePageChange } =
    usePaginationConfig({ numberOfItems: defaultItems.length });

  useEffect(() => {
    setItems(defaultItems);
  }, [defaultItems]);
  return (
    <>
      <TableWrap
        stickyColumnsLeft={stickyColumnsLeft}
        stickyColumnsRight={stickyColumnsRight}
        stickyRowsTop={stickyRowsTop}
        stickyRowsBottom={stickyRowsBottom}
      >
        <MainTable
          tableDataAction={tableDataAction}
          paginationState={withPagination ? { from, to } : null}
          SubrowComponent={SubrowComponent}
          className="main-table"
          size={tableSize}
          columnSizingOptionsState={defaultColumnSizingOptions}
          columnNames={columnNames}
          columnsDef={columns}
          items={items}
          onRowClick={onRowClick}
          selectionMode={selectionMode}
          withPagination={withPagination}
          showDeleteOption={showDeleteOption}
          showManagePackageEligibilityOption={
            showManagePackageEligibilityOption
          }
        />
      </TableWrap>
      {withPagination && (
        <PaginationWrap>
          <CustomPagination
            page={currentPage}
            totalPages={totalPages}
            onChange={handlePageChange}
          />
        </PaginationWrap>
      )}
    </>
  );
};

const TableWrap = styled.div<{
  stickyColumnsLeft: number;
  stickyColumnsRight: number;
  stickyRowsTop: number;
  stickyRowsBottom: number;
}>`
  overflow-x: auto;
  scrollbar-width: thin;
  display: flex;
  flex-direction: column;
  height: auto;
  max-height: 70vh;
  display: block;
  overflow-y: auto;

  table {
    border-collapse: separate;
  }

  table {
    tr,
    th {
      padding: 5px 10px;
    }

    td,
    th {
      border: none;
    }

    ${({ stickyColumnsLeft }) =>
      Array.from({ length: stickyColumnsLeft }).map(
        (_, index) => `
        td:nth-of-type(${index + 1}),
        th:nth-of-type(${index + 1}) {
          position: sticky;
          left: ${index * 100}px;
          z-index: 1;
          background: inherit;
          box-shadow: 16px 0px 32px 0px rgba(5, 54, 69, 0.06);
        }
      `
      )}

    ${({ stickyColumnsRight }) =>
      Array.from({ length: stickyColumnsRight }).map(
        (_, index) => `
        td:nth-last-of-type(${index + 1}),
        th:nth-last-of-type(${index + 1}) {
          position: sticky;
          right: ${index * 100}px;
          z-index: 1;
          background: inherit;
          box-shadow: -16px 0px 32px 0px rgba(5, 54, 69, 0.06);
        }
      `
      )}

    ${({ stickyRowsTop }) =>
      Array.from({ length: stickyRowsTop }).map(
        (_, index) => `
        tr:nth-of-type(${index + 1}),
        th:nth-of-type(${index + 1}) {
          position: sticky;
          top: ${index * 38}px;
          z-index: 2;
          background: inherit;
        }
      `
      )}

    ${({ stickyRowsBottom }) =>
      Array.from({ length: stickyRowsBottom }).map(
        (_, index) => `
        tr:nth-last-of-type(${index + 1}),
        th:nth-last-of-type(${index + 1}) {
          position: sticky;
          bottom: ${index * 38}px;
          z-index: 2;
          background: inherit;
        }
      `
      )}

    .fui-TableHeader {
      position: sticky;
      top: 0;
      z-index: 3;
      th {
        background: ${({ theme }) => theme.palette.white} !important;
      }
    }

    .fui-TableBody {
      .fui-TableRow {
        height: 38px;
        min-height: 38px;
        max-height: 38px;
      }

      .fui-TableRow:nth-of-type(odd) {
        background: ${({ theme }) =>
          theme.isDark
            ? theme.semanticColors.bodyBackground
            : theme.palette.themeLighterAlt};
      }
      .fui-TableRow:nth-of-type(even) {
        background: ${({ theme }) => theme.palette.white};
      }
      .fui-TableRow:hover {
        background: ${({ theme }) => theme.palette.themeLighter};
        cursor: pointer;
      }
    }
  }
`;
