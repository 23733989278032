import { useSelectedViewTab } from "hooks/useSelectedViewTab";
import { columnNames } from "library/constants";
import { EBenefitTab, IBenefitCategory } from "library/types";
import { useEffect } from "react";
import { useInvalidateBenefitCategories } from "state/queries/useGetBenefitCategories";
import styled from "styled-components";
import { EditableTable } from "../EditableTable/EditableTable";
import { BenefitCardList } from "./BenefitCardList/BenefitCardList";
import { usePrevious } from "hooks/usePrevious";

type Props = {
  benefitCategories: IBenefitCategory[];
  benefitTableItems: any[];
  defaultColumnSizingOptions: any;
  columns: any[];
  columnNames: {
    [x: string]: string;
  };
  onRowClick?: (row) => void;
  countryCode: number;
  currencyCode?: number;
  clientInternalNumberGOS?: number;
  selectionMode?: "single" | "multiselect";
  hasImplementationActions?: boolean;
};

export function ManageBenefitCategories({
  benefitCategories,
  benefitTableItems,
  defaultColumnSizingOptions,
  columns,
  selectionMode,
  countryCode,
  currencyCode,
  clientInternalNumberGOS = 0,
  onRowClick,
  hasImplementationActions,
}: Props) {
  const selectedTab = useSelectedViewTab();
  const invalidateBenefitCategories = useInvalidateBenefitCategories();

  const prevSelectedTab = usePrevious(selectedTab);

  useEffect(() => {
    if (!prevSelectedTab) return; // on initial render, we don't want to invalidate data
    if (selectedTab === EBenefitTab.grid) {
      invalidateBenefitCategories();
    }
  }, [selectedTab]); // we need to invalidate data when we move from list to grid view (if user change something in table, it should be reflected in grid view) and vice versa

  return (
    <div>
      <TabContent>
        {selectedTab === EBenefitTab.list && (
          <EditableTable
            defaultColumnSizingOptions={defaultColumnSizingOptions}
            defaultItems={benefitTableItems}
            columns={columns}
            columnNames={columnNames}
            selectionMode={selectionMode}
            onRowClick={onRowClick}
          />
        )}
        {selectedTab === EBenefitTab.grid && (
          <BenefitCardList
            currencyCode={currencyCode}
            countryCode={countryCode}
            clientInternalNumberGOS={clientInternalNumberGOS}
            benefitCategories={benefitCategories}
            hasImplementationActions={hasImplementationActions}
          />
        )}
      </TabContent>
    </div>
  );
}

const TabContent = styled.div`
  margin-top: 20px;
`;
