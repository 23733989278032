import useNotificationHandler from "hooks/useNotificationHandler";
import { MUTATION_KEYS, QUERY_KEYS } from "library/shared";
import {
  IEmployeeDependent,
  IEmployeeEnrollment,
  IEmployeeView,
} from "library/types";
import { useMutation, useQueryClient } from "react-query";
import api from "service/api";
import { useInvalidateCompanyEmployeeViews } from "./useGetCompanyEmployeeViews";
import { useInvalidateEmployeeDetails } from "./useGetEmployeeDetails";
import { useInvalidateNeedsAttentionData } from "./useGetNeedsAttentionData";

type Params = {
  countryCode?: number;
  clientInternalNumberGOS?: number;
  payload: IEmployeeEnrollment;
  benefitImplementationTag?: number;
  benefitPackageTag?: number;
  employeeDependentTag?: number;
  employeeLoginEmailAddress?: string;
  employeeEnrollmentTag?: number;
};

const updateEmployeeDependentEnrollment = async ({
  countryCode,
  clientInternalNumberGOS,
  benefitImplementationTag,
  benefitPackageTag,
  employeeDependentTag,
  employeeLoginEmailAddress,
  employeeEnrollmentTag,
  payload,
}: Params) => {
  const response = await api.patch(
    `/employeeenrollment/dependent/${countryCode}/${clientInternalNumberGOS}/${benefitImplementationTag}/${benefitPackageTag}/${employeeLoginEmailAddress}/${employeeDependentTag}/${employeeEnrollmentTag}`,
    payload
  );
  return response.data as IEmployeeDependent;
};

export function useUpdateEmployeeDependentEnrollment(
  refetch = true,
  onlyActiveImplementations
) {
  const queryClient = useQueryClient();
  const invalidateCompanyEmployees = useInvalidateCompanyEmployeeViews();
  const invalidateEmployeeDetails = useInvalidateEmployeeDetails();
  const { handleAddAxiosErrorNotification } = useNotificationHandler();
  const invalidateNeedsAttentionData = useInvalidateNeedsAttentionData();

  return useMutation(updateEmployeeDependentEnrollment, {
    mutationKey: MUTATION_KEYS.updateEmployeeDependentEnrollment,
    onSuccess: (dependentEligibility: any) => {
      invalidateNeedsAttentionData();
      if (refetch) {
        invalidateCompanyEmployees();
        invalidateEmployeeDetails();

        queryClient.invalidateQueries({
          queryKey: QUERY_KEYS.companyAction,
        });
      } else {
        const previousEmployeeData = queryClient.getQueryData<IEmployeeView[]>([
          QUERY_KEYS.companyEmployeeViews,
          dependentEligibility.countryCode.toString(),
          dependentEligibility.clientInternalNumberGos.toString(),
          onlyActiveImplementations,
        ]);

        const updatedEmployeeData = previousEmployeeData?.map(
          (employee: IEmployeeView) => {
            if (
              employee.employeeDependentTag ===
              dependentEligibility.employeeDependentTag
            ) {
              return {
                ...employee,
                expanded: true,
                selected: true,
                enrollments: [
                  ...(employee.enrollments || []).map((enrollment) => {
                    if (
                      enrollment.employeeEnrollmentTag ===
                      dependentEligibility.employeeEnrollmentTag
                    ) {
                      return dependentEligibility;
                    } else {
                      return enrollment;
                    }
                  }),
                ],
              };
            }
            return employee;
          }
        );
        queryClient.setQueryData(
          [
            QUERY_KEYS.companyEmployeeViews,
            dependentEligibility.countryCode.toString(),
            dependentEligibility.clientInternalNumberGos.toString(),
            onlyActiveImplementations,
          ],
          updatedEmployeeData
        );
      }
    },
    onError: (error) => {
      // handle error
      handleAddAxiosErrorNotification(error);
    },
  });
}
