import useNotificationHandler from "hooks/useNotificationHandler";
import { QUERY_KEYS } from "library/shared";
import { IClient, IGrecoClient } from "library/types";
import { UseQueryResult, useQuery, useQueryClient } from "react-query";
import api from "service/api";

type Params = {
  countryCode: number | string | null;
  letters?: string;
  enabled?: boolean;
};

export const getClientsByCountry = async ({ countryCode }: Params) => {
  if (countryCode === null) return;

  const response = await api.get(`/client/${countryCode}`);

  return response.data.map((client) => {
    return {
      ...client,
      text: client?.clientName || "",
      value: client?.clientInternalNumberGos || "",
    };
  });
};

export function useGetClientsByCountry({
  countryCode,
  enabled = true,
}: Params): UseQueryResult<IClient[]> {
  const { handleAddAxiosErrorNotification } = useNotificationHandler();

  return useQuery<IClient[], Error>(
    [QUERY_KEYS.clientsByCountry, countryCode],
    () => getClientsByCountry({ countryCode }),
    {
      // staleTime: 0,
      refetchOnMount: true,
      enabled: enabled,
      onError: (error: any) => {
        handleAddAxiosErrorNotification(error);
      },
    }
  );
}

export const useInvalidateClientsByCountry = () => {
  const queryClient = useQueryClient();
  return () =>
    queryClient.invalidateQueries({ queryKey: QUERY_KEYS.clientsByCountry });
};
