import useNotificationHandler from "hooks/useNotificationHandler";
import { MUTATION_KEYS, QUERY_KEYS } from "library/shared";
import { IEmployee, IEmployeeDependent, IEmployeeView } from "library/types";
import { useMutation, useQueryClient } from "react-query";
import api from "service/api";
import { useInvalidateCompanyEmployeeViews } from "./useGetCompanyEmployeeViews";
import { useInvalidateEmployeeDetails } from "./useGetEmployeeDetails";
import { useInvalidateNeedsAttentionData } from "./useGetNeedsAttentionData";

type Params = {
  countryCode: number | string;
  clientInternalNumberGOS: number | string;
  payload: IEmployeeDependent;
  employeeLoginEmailAddress: string;
};

const createEmployeeDependent = async ({
  countryCode,
  clientInternalNumberGOS,
  employeeLoginEmailAddress,
  payload,
}: Params) => {
  const response = await api.post(
    `/employee/dependent/${countryCode}/${clientInternalNumberGOS}/${employeeLoginEmailAddress}`,
    payload
  );
  return response.data as IEmployeeDependent;
};

export function useCreateEmployeeDependent(
  refetch = true,
  onlyActiveImplementations = true
) {
  const queryClient = useQueryClient();
  const invalidateCompanyEmployees = useInvalidateCompanyEmployeeViews();
  const invalidateEmployeeDetails = useInvalidateEmployeeDetails();
  const { handleAddAxiosErrorNotification } = useNotificationHandler();

  const invalidateNeedsAttentionData = useInvalidateNeedsAttentionData();

  return useMutation(createEmployeeDependent, {
    mutationKey: MUTATION_KEYS.createEmployeeDependent,
    onSuccess: (newDependent) => {
      invalidateNeedsAttentionData();
      if (refetch) {
        invalidateCompanyEmployees();
        invalidateEmployeeDetails();
      } else {
        // This is the new dependent that was created, update newDependent with enrollments: [] and isDependent:true
        const formattedDependent = {
          ...newDependent,
          enrollments: [],
          isDependent: true,
        };

        const previousEmployeeViewData = queryClient.getQueryData<
          IEmployeeView[]
        >([
          QUERY_KEYS.companyEmployeeViews,
          newDependent?.countryCode?.toString(),
          newDependent?.clientInternalNumberGos?.toString(),
          onlyActiveImplementations,
        ]);

        const previousEmployeeData = queryClient.getQueryData<IEmployee>([
          QUERY_KEYS.employeeDetails,
          newDependent?.countryCode?.toString(),
          newDependent?.clientInternalNumberGos?.toString(),
          newDependent?.employeeLoginEmailAddress,
        ]);

        const updatedEmployeeData = {
          ...previousEmployeeData,
          dependents: [
            ...(previousEmployeeData?.dependents || []),
            newDependent,
          ],
        };

        const updatedEmployeeViewData = [
          ...(previousEmployeeViewData || []),
          formattedDependent,
        ];

        queryClient.setQueryData(
          [
            QUERY_KEYS.employeeDetails,
            newDependent?.countryCode?.toString(),
            newDependent?.clientInternalNumberGos?.toString(),
            newDependent?.employeeLoginEmailAddress,
          ],
          updatedEmployeeData
        );
        queryClient.setQueryData(
          [
            QUERY_KEYS.companyEmployeeViews,
            newDependent?.countryCode?.toString(),
            newDependent?.clientInternalNumberGos?.toString(),
            onlyActiveImplementations,
          ],
          updatedEmployeeViewData
        );
      }
    },
    onError: (error) => {
      // handle error
      handleAddAxiosErrorNotification(error);
    },
  });
}
