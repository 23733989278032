import { PageSkeleton } from "components/Skeletons/PageSkeleton";
import { PageContent } from "components/styled";
import { useTaxonomyOptions } from "hooks/useTaxonomyOptions";
import { useAtom, useAtomValue } from "jotai";
import { ETaxonomy, IBenefitPackageView } from "library/types";
import { EmployeeView } from "pages/HrPage/components/ManagePageView/EmployeesView/EmployeeView";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetBenefitImplementation } from "state/queries/useGetBenefitImplementation";
import { useGetBenefitImplementationPackages } from "state/queries/useGetBenefitImplementationPackages";
import { useGetBenefitImplementations } from "state/queries/useGetBenefitImplementations";

import useEmployeeViewData from "hooks/useEmployeeViewData";
import { selectedHrTabState } from "store";
import {
  onlyActiveImplementationsState,
  searchEmployeeFilterValueState,
  selectedCompanyState,
  selectedCountryState,
} from "store/UIHrPage";
import { HrPackageEmployeesSubheader } from "./HrPackageEmployeesSubheader/HrPackageEmployeesSubheader";
import { getSingleClient } from "state/queries/useGetSingleClient";

export function HrPackageEmployeesPage() {
  const params = useParams();
  const implementationId = params.implementationId;
  const categoryId = params.categoryId;
  const packageId = params.packageId;

  const countryCode = params.countryCode;
  const clientInternalNumberGOS = params.clientInternalNumberGOS;

  const selectedTab = useAtomValue(selectedHrTabState);
  const countryOptions = useTaxonomyOptions(ETaxonomy.Country);

  const [selectedCompany, setSelectedCompany] = useAtom(selectedCompanyState);
  const [selectedCountry, setSelectedCountry] = useAtom(selectedCountryState);

  const [searchEmployeeFilterValue, setSearchEmployeeFilterValue] = useAtom(
    searchEmployeeFilterValueState
  );

  const handleSearchEmployeeFilterValue = useCallback(
    (value) => {
      setSearchEmployeeFilterValue(value);
    },
    [setSearchEmployeeFilterValue]
  );

  const [onlyActiveImplementations, setOnlyActiveImplementations] = useAtom(
    onlyActiveImplementationsState
  );

  const { companyEmployees, transformedCompanyPackages } = useEmployeeViewData({
    countryCode,
    clientInternalNumberGOS,
    enabled: countryCode !== undefined && clientInternalNumberGOS !== undefined,
    onlyActiveImplementations,
    searchEmployeeFilterValue,
    selectedTab,
  });

  const packageData = transformedCompanyPackages[packageId || ""];
  const [onlyEnrolledEmployees, setOnlyEnrolledEmployees] = useState(true);

  const getAndSetSelectedCompanyAndCountry = async () => {
    const company = await getSingleClient({
      countryCode: Number(countryCode),
      internalNumber: Number(clientInternalNumberGOS),
    });

    const country = countryOptions?.find(
      (country) => country?.value === Number(countryCode)
    );

    setSelectedCountry(country);
    setSelectedCompany(company);
  };

  useEffect(() => {
    if (countryCode && clientInternalNumberGOS) {
      getAndSetSelectedCompanyAndCountry();
    }
  }, [countryCode, clientInternalNumberGOS]);

  useGetBenefitImplementations({
    countryCode: Number(countryCode),
    clientInternalNumberGOS: Number(clientInternalNumberGOS),
    benefitCategoryTag: Number(categoryId),
    onlyActive: onlyActiveImplementations,
  });

  const filteredCompanyEmployees = ({
    companyEmployees,
    onlyEnrolledEmployees,
  }) => {
    if (onlyEnrolledEmployees) {
      let retVal = [...companyEmployees];
      // remove dependent enrollments that are not enrolled to the package
      retVal = retVal
        .map((employee) => {
          return {
            ...employee,
            dependentList: employee.dependentList?.map((dependent) => {
              return {
                ...dependent,
                enrollments: dependent.enrollments?.filter(
                  (enrollment) =>
                    enrollment.benefitPackageTag === Number(packageId)
                ),
              };
            }),
          };
        })
        // remove dependents that are not enrolled to the package
        .map((employee) => {
          return {
            ...employee,
            dependentList: employee.dependentList?.filter((dependent) => {
              return dependent.enrollments?.length;
            }),
          };
        });
      // remove enrollments that are not enrolled to the package
      retVal = retVal.map((employee) => {
        return {
          ...employee,
          enrollments: employee.enrollments?.filter(
            (enrollment) => enrollment.benefitPackageTag === Number(packageId)
          ),
        };
      });
      // remove employees that are not enrolled to the package and have no dependents
      retVal = retVal.filter((employee) => {
        return employee.enrollments?.length || employee.dependentList?.length;
      });
      return retVal;
    } else {
      return companyEmployees?.map((employee) => {
        return {
          ...employee,
          enrollments: employee.enrollments?.filter(
            (enrollment) => enrollment.benefitPackageTag === Number(packageId)
          ),
          dependentList: employee.dependentList?.map((dependent) => {
            return {
              ...dependent,
              enrollments: dependent.enrollments?.filter(
                (enrollment) =>
                  enrollment.benefitPackageTag === Number(packageId)
              ),
            };
          }),
        };
      });
    }
  };

  const {
    data: benefitImplementation,
    isLoading: benefitImplementationLoading,
  } = useGetBenefitImplementation({
    benefitCategoryTag: Number(categoryId),
    countryCode: Number(countryCode),
    benefitImplementationTag: Number(implementationId),
    clientInternalNumberGOS: Number(clientInternalNumberGOS),
  });

  const { data: packageList, isLoading: packagesLoading } =
    useGetBenefitImplementationPackages({
      countryCode: Number(countryCode),

      clientInternalNumberGOS: Number(clientInternalNumberGOS),
      benefitCategoryTag: Number(categoryId),
      benefitImplementationTag: Number(implementationId),
    });

  const isLoading = benefitImplementationLoading || packagesLoading;

  useEffect(() => {
    setSearchEmployeeFilterValue("");
  }, [selectedCompany]);

  if (isLoading || !benefitImplementation || !packageList)
    return <PageSkeleton />;

  return (
    <>
      <HrPackageEmployeesSubheader
        benefitImplementation={benefitImplementation}
        searchFilterValue={searchEmployeeFilterValue}
        items={
          filteredCompanyEmployees({
            companyEmployees,
            onlyEnrolledEmployees,
          }) || []
        }
        setSearchFilterValue={handleSearchEmployeeFilterValue}
        packageData={packageData as IBenefitPackageView}
      />
      <PageContent>
        <EmployeeView
          onlyActiveImplementations={onlyActiveImplementations}
          setOnlyActiveImplementation={setOnlyActiveImplementations}
          companyEmployees={filteredCompanyEmployees({
            companyEmployees,
            onlyEnrolledEmployees,
          })}
          onlyEnrolledEmployees={onlyEnrolledEmployees}
          setOnlyEnrolledEmployees={setOnlyEnrolledEmployees}
        />
      </PageContent>
    </>
  );
}
