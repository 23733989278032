import useNotificationHandler from "hooks/useNotificationHandler";
import { MUTATION_KEYS, QUERY_KEYS } from "library/shared";
import { IBenefitImplementationSecurity } from "library/types";
import { useMutation, useQueryClient } from "react-query";
import api from "service/api";
import { useInvalidateCompanyEmployeeViews } from "./useGetCompanyEmployeeViews";

type Params = {
  countryCode: number | string;
  clientInternalNumberGOS: number | string;
  securities: IBenefitImplementationSecurity[];
};
const updateBenefitImplementationSecurities = async ({
  countryCode,
  clientInternalNumberGOS,
  securities,
}: Params) => {
  const response = await api.post(
    `/benefitimplementation/security/bulk/${countryCode}/${clientInternalNumberGOS}`,
    [
      ...securities.map((el) => {
        return {
          countryCode: countryCode,
          clientInternalNumberGos: clientInternalNumberGOS,
          hbhUserAssignmentId: el.hbhUserAssignmentId,
          benefitImplementationTag: el.benefitImplementationTag,
          permission: el.permission,
          userId: el.userId,
        };
      }),
    ]
  );

  return response.data as any;
};

export function useUpdateBenefitImplementationSecurities(refetch = true) {
  const queryClient = useQueryClient();
  const invalidateCompanyEmployees = useInvalidateCompanyEmployeeViews();
  const { handleAddAxiosErrorNotification } = useNotificationHandler();

  return useMutation(updateBenefitImplementationSecurities, {
    mutationKey: MUTATION_KEYS.updateBenefitImplementationSecurities,
    onSuccess: () => {
      if (refetch) {
        invalidateCompanyEmployees();

        queryClient.invalidateQueries({
          queryKey: QUERY_KEYS.benefitImplementationSecurities,
        });
        queryClient.invalidateQueries({
          queryKey: QUERY_KEYS.companyAction,
        });
      }
    },
    onError: (error) => {
      // handle error
      handleAddAxiosErrorNotification(error);
    },
  });
}
