import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  makeStyles,
} from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";
import { AppSpinner } from "components/AppSpinner/AppSpinner";
import { PrimaryButton } from "components/PrimaryButton/PrimaryButton";
import { format, formatDate } from "date-fns";
import { Form, Formik } from "formik";
import useGetAllCurrencyOptions from "hooks/useGetAllCurrencyOptions";
import { useTaxonomyOptions } from "hooks/useTaxonomyOptions";
import { devices } from "library/constants";
import {
  ECheckedValue,
  EInsurerConsentType,
  ETaxonomy,
  IBenefitCategory,
  IBenefitImplementation,
} from "library/types";
import { formatSelectFieldForPayload } from "library/utils";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useCreateBenefitImplementation } from "state/queries/useCreateBenefitImplementation";
import { useUpdateBenefitImplementation } from "state/queries/useUpdateBenefitImplementation";
import styled from "styled-components";
import * as yup from "yup";
import { ImplementBenefitFormItems } from "./ImplementBenefitFormItems";

type Props = {
  category?: IBenefitCategory;
  benefitImplementation?: IBenefitImplementation;
  open: boolean;
  setOpen: (open: boolean) => void;
  isAddMode?: boolean;
  refetchCategoryOnAdd?: boolean;
  currencyCode?: number;
};

const useStyles = makeStyles({
  body: {
    display: "flex",
    flexDirection: "column",
    width: "900px",
  },
  content: {
    maxHeight: "90vh",
    scrollbarWidth: "thin",
  },
  actions: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    paddingTop: "10px",
    "@media (max-width: 600px)": {
      flexDirection: "row",
    },
  },
});

export const ImplementBenefitDialog = ({
  category,
  open,
  setOpen,
  benefitImplementation,
  isAddMode,
  refetchCategoryOnAdd,
  currencyCode,
}: Props) => {
  const { t } = useTranslation();

  const title = isAddMode
    ? t("hbh.implementBenefitFor.label", {
        category: category?.name,
      })
    : `${benefitImplementation?.name}: Edit`;

  const styles = useStyles();
  const allCurrencyOptions = useGetAllCurrencyOptions();
  const consentOptions = useTaxonomyOptions(ETaxonomy.ConsentType);
  const consentDeliveryOptions = useTaxonomyOptions(ETaxonomy.DeliveryType);
  const currencyCodeOption = allCurrencyOptions?.find(
    (currency) =>
      currency.value ===
      ((isAddMode ? category : benefitImplementation)?.currencyCode ||
        currencyCode)
  );
  const {
    mutate: updateImplementation,
    isLoading: updateImplementationInProgress,
  } = useUpdateBenefitImplementation();

  const {
    mutate: onCreateBenefitImplementation,
    isLoading: createImplementationInProgress,
  } = useCreateBenefitImplementation(true, refetchCategoryOnAdd);

  const initialValues = useMemo(() => {
    const data = isAddMode ? category : benefitImplementation;

    const initialData = {
      name: data?.name || "",
      ...(isAddMode
        ? {}
        : {
            insurerConsentType: consentOptions?.find(
              (consentType) =>
                consentType.value === benefitImplementation?.insurerConsentType
            ),
            insurerConsentText: benefitImplementation?.insurerConsentText,
            insurerConsentUrl: benefitImplementation?.insurerConsentUrl || "",
            insurerConsentDeliveryType: consentDeliveryOptions?.find(
              (consentType) =>
                consentType.value ===
                benefitImplementation?.insurerConsentDeliveryType
            ),
            insurerForm: benefitImplementation?.insurerConsentDocumentPath
              ? {
                  name: benefitImplementation?.insurerConsentDocumentName,
                  path: benefitImplementation?.insurerConsentDocumentPath,
                  base64String:
                    benefitImplementation?.insurerConsentDocumentContent,
                  type: benefitImplementation?.insurerConsentDocumentMimeType,

                  insurerConsentText: benefitImplementation?.insurerConsentText,
                }
              : null,
          }),

      currencyCode: allCurrencyOptions?.find(
        (currency) => currency.value === (data?.currencyCode || currencyCode)
      ),

      consentsNeeded: data?.consentsNeeded
        ? ECheckedValue.Yes
        : ECheckedValue.No,

      isAutoEnrollment: data?.isAutoEnrollment
        ? ECheckedValue.Yes
        : ECheckedValue.No,
      coverPhoto: data?.backgroundPicturePath
        ? {
            name: data?.backgroundPictureName,
            path: data?.backgroundPicturePath,
            base64String: data?.backgroundPictureContent,
            type: data?.backgroundPictureMimeType,
          }
        : null,
      description: data?.description || "",
      attachments: data?.attachments || [],
      ...(!isAddMode &&
        benefitImplementation && {
          carrierName: benefitImplementation?.carrierName,
          policyOrContractNumber: benefitImplementation?.policyOrContractNumber,
          validFrom: benefitImplementation?.validFrom,
          validTo: benefitImplementation?.validTo,
        }),
    };

    return initialData;
  }, [
    isAddMode,
    category,
    benefitImplementation,
    consentOptions,
    consentDeliveryOptions,
    allCurrencyOptions,
    currencyCode,
  ]);

  const validationSchema = yup.object().shape({
    name: yup.string().min(2).max(128).required(),
    description: yup.string().min(2).max(4000).required(),
    currencyCode: yup.mixed().required(),
    consentsNeeded: yup.mixed().required(),

    isAutoEnrollment: yup.mixed().required(),
    validFrom: yup.mixed().required(),
    carrierName: yup.mixed().required(),
    validTo: yup.mixed().required(),
    policyOrContractNumber: yup.mixed().required(),

    insurerConsentType: yup
      .mixed()
      .when(["consentsNeeded"], ([consentsNeeded], schema) => {
        if (consentsNeeded === ECheckedValue.Yes) {
          return yup.mixed().required();
        }
        return yup.mixed().nullable();
      }),
    insurerConsentUrl: yup
      .mixed()
      .when(["insurerConsentType"], ([insurerConsentType], schema) => {
        if (insurerConsentType?.value === EInsurerConsentType.URL_TO_INSURER) {
          return yup.string().required();
        }
        return yup.string().nullable();
      }),
    insurerConsentDeliveryType: yup
      .mixed()
      .when(["insurerConsentType"], ([insurerConsentType], schema) => {
        if (insurerConsentType?.value === EInsurerConsentType.INSURER_FORM) {
          return yup.mixed().required();
        }
        return yup.mixed().nullable();
      }),
    insurerConsentText: yup
      .mixed()
      .when(["insurerConsentType"], ([insurerConsentType], schema) => {
        if (insurerConsentType?.value === EInsurerConsentType.I_AGREE_TEXT) {
          return yup.string().required();
        }
        return yup.string().nullable();
      }),
  });

  const handleSubmit = (values) => {
    if (isAddMode) {
      onCreateBenefitImplementation(
        {
          countryCode: Number(category?.countryCode),
          clientInternalNumberGOS: Number(category?.clientInternalNumberGos),
          benefitCategoryTag: Number(category?.benefitCategoryTag),
          payload: {
            ...category,
            ...values,
            currencyCode: values?.currencyCode?.value
              ? values?.currencyCode.value
              : values.currencyCode,
            benefitCategoryParentTag: category?.benefitCategoryTag,
            insurerConsentType: formatSelectFieldForPayload({
              values,
              field: "insurerConsentType",
            }),
            insurerConsentDeliveryType: formatSelectFieldForPayload({
              values,
              field: "insurerConsentDeliveryType",
            }),
            insurerConsentDocumentName: values?.insurerForm?.name,
            insurerConsentDocumentContent: values?.insurerForm?.base64String,
            insurerConsentDocumentMimeType: values?.insurerForm?.type,
            ...(!values?.insurerForm?.base64String && {
              insurerConsentDocumentPath: values?.insurerForm?.path,
            }),
            consentsNeeded: values?.consentsNeeded === ECheckedValue.Yes,

            isAutoEnrollment: values?.isAutoEnrollment === ECheckedValue.Yes,
            backgroundPictureName: values?.coverPhoto?.name,
            backgroundPictureContent: values?.coverPhoto?.base64String,
            backgroundPictureMimeType: values?.coverPhoto?.type,
            ...(!values?.coverPhoto?.base64String && {
              backgroundPicturePath: values?.coverPhoto?.path,
            }),
            attachments: values?.attachments || [],
            validFrom: formatDate(values.validFrom, "yyyy-MM-dd"),
            validTo: formatDate(values.validTo, "yyyy-MM-dd"),
          },
        },
        {
          onSuccess() {
            toast.success(t("hbh.implementationCreatedSuccessfully.label"));
            setOpen(false);
          },
          onError() {
            toast.error(t("greco.error"));
          },
        }
      );
      return;
    }
    updateImplementation(
      {
        countryCode: Number(benefitImplementation?.countryCode),
        clientInternalNumberGOS: Number(
          benefitImplementation?.clientInternalNumberGos
        ),
        benefitCategoryTag: Number(benefitImplementation?.benefitCategoryTag),
        benefitImplementationTag: Number(
          benefitImplementation?.benefitImplementationTag
        ),
        payload: {
          ...benefitImplementation,
          ...values,

          backgroundPictureContent: values.coverPhoto?.base64String,
          backgroundPictureName: values.coverPhoto?.name,
          backgroundPicturePath: values.coverPhoto?.path,
          backgroundPictureMimeType: values.coverPhoto?.type,
          ...(values.coverPhoto?.base64String && {
            backgroundPictureContent: values.coverPhoto?.base64String,
            backgroundPictureName: values.coverPhoto?.name,
            backgroundPicturePath: values.coverPhoto?.path,
            backgroundPictureMimeType: values.coverPhoto?.type,
          }),
          insurerConsentDeliveryType: formatSelectFieldForPayload({
            values,
            field: "insurerConsentDeliveryType",
          }),
          insurerConsentType: formatSelectFieldForPayload({
            values,
            field: "insurerConsentType",
          }),
          insurerConsentDocumentName: values?.insurerForm?.name,
          insurerConsentDocumentContent: values?.insurerForm?.base64String,
          insurerConsentDocumentMimeType: values?.insurerForm?.type,
          ...(!values?.insurerForm?.base64String && {
            insurerConsentDocumentPath: values?.insurerForm?.path,
          }),

          consentsNeeded: values?.consentsNeeded === ECheckedValue.Yes,

          isAutoEnrollment: values?.isAutoEnrollment === ECheckedValue.Yes,

          currencyCode: formatSelectFieldForPayload({
            values,
            field: "currencyCode",
          }),
          validFrom: format(values?.validFrom || "", "yyyy-MM-dd"),
          validTo: format(values?.validTo || "", "yyyy-MM-dd"),
        },
      },
      {
        onSuccess() {
          toast.success(t("hbh.implementationUpdatedSuccessfully.label"));
          setOpen(false);
        },
        onError() {
          toast.error(t("greco.error"));
        },
      }
    );
  };

  const isLoading =
    createImplementationInProgress || updateImplementationInProgress;
  return (
    <>
      <Dialog
        modalType="alert"
        open={open}
        onOpenChange={(event, data) => setOpen(data.open)}
      >
        <StyledDialogSurface
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {isLoading ? <AppSpinner /> : null}
          <Formik
            validationSchema={validationSchema}
            initialValues={initialValues}
            enableReinitialize
            onSubmit={(values, actions) => {
              handleSubmit(values);

              actions.setSubmitting(false);
            }}
          >
            {() => (
              <Form>
                <DialogBody className={styles.content}>
                  <DialogTitle
                    action={
                      <DialogTrigger action="close">
                        <Button
                          appearance="subtle"
                          aria-label="close"
                          icon={<Dismiss24Regular />}
                        />
                      </DialogTrigger>
                    }
                  >
                    {title}
                  </DialogTitle>
                  <DialogContent className={styles.content}>
                    <ImplementBenefitFormItems
                      benefitImplementation={benefitImplementation}
                      currencyCodeOption={currencyCodeOption}
                    />
                  </DialogContent>
                </DialogBody>
                <DialogActions className={isAddMode ? "" : styles.actions}>
                  <PrimaryButton
                    disabled={isLoading}
                    style={{
                      paddingLeft: "25px",
                      paddingRight: "25px",
                      marginLeft: "auto",
                    }}
                  >
                    {t("greco.save")}
                  </PrimaryButton>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </StyledDialogSurface>
      </Dialog>
    </>
  );
};

const StyledDialogSurface = styled(DialogSurface)`
  margin-left: 10px;
  margin-right: 10px;
  width: 95%;
  min-width: 95%;
  max-height: 98vh;
  padding: 15px;

  @media only screen and ${devices.md} {
    width: 1200px;
    min-width: 1200px;
    padding: 24px;

    margin-left: auto;
    margin-right: auto;
  }
`;
