import useNotificationHandler from "hooks/useNotificationHandler";
import { MUTATION_KEYS, QUERY_KEYS } from "library/shared";
import { IEmployee, IEmployeeEnrollment, IEmployeeView } from "library/types";
import { useMutation, useQueryClient } from "react-query";
import api from "service/api";
import { useInvalidateCompanyEmployeeViews } from "./useGetCompanyEmployeeViews";
import { useInvalidateNeedsAttentionData } from "./useGetNeedsAttentionData";

type Params = {
  countryCode: number;
  clientInternalNumberGos: number;
  benefitImplementationTag: number;
  benefitPackageTag: number;
  employeeEnrollmentTag: number;
  employeeDependentTag: number;
  employeeEmail: string;
  approve: boolean;
  payload: {
    rowVersion: string;
    rejectionComment?: string;
  };
};

const updateDependentEnrollmentStatus = async ({
  countryCode,
  clientInternalNumberGos,
  employeeEmail,
  employeeEnrollmentTag,
  benefitImplementationTag,
  benefitPackageTag,
  employeeDependentTag,
  approve,
  payload,
}: Params) => {
  const response = await api.patch(
    `/employeeenrollment/dependent/approveorreject/${countryCode}/${clientInternalNumberGos}/${benefitImplementationTag}/${benefitPackageTag}/${employeeEmail}/${employeeDependentTag}/${employeeEnrollmentTag}/${approve}`,
    payload
  );

  return response.data as IEmployeeEnrollment[];
};

export function useUpdateDependentEnrollmentStatus(
  refetch = true,
  onlyActiveImplementations = true
) {
  const queryClient = useQueryClient();
  const { handleAddAxiosErrorNotification } = useNotificationHandler();

  const invalidateCompanyEmployees = useInvalidateCompanyEmployeeViews();
  const invalidateNeedsAttentionData = useInvalidateNeedsAttentionData();

  return useMutation(updateDependentEnrollmentStatus, {
    mutationKey: MUTATION_KEYS.updateDependentEnrollmentStatus,
    onSuccess: (employeeEnrollmentList) => {
      const countryCode = employeeEnrollmentList[0]?.countryCode;
      const clientInternalNumberGos =
        employeeEnrollmentList[0]?.clientInternalNumberGos;
      invalidateNeedsAttentionData();
      if (refetch) {
        invalidateCompanyEmployees();

        queryClient.invalidateQueries({
          queryKey: QUERY_KEYS.companyAction,
        });
      } else {
        const previousEmployeeData = queryClient.getQueryData<IEmployeeView[]>([
          QUERY_KEYS.companyEmployeeViews,
          countryCode?.toString(),
          clientInternalNumberGos?.toString(),
          onlyActiveImplementations,
        ]);
        const updatedEmployeeData = previousEmployeeData?.map(
          (employee: IEmployeeView) => {
            const dependentEnrollment = employeeEnrollmentList.find(
              (item) =>
                item?.employeeLoginEmailAddress ===
                employee.employeeLoginEmailAddress
            );
            if (
              employee.employeeLoginEmailAddress ===
                dependentEnrollment?.employeeLoginEmailAddress &&
              employee.isDependent
            ) {
              return {
                ...employee,
                expanded: true,
                selected: true,
                enrollments: [
                  ...(employee.enrollments || []).map((enrollment) => {
                    if (
                      enrollment.employeeEnrollmentTag ===
                      dependentEnrollment?.employeeEnrollmentTag
                    ) {
                      return dependentEnrollment;
                    } else {
                      return enrollment;
                    }
                  }),
                ],
              };
            }
            return employee;
          }
        );
        queryClient.setQueryData(
          [
            QUERY_KEYS.companyEmployeeViews,
            countryCode?.toString(),
            clientInternalNumberGos?.toString(),
            onlyActiveImplementations,
          ],
          updatedEmployeeData
        );
      }
    },
    onError: (error) => {
      // handle error
      handleAddAxiosErrorNotification(error);
    },
  });
}
