import useNotificationHandler from "hooks/useNotificationHandler";
import { MUTATION_KEYS, QUERY_KEYS } from "library/shared";
import { Attachment, IClientAndBenefitCategories } from "library/types";
import { useMutation, useQueryClient } from "react-query";
import api from "service/api";
import { useInvalidateBenefitCategories } from "./useGetBenefitCategories";
import { useLocation } from "react-router-dom";
import { ROUTES } from "library/constants";
import { useInvalidateSingleClient } from "./useGetSingleClient";

type Params = {
  countryCode: number | string;
  clientInternalNumberGOS: number | string;
  rowVersion: string;
};
const deleteClient = async ({
  countryCode,
  clientInternalNumberGOS,
  rowVersion,
}: Params) => {
  const response = await api.patch(
    `/client/delete/${countryCode}/${clientInternalNumberGOS}`,
    {
      rowVersion,
    }
  );
  return response.data as Attachment;
};

export function useDeleteClient(refetch = true) {
  const queryClient = useQueryClient();

  const { handleAddAxiosErrorNotification } = useNotificationHandler();
  const invalidateBenefitCategories = useInvalidateBenefitCategories();
  const invalidateSingleClient = useInvalidateSingleClient();
  const location = useLocation();
  const isHrPage = location.pathname.includes(ROUTES.HR);
  return useMutation(deleteClient, {
    mutationKey: MUTATION_KEYS.deleteClient,
    onSuccess: (deletedClient) => {
      if (refetch) {
        if (isHrPage) {
          invalidateSingleClient();
        } else {
          invalidateBenefitCategories();
        }
      } else {
        if (isHrPage) {
          queryClient.removeQueries({
            queryKey: [
              QUERY_KEYS.singleClient,
              deletedClient.countryCode?.toString(),
              deletedClient.clientInternalNumberGos?.toString(),
            ],
          });
        } else {
          const prevBenefitCategories = queryClient.getQueryData([
            QUERY_KEYS.benefitCategories,
            deletedClient.countryCode?.toString(),
            deletedClient.clientInternalNumberGos?.toString(),
          ]) as IClientAndBenefitCategories;

          queryClient.setQueriesData(
            [
              QUERY_KEYS.benefitCategories,
              deletedClient.countryCode?.toString(),
              deletedClient.clientInternalNumberGos?.toString(),
            ],
            {
              ...prevBenefitCategories,
              client: null,
            }
          );
        }
      }
    },
    onError: (error) => {
      // handle error
      handleAddAxiosErrorNotification(error);
    },
  });
}
