import { Button, Text } from "@fluentui/react-components";
import { DeleteRegular } from "@fluentui/react-icons";
import { SaveIcon } from "@fluentui/react-icons-mdl2";
import { Flex } from "components/styled";
import { useAppTheme } from "hooks/useAppTheme";
import { IFormattedFile } from "library/types";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useAddImage } from "state/queries/useAddImage";
import styled from "styled-components";

type Props = {
  attachment: IFormattedFile;
  deleteFromList: (attach: IFormattedFile) => void;
  uploadingImages?: boolean;
};

export function AddImageItem({
  attachment,
  deleteFromList,
  uploadingImages,
}: Props) {
  const { t } = useTranslation();
  const theme = useAppTheme();

  const { mutate: onAddImage, isLoading: imageIsUploading } = useAddImage();

  const disabled = imageIsUploading || uploadingImages;

  return (
    <Container>
      <Text>{attachment?.name}</Text>

      <Flex justify="space-between" $align="center">
        <Button
          style={{
            padding: "5px 15px",
          }}
          icon={
            <SaveIcon
              style={{
                color: theme.palette.themePrimary,
                opacity: disabled ? "0.5" : "1",
              }}
            />
          }
          disabled={disabled}
          appearance="outline"
          size="small"
          onClick={async (e) => {
            onAddImage(
              {
                payload: {
                  ImageContent: attachment?.base64String || "",
                  ImageName: attachment?.name || "",
                  ImageMimeType: attachment?.type || "",
                },
              },
              {
                onSuccess: () => {
                  deleteFromList(attachment);

                  toast.success(t("hbh.imageUploadedSuccessfully.label"));
                },
              }
            );
          }}
        />
        <Button
          disabled={disabled}
          onClick={() => {
            deleteFromList(attachment);
          }}
          icon={<DeleteRegular />}
        />
      </Flex>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 5px;
  margin-bottom: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.neutralLight};
`;
