import { ArrowExportRegular } from "@fluentui/react-icons";
import { ManageAttachmentsDialog } from "components/ManageAttachmentsDialog/ManageAttachmentsDialog";
import { IPackageRowItem } from "components/PackagesView/components/BenefitImplementationPackages/shared";
import { ActionButton, Flex } from "components/styled";
import {
  Attachment,
  IFormattedFile,
  IFormattedFileExtended,
} from "library/types";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useCreatePackageAttachment } from "state/queries/useCreatePackageAttachment";
import { useDeletePackageAttachment } from "state/queries/useDeletePackageAttachment";
import { useUpdatePackageAttachment } from "state/queries/useUpdatePackageAttachment";

type Props = {
  item: IPackageRowItem;
  readOnly?: boolean;
};

export function ManagePackageDocuments({ item, readOnly }: Props) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  const onDialogOpenChange = useCallback((state) => {
    setOpen(state);
  }, []);

  const {
    mutateAsync: onCreateAttachment,
    isLoading: createAttachmentInProgress,
  } = useCreatePackageAttachment();

  const { mutate: onUpdateAttachment, isLoading: updateInProgress } =
    useUpdatePackageAttachment();

  const { mutate: onDeleteAttachment, isLoading: deleteInProgress } =
    useDeletePackageAttachment();

  const handleUpdateAttachment = useCallback(
    ({
      attachment,
      newValues,
    }: {
      attachment: Attachment;
      newValues: {
        attachmentName: string;
        attachmentSummary: string;
        attachmentAbstract: string;
        isHiddenForEmployee: boolean;
      };
    }) => {
      onUpdateAttachment(
        {
          attachmentName: attachment.attachmentName || "",
          benefitCategoryTag: Number(item.benefitCategoryTag),
          benefitImplementationTag: Number(item.benefitImplementationTag),
          clientInternalNumberGOS: item.clientInternalNumberGos || 0,
          benefitPackageTag: Number(item.benefitPackageTag),
          countryCode: item.countryCode || 0,
          payload: {
            ...attachment,
            attachmentName: newValues?.attachmentName,
            attachmentSummary: newValues?.attachmentSummary,
            attachmentAbstract: newValues?.attachmentAbstract,
            isHiddenForEmployee: newValues?.isHiddenForEmployee,
          },
        },
        {
          onSuccess: () => {
            toast.success(t("hbh.documentUpdatedSuccessfully.label"));
          },
        }
      );
    },
    []
  );

  const handleCreateAttachment = useCallback(
    (
      newAttach: IFormattedFile | null,
      isHiddenForEmployee?: boolean,
      newAttachList?: IFormattedFileExtended[],
      onSuccessFn?: () => void
    ) => {
      try {
        if (newAttachList) {
          const promiseArray = [] as Promise<any>[];
          newAttachList.forEach((attachment) => {
            promiseArray.push(
              onCreateAttachment({
                benefitCategoryTag: Number(item.benefitCategoryTag),
                benefitImplementationTag: Number(item.benefitImplementationTag),
                clientInternalNumberGOS: item.clientInternalNumberGos || 0,
                benefitPackageTag: Number(item.benefitPackageTag),
                countryCode: item.countryCode || 0,
                payload: {
                  attachmentName: attachment?.name || "",
                  attachmentContent: attachment?.base64String || "",
                  attachmentMimeType: attachment?.type || "",
                  isHiddenForEmployee,
                },
              })
            );
          });

          Promise.all(promiseArray).then(() => {
            onSuccessFn && onSuccessFn();
            toast.success(t("hbh.documentCreatedSuccessfully.label"));
          });
          return;
        }
        onCreateAttachment(
          {
            benefitCategoryTag: Number(item.benefitCategoryTag),
            benefitImplementationTag: Number(item.benefitImplementationTag),
            clientInternalNumberGOS: item.clientInternalNumberGos || 0,
            benefitPackageTag: Number(item.benefitPackageTag),
            countryCode: item.countryCode || 0,
            payload: {
              attachmentName: newAttach?.name || "",
              attachmentContent: newAttach?.base64String || "",
              attachmentMimeType: newAttach?.type || "",
              isHiddenForEmployee,
            },
          },
          {
            onSuccess: () => {
              onSuccessFn && onSuccessFn();
              toast.success(t("hbh.documentDeletedSuccessfully.label"));
            },
          }
        );
      } catch (error) {
        console.log(error, "error in manage package documents");
      }
    },
    []
  );

  const handleDeleteAttachment = useCallback((attach: Attachment) => {
    onDeleteAttachment(
      {
        attachmentName: attach.attachmentName || "",
        benefitCategoryTag: Number(item.benefitCategoryTag),
        benefitImplementationTag: Number(item.benefitImplementationTag),
        benefitPackageTag: Number(item.benefitPackageTag),
        clientInternalNumberGOS: item.clientInternalNumberGos || 0,
        countryCode: item.countryCode || 0,
        rowVersion: attach.rowVersion || "",
      },
      {
        onSuccess: () => {
          toast.success(t("hbh.documentDeletedSuccessfully.label"));
        },
      }
    );
  }, []);

  const attachNumber = item?.attachments?.length;

  const titleLabel = attachNumber
    ? t("hbh.manageDocuments.label")
    : t("hbh.addDocuments.label");

  return (
    <>
      <Flex justify="flex-start" gap={"5px"}>
        <ActionButton
          disabled={readOnly}
          icon={<ArrowExportRegular />}
          noBorder
          appearance="subtle"
          onClick={(e) => {
            e.stopPropagation();
            setOpen(true);
          }}
        >
          {titleLabel}
          {attachNumber
            ? ` (${attachNumber} ${t("hbh.items.label")})`
            : ` (${t("hbh.noData.label")}) `}
        </ActionButton>
      </Flex>
      {open && (
        <ManageAttachmentsDialog
          attachmentActionsState={{
            createAttachmentInProgress,
            onCreateAttachment: handleCreateAttachment,
            updateInProgress,
            onUpdateAttachment: handleUpdateAttachment,
            deleteInProgress,
            onDeleteAttachment: handleDeleteAttachment,
          }}
          item={item}
          open={open}
          setOpen={onDialogOpenChange}
        />
      )}
    </>
  );
}
