import {
  Link,
  Menu,
  MenuButtonProps,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  SplitButton,
  Text,
} from "@fluentui/react-components";
import { Delete16Regular, Edit16Regular } from "@fluentui/react-icons";
import { AvatarWithPreview } from "components/AvatarWithPreview/AvatarWithPreview";
import { ConfirmationDialog } from "components/ConfirmationDialog/ConfirmationDialog";
import { Flex } from "components/styled";
import { useAppTheme } from "hooks/useAppTheme";
import { useRoleType } from "hooks/useRoleType";
import { PrimitiveAtom, useAtom } from "jotai";
import { IClient } from "library/types";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useDeleteClient } from "state/queries/useDeleteClient";
import styled from "styled-components";
import { EditClientDialog } from "./EditClientDialog";
import { useGetSingleClient } from "state/queries/useGetSingleClient";
import { useLocation } from "react-router-dom";
import { ROUTES } from "library/constants";

type Props = {
  companyAtom: PrimitiveAtom<IClient | null>;
};

export function EditClient({ companyAtom }: Props) {
  const theme = useAppTheme();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const location = useLocation();
  const isHrPage = location.pathname.includes(ROUTES.HR);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [selectedCompany, setSelectedCompany] = useAtom(companyAtom);

  const isSuperAdminUser = useRoleType()?.hasSuperAdminRole;

  const { data: companyData } = useGetSingleClient({
    countryCode: selectedCompany?.countryCode || 0,
    internalNumber: selectedCompany?.clientInternalNumberGos || 0,
    enabled: !!selectedCompany && isHrPage && open,
  });

  const company = isHrPage && companyData ? companyData : selectedCompany;

  const toggleDeleteConfirmationState = useCallback(() => {
    setDeleteConfirmationOpen((prev) => !prev);
  }, []);

  const onDialogOpenChange = useCallback((state) => {
    setOpen(state);
  }, []);

  const { mutate: onDeleteClient, isLoading: deleteInProgress } =
    useDeleteClient(isHrPage ? false : true);

  const handleDeleteClient = useCallback(() => {
    onDeleteClient(
      {
        clientInternalNumberGOS: Number(company?.clientInternalNumberGos),
        countryCode: Number(company?.countryCode),
        rowVersion: company?.rowVersion || "",
      },
      {
        onSuccess: () => {
          setSelectedCompany(null);
          toast.success(t("hbh.clientDeletedSuccessfully.label"));
        },
      }
    );
  }, [selectedCompany, companyData]);

  return (
    <>
      <Container>
        <Menu positioning="below-end">
          <MenuTrigger disableButtonEnhancement>
            {(triggerProps: MenuButtonProps) => (
              <SplitButton
                style={{
                  paddingTop: "0 !important",
                  paddingBottom: "0 !important",
                }}
                menuButton={{
                  ...triggerProps,
                }}
                appearance="transparent"
              >
                <Flex
                  style={{
                    color: theme.palette.themePrimary,
                  }}
                  onClick={triggerProps.onClick as any}
                >
                  <Link
                    href={company?.backgroundPicturePath || ""}
                    target="_blank"
                  >
                    <AvatarWithPreview
                      shape="square"
                      name={company?.clientName}
                      imgSrc={company?.backgroundPicturePath || ""}
                    />
                  </Link>
                  <span>{company?.clientName}</span>
                </Flex>
              </SplitButton>
            )}
          </MenuTrigger>

          <MenuPopover>
            <MenuList>
              <MenuItem>
                <Flex
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpen(true);
                  }}
                >
                  <Edit16Regular
                    style={{
                      color: theme.palette.themePrimary,
                    }}
                  />
                  <Text>
                    {t("hbh.edit.label", {
                      client: companyData?.clientName,
                    })}
                  </Text>
                </Flex>
              </MenuItem>
              <MenuItem>
                <Flex
                  onClick={(e) => {
                    if (!isSuperAdminUser) return;
                    e.stopPropagation();
                    setDeleteConfirmationOpen(true);
                  }}
                >
                  <Delete16Regular
                    style={{
                      color: isSuperAdminUser
                        ? theme.palette.redDark
                        : theme.semanticColors.disabledBodyText,
                    }}
                  />
                  <Text
                    style={{
                      color: isSuperAdminUser
                        ? theme.palette.redDark
                        : theme.semanticColors.disabledBodyText,
                    }}
                  >
                    {t("greco.delete", {
                      client: company?.clientName,
                    })}
                  </Text>
                </Flex>
              </MenuItem>
            </MenuList>
          </MenuPopover>
        </Menu>
      </Container>

      {open && company && (
        <EditClientDialog
          client={company}
          setSelectedCompany={setSelectedCompany}
          open={open}
          setOpen={onDialogOpenChange}
        />
      )}
      <ConfirmationDialog
        isDisabled={deleteInProgress}
        isOpen={deleteConfirmationOpen}
        toggleOpen={toggleDeleteConfirmationState}
        onConfirm={handleDeleteClient}
        confirmLabel={t("greco.delete")}
        title={`${t("hbh.deleteClient.label")}: ${company?.clientName} `}
        description={t("hbh.deleteClientConfirm.label")}
      />
    </>
  );
}

const Container = styled.div`
  .fui-Button {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
`;
