import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  IBenefitImplementation,
  IBenefitImplementationSecurity,
  IUserAssignment,
} from "library/types";
import styled from "styled-components";

type Props = {
  user: IUserAssignment;
  benefitImplementation: IBenefitImplementation;
  permission: number;
  onChange: (
    newPermission: number,
    isChanged: boolean,
    updates: IBenefitImplementationSecurity[],
    setUpdates: (updates: IBenefitImplementationSecurity[]) => void
  ) => void;
  updates: IBenefitImplementationSecurity[];
  setUpdates: (updates: IBenefitImplementationSecurity[]) => void;
  isMarked: boolean;
};

export function SecurityCell({
  user,
  benefitImplementation,
  permission,
  onChange,
  updates,
  setUpdates,
  isMarked,
}: Props) {
  const { t } = useTranslation();
  const [colorId, setColorId] = useState(permission);
  const [originalColorId] = useState(permission);
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    console.log(updates);
    const update = updates.find(
      (u) =>
        u.hbhUserAssignmentId === user.hbhUserAssignmentId &&
        u.benefitImplementationTag ===
          benefitImplementation.benefitImplementationTag
    );
    if (update) {
      setColorId(update.permission || 0);
      setIsChanged(update.permission !== originalColorId);
    } else {
      setColorId(permission);
      setIsChanged(false);
    }
  }, [
    updates,
    user.hbhUserAssignmentId,
    benefitImplementation.benefitImplementationTag,
    permission,
    originalColorId,
  ]);

  const colors = [
    { id: 2, label: t("hbh.security.viewEdit.label"), value: "#c7efcd" },
    { id: 1, label: t("hbh.security.view.label"), value: "#ffeb9c" },
    { id: 3, label: t("hbh.security.noAccess.label"), value: "#FCC9D0" },
  ];

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newPermission = Number(event.target.value);
    setColorId(newPermission);
    setIsChanged(newPermission !== originalColorId);
    onChange(
      newPermission,
      newPermission !== originalColorId,
      updates,
      setUpdates
    );
  };

  return (
    <StyledCell
      color={colors.find((color) => color.id === colorId)?.value || "#FFFFFF"}
      isChanged={isChanged}
      isMarked={isMarked}
    >
      <CustomCombobox value={colorId} onChange={handleChange}>
        {colors.map((color) => (
          <option key={color.id} value={color.id}>
            <ColorIcon color={color.value} />
            {color.label}
          </option>
        ))}
      </CustomCombobox>
    </StyledCell>
  );
}

const StyledCell = styled.div<{
  color: string;
  isChanged: boolean;
  isMarked: boolean;
}>`
  background-color: ${({ color, isMarked }) => (isMarked ? "#e0f7fa" : color)};
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  border-radius: 4px;
  transition: background-color 0.3s;
  outline: ${({ isChanged }) => (isChanged ? "2px solid blue" : "none")};
`;

const CustomCombobox = styled.select`
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
  color: #555555;
  font-weight: inherit;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-align: center;

  option {
    background-color: white;
    color: black;
    display: flex;
    align-items: center;
  }

  option:hover {
    background-color: lightgray;
  }

  option:checked {
    outline: 1px solid gray;
  }
`;

const ColorIcon = styled.span<{ color: string }>`
  width: 12px;
  height: 12px;
  background-color: ${({ color }) => color};
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
`;
