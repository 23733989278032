import { Button } from "@fluentui/react-components";
import { DeleteRegular } from "@fluentui/react-icons";
import { SaveIcon } from "@fluentui/react-icons-mdl2";
import { UploadFileList } from "components/Upload/UploadFileList";
import { useAppTheme } from "hooks/useAppTheme";
import {
  Attachment,
  IFormattedFile,
  IFormattedFileExtended,
} from "library/types";
import { genUUID } from "library/utils";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { AddAttachItem } from "./AddAttachItem";

type Props = {
  defaultAttachList: Attachment[];
  attachHiddenForEmployee?: boolean;
  showIsAttachHiddenForEmployeeField: boolean;
  onCreateAttachment: (
    attach: IFormattedFile | null,
    isHiddenForEmployee?: boolean,
    newAttachList?: IFormattedFileExtended[],
    onSuccessFn?: () => void
  ) => void;
  createAttachmentInProgress?: boolean;
};

export function AddAttachments({
  defaultAttachList,
  showIsAttachHiddenForEmployeeField,
  onCreateAttachment,
  createAttachmentInProgress,
}: Props) {
  const theme = useAppTheme();
  const { t } = useTranslation();

  const [newAttachList, setNewAttachList] = useState<IFormattedFileExtended[]>(
    []
  );

  const deleteFromList = useCallback((attachment) => {
    setNewAttachList((prev) => {
      const filteredAttachmentList = prev.filter((attach) => {
        return attach.name !== attachment?.name;
      });
      return filteredAttachmentList;
    });
  }, []);

  const updateAttachList = useCallback((attachment) => {
    setNewAttachList((prev) => {
      return prev.map((attach) => {
        if (attach.name === attachment.name) {
          return attachment;
        }
        return attach;
      });
    });
  }, []);

  return (
    <div>
      <Title>{t("hbh.documentList.label")}</Title>
      <ActionsWrap>
        <UploadFileList
          selectedFileList={newAttachList}
          setFieldValue={(_, fileList) => {
            const filteredDuplicatesFileList = (fileList || []).filter(
              (file) =>
                !defaultAttachList?.some(
                  (defaultFile: Attachment) =>
                    defaultFile.attachmentName === file.name
                )
            );
            setNewAttachList(
              filteredDuplicatesFileList as IFormattedFileExtended[]
            );
          }}
          fieldName=""
          label=""
        />
        {newAttachList.length > 1 && (
          <>
            <Button
              style={{
                minWidth: "fit-content",
              }}
              icon={
                <SaveIcon
                  style={{
                    color: theme.palette.themePrimary,
                    opacity: createAttachmentInProgress ? "0.5" : "1",
                  }}
                />
              }
              disabled={createAttachmentInProgress}
              appearance="outline"
              onClick={async (e) => {
                onCreateAttachment(null, undefined, newAttachList, () => {
                  setNewAttachList([]);
                });
              }}
            >
              {t("hbh.saveAll.label")}
            </Button>
            <Button
              style={{
                minWidth: "fit-content",
              }}
              disabled={createAttachmentInProgress}
              onClick={() => {
                setNewAttachList([]);
              }}
              icon={<DeleteRegular />}
            >
              {t("greco.clear")}
            </Button>
          </>
        )}
      </ActionsWrap>
      <AttachmentList>
        {newAttachList?.map((attachment) => {
          return (
            <AddAttachItem
              key={genUUID()}
              attachment={attachment}
              onCreateAttachment={onCreateAttachment}
              showIsAttachHiddenForEmployeeField={
                showIsAttachHiddenForEmployeeField
              }
              updateAttachList={updateAttachList}
              deleteFromList={deleteFromList}
              createAttachmentInProgress={createAttachmentInProgress}
            />
          );
        })}
      </AttachmentList>
    </div>
  );
}

const Title = styled.div`
  font-weight: 600;
  text-decoration: underline;
  font-size: 16px;
`;
const AttachmentList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  //   border: 1px solid ${({ theme }) => theme.palette.neutralLight};
  max-height: 300px;
  overflow-y: auto;
`;

const ActionsWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 10px;
  gap: 5px;
`;
