import { Skeleton } from "@fluentui/react-components";
import { BenefitCategoryDialog } from "components/BenefitCategoryDialog/BenefitCategoryDialog";
import { ConfirmationDialog } from "components/ConfirmationDialog/ConfirmationDialog";
import { ImplementBenefitDialog } from "components/ImplementBenefitDialog/ImplementBenefitDialog";
import { ManageAttachmentsDialog } from "components/ManageAttachmentsDialog/ManageAttachmentsDialog";
import CardItemSkeleton from "components/Skeletons/CardItemSkeleton";
import { CardListWrap } from "components/styled";
import {
  Attachment,
  IBenefitCategory,
  IFormattedFile,
  IFormattedFileExtended,
  IRowItem,
} from "library/types";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useCreateCategoryAttachment } from "state/queries/useCreateCategoryAttachment";
import { useDeleteBenefitCategory } from "state/queries/useDeleteBenefitCategory";
import { useDeleteBenefitCategoryAttachment } from "state/queries/useDeleteBenefitCategoryAttachment";
import { useUpdateBenefitCategory } from "state/queries/useUpdateBenefitCategory";
import { useUpdateCategoryAttachment } from "state/queries/useUpdateCategoryAttachment";
import { BenefitCardNew } from "./BenefitCardNew";
import { use } from "i18next";
import { useRoleType } from "hooks/useRoleType";
import { genUUID } from "library/utils";

type Props = {
  benefitCategories: IBenefitCategory[];
  hasImplementationActions?: boolean;

  countryCode: number;
  currencyCode?: number;
  clientInternalNumberGOS?: number;
};

export function BenefitCardList({
  benefitCategories,
  countryCode,
  clientInternalNumberGOS = 0,
  hasImplementationActions,
  currencyCode,
}: Props) {
  const [selectedBenefit, setSelectedBenefit] = useState<
    IBenefitCategory | undefined
  >(undefined);
  const { t } = useTranslation();

  const roles = useRoleType();
  const isSuperAdminUser = roles?.hasSuperAdminRole;

  const isReadOnlyUser = roles?.hasReadOnlyRole;

  const [editOpen, setEditOpen] = useState(false);
  const [manageDocumentOpen, setManageDocumentOpen] = useState(false);
  const [addImplementationOpen, setAddImplementationOpen] = useState(false);

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

  const handleAddBenefitDialogState = useCallback((state) => {
    setAddImplementationOpen(state);
  }, []);

  const handleAddImplementationOpen = useCallback((category) => {
    setSelectedBenefit(category);
    setAddImplementationOpen(true);
  }, []);

  const {
    mutate: onDeleteBenefitCategory,
    isLoading: deleteCategoryInProgress,
  } = useDeleteBenefitCategory();

  const { mutate: onUpdateBenefitCategory, isLoading: updateImageInProgress } =
    useUpdateBenefitCategory();

  const {
    mutateAsync: onCreateAttachment,
    isLoading: createAttachmentInProgress,
  } = useCreateCategoryAttachment();

  const { mutate: onUpdateAttachment, isLoading: updateInProgress } =
    useUpdateCategoryAttachment();

  const { mutate: onDeleteAttachment, isLoading: deleteAttchInProgress } =
    useDeleteBenefitCategoryAttachment();

  const handleOpenEditBenefit = useCallback((benefit) => {
    setSelectedBenefit(benefit);
    setEditOpen(true);
  }, []);

  const handleOpenManageDocuments = useCallback((benefit) => {
    setSelectedBenefit(benefit);
    setManageDocumentOpen(true);
  }, []);

  const handleDeleteCategoryOpen = useCallback((category) => {
    setSelectedBenefit(category);
    setDeleteConfirmationOpen(true);
  }, []);

  const toggleDeleteConfirmationState = useCallback(() => {
    setDeleteConfirmationOpen((prev) => !prev);
  }, []);

  const handleCreateAttachment = useCallback(
    (
      newAttach: IFormattedFile | null,
      isHiddenForEmployee?: boolean,
      newAttachList?: IFormattedFileExtended[],
      onSuccessFn?: () => void
    ) => {
      try {
        if (newAttachList) {
          const promiseArray = [] as Promise<any>[];
          newAttachList.forEach((attachment) => {
            promiseArray.push(
              onCreateAttachment({
                benefitCategoryTag: Number(selectedBenefit?.benefitCategoryTag),
                clientInternalNumberGOS:
                  selectedBenefit?.clientInternalNumberGos || 0,
                countryCode: selectedBenefit?.countryCode || 0,
                payload: {
                  attachmentName: attachment?.name || "",
                  attachmentContent: attachment?.base64String || "",
                  attachmentMimeType: attachment?.type || "",
                  isHiddenForEmployee,
                  // rowVersion: item.rowVersion || "",
                },
              })
            );
          });

          Promise.all(promiseArray).then(() => {
            onSuccessFn && onSuccessFn();
            toast.success(t("hbh.documentCreatedSuccessfully.label"));
          });
          return;
        }
        onCreateAttachment(
          {
            benefitCategoryTag: Number(selectedBenefit?.benefitCategoryTag),
            clientInternalNumberGOS:
              selectedBenefit?.clientInternalNumberGos || 0,
            countryCode: selectedBenefit?.countryCode || 0,
            payload: {
              attachmentName: newAttach?.name || "",
              attachmentContent: newAttach?.base64String || "",
              attachmentMimeType: newAttach?.type || "",
              isHiddenForEmployee,
              // rowVersion: item.rowVersion || "",
            },
          },
          {
            onSuccess: () => {
              onSuccessFn && onSuccessFn();

              toast.success(t("hbh.documentCreatedSuccessfully.label"));
            },
          }
        );
      } catch (error) {
        console.log(error, "error in benefit card list");
      }
    },
    [selectedBenefit]
  );

  const handleDeleteBenefitCategory = useCallback(() => {
    if (!selectedBenefit) return;
    onDeleteBenefitCategory(
      {
        benefitCategoryTag: Number(selectedBenefit?.benefitCategoryTag),
        clientInternalNumberGOS: Number(
          selectedBenefit?.clientInternalNumberGos
        ),
        countryCode: Number(selectedBenefit?.countryCode),
        rowVersion: selectedBenefit?.rowVersion || "",
      },
      {
        onSuccess: () => {
          setDeleteConfirmationOpen(false);
          toast.success(t("hbh.benefitCategoryDeletedSuccessfully.label"));
        },
      }
    );
  }, [benefitCategories, selectedBenefit, t]);

  const handleUpdateAttachment = useCallback(
    ({
      attachment,
      newValues,
    }: {
      attachment: Attachment;
      newValues: {
        attachmentName: string;
        attachmentSummary: string;
        attachmentAbstract: string;
        isHiddenForEmployee: boolean;
      };
    }) => {
      onUpdateAttachment(
        {
          attachmentName: attachment.attachmentName || "",
          benefitCategoryTag: Number(selectedBenefit?.benefitCategoryTag),
          clientInternalNumberGOS:
            selectedBenefit?.clientInternalNumberGos || 0,
          countryCode: selectedBenefit?.countryCode || 0,
          payload: {
            ...attachment,
            // rowVersion: item.rowVersion || "",
            attachmentName: newValues?.attachmentName,
            attachmentSummary: newValues?.attachmentSummary,
            attachmentAbstract: newValues?.attachmentAbstract,
            isHiddenForEmployee: newValues?.isHiddenForEmployee,
          },
        },
        {
          onSuccess: () => {
            toast.success(t("hbh.documentUpdatedSuccessfully.label"));
          },
        }
      );
    },
    [selectedBenefit]
  );

  const handleDeleteAttachment = useCallback(
    (attach: Attachment) => {
      onDeleteAttachment(
        {
          attachmentName: attach.attachmentName || "",
          benefitCategoryTag: Number(selectedBenefit?.benefitCategoryTag),
          clientInternalNumberGOS:
            selectedBenefit?.clientInternalNumberGos || 0,
          countryCode: selectedBenefit?.countryCode || 0,
          rowVersion: attach.rowVersion || "",
        },
        {
          onSuccess: () => {
            toast.success(t("hbh.documentDeletedSuccessfully.label"));
          },
        }
      );
    },
    [selectedBenefit]
  );

  const onUpdatePhoto = useCallback(
    (photo, categoryData) => {
      setSelectedBenefit(categoryData);
      onUpdateBenefitCategory(
        {
          countryCode: Number(categoryData.countryCode),
          clientInternalNumberGOS: Number(categoryData.clientInternalNumberGos),
          benefitCategoryTag: categoryData?.benefitCategoryTag,
          payload: {
            ...(categoryData as IBenefitCategory),
            backgroundPictureContent: photo?.base64String,
            backgroundPictureName: photo?.name,
            backgroundPictureMimeType: photo?.type,
            backgroundPicturePath: photo?.path,
          },
        },
        {
          onSuccess() {
            toast.success(t("hbh.benefitCategoryUpdatedSuccessfully.label"));
          },
          onError() {
            toast.error(t("greco.error"));
          },
        }
      );
    },
    [selectedBenefit]
  );

  const onEditDialogOpenStateChange = useCallback((state) => {
    setEditOpen(state);
  }, []);

  const onManageDocumentsOpenStateChange = useCallback((state) => {
    setManageDocumentOpen(state);
  }, []);

  const sortedCategories = benefitCategories.sort(
    (a, b) => Number(a?.benefitCategoryTag) - Number(b.benefitCategoryTag)
  );

  useEffect(() => {
    const updatedCategory = benefitCategories.find(
      (item) => item.benefitCategoryTag === selectedBenefit?.benefitCategoryTag
    );
    if (updatedCategory) {
      setSelectedBenefit(updatedCategory); // update selected benefit when documents are updated
    }
  }, [benefitCategories]);

  return (
    <>
      <CardListWrap>
        {sortedCategories.map((benefit, index) => {
          if (
            benefit.benefitCategoryTag ===
              selectedBenefit?.benefitCategoryTag &&
            (updateImageInProgress || deleteCategoryInProgress)
          ) {
            return (
              <Skeleton aria-label="Loading Content">
                <CardItemSkeleton />
              </Skeleton>
            );
          }
          return (
            <BenefitCardNew
              type="category"
              key={benefit.benefitCategoryTag + "" + index}
              benefitCategory={benefit}
              onEdit={handleOpenEditBenefit}
              hasImplementationActions={hasImplementationActions}
              onManageDocuments={handleOpenManageDocuments}
              onUpdatePhoto={onUpdatePhoto}
              onAddNew={handleAddImplementationOpen}
              onDelete={isSuperAdminUser ? handleDeleteCategoryOpen : undefined}
              previewMode={isReadOnlyUser}
            />
          );
        })}
      </CardListWrap>
      {editOpen && (
        <BenefitCategoryDialog
          clientInternalNumberGOS={clientInternalNumberGOS}
          countryCode={countryCode}
          open={editOpen}
          setOpen={onEditDialogOpenStateChange}
          benefitCategory={selectedBenefit}
        />
      )}
      {manageDocumentOpen && (
        <ManageAttachmentsDialog
          showIsAttachHiddenForEmployeeField={false}
          attachmentActionsState={{
            createAttachmentInProgress,
            onCreateAttachment: handleCreateAttachment,
            updateInProgress,
            onUpdateAttachment: handleUpdateAttachment,
            deleteInProgress: deleteAttchInProgress,
            onDeleteAttachment: handleDeleteAttachment,
          }}
          item={selectedBenefit as IRowItem}
          open={manageDocumentOpen}
          setOpen={onManageDocumentsOpenStateChange}
        />
      )}
      {addImplementationOpen && (
        <ImplementBenefitDialog
          isAddMode
          currencyCode={currencyCode}
          category={selectedBenefit as IBenefitCategory}
          open={addImplementationOpen}
          setOpen={handleAddBenefitDialogState}
          refetchCategoryOnAdd={true}
        />
      )}
      {deleteConfirmationOpen && (
        <ConfirmationDialog
          isDisabled={deleteCategoryInProgress}
          isOpen={deleteConfirmationOpen}
          toggleOpen={toggleDeleteConfirmationState}
          onConfirm={handleDeleteBenefitCategory}
          description={t("hbh.deleteObject.label", {
            object: selectedBenefit?.name,
          })}
          confirmLabel={t("greco.delete")}
          title={`${t("hbh.deleteCategory.label")}: ${selectedBenefit?.name} `}
        />
      )}
    </>
  );
}
