import React, { useEffect } from "react";
import {
  Button,
  Badge,
  Tooltip,
  ToggleButton,
} from "@fluentui/react-components";
import { ServiceBellRegular } from "@fluentui/react-icons";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "library/constants";
import { useAtom, useAtomValue } from "jotai";
import {
  pageVisitedBeforeNeedsAttentionState,
  selectedCompanyState,
} from "store/UIHrPage";
import useNeedsAttentionData from "hooks/useNeedsAttentionData";

type Props = {
  count: number;
};

const NeedsAttentionButton = ({ count }: Props) => {
  const { t } = useTranslation();
  const [pageVisitedBeforeNeedsAttention, setPageVisitedBeforeNeedsAttention] =
    useAtom(pageVisitedBeforeNeedsAttentionState);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (
      location.pathname === ROUTES.NEEDS_ATTENTION &&
      pageVisitedBeforeNeedsAttention === null
    ) {
      setPageVisitedBeforeNeedsAttention(ROUTES.HR);
    }

    if (
      location.pathname !== ROUTES.NEEDS_ATTENTION &&
      pageVisitedBeforeNeedsAttention !== null
    ) {
      setPageVisitedBeforeNeedsAttention(null);
    }
  }, [pageVisitedBeforeNeedsAttention, location]);

  return (
    <Tooltip content={t("hbh.needsAttention.label")} relationship="label">
      <div style={{ position: "relative", display: "inline-block" }}>
        <ToggleButton
          icon={<ServiceBellRegular />}
          appearance="subtle"
          checked={pageVisitedBeforeNeedsAttention !== null}
          onClick={() => {
            if (pageVisitedBeforeNeedsAttention === null) {
              setPageVisitedBeforeNeedsAttention(location.pathname);
              navigate(`${ROUTES.NEEDS_ATTENTION}`);
            } else {
              const prevPage = pageVisitedBeforeNeedsAttention;
              setPageVisitedBeforeNeedsAttention(null);
              navigate(`${prevPage}`);
            }
          }}
        />
        {count > 0 && (
          <Badge
            appearance="filled"
            color="danger"
            size="small"
            style={{
              position: "absolute",
              top: 4,
              right: 4,
              transform: "translate(50%, -50%)",
            }}
          >
            {count}
          </Badge>
        )}
      </div>
    </Tooltip>
  );
};

export default NeedsAttentionButton;
