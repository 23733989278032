import { Skeleton } from "@fluentui/react-components";
import { ManageAttachmentsDialog } from "components/ManageAttachmentsDialog/ManageAttachmentsDialog";
import { BenefitCardNew } from "components/ManageBenefitCategories/BenefitCardList/BenefitCardNew";
import { PackageDialog } from "components/PackageDialog/PackageDialog";
import CardItemSkeleton from "components/Skeletons/CardItemSkeleton";
import { CardListWrap } from "components/styled";
import { useRoleType } from "hooks/useRoleType";
import {
  Attachment,
  IBenefitImplementation,
  IBenefitPackage,
  IFormattedFile,
  IFormattedFileExtended,
} from "library/types";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useCreatePackageAttachment } from "state/queries/useCreatePackageAttachment";
import { useDeletePackageAttachment } from "state/queries/useDeletePackageAttachment";
import { useUpdateBenefitImplementationPackage } from "state/queries/useUpdateBenefitImplementationPackage";
import { useUpdatePackageAttachment } from "state/queries/useUpdatePackageAttachment";
import { IPackageRowItem } from "../BenefitImplementationPackages/shared";
import { useDeleteBenefitImplementationPackage } from "state/queries/useDeleteBenefitImplementationPackage";
import { ConfirmationDialog } from "components/ConfirmationDialog/ConfirmationDialog";

type Props = {
  benefitImplementation: IBenefitImplementation;
  benefitPackages: IBenefitPackage[];
  openBenefitPackageEmployeesImplementation?: boolean;
};

export function PackageCardList({
  benefitImplementation,
  benefitPackages,
  openBenefitPackageEmployeesImplementation,
}: Props) {
  const [selectedPackage, setSelectedPackage] = useState<
    IBenefitPackage | undefined
  >(undefined);
  const { t } = useTranslation();
  const roles = useRoleType();
  const isSuperAdminUser = roles?.hasSuperAdminRole;

  const previewModeCard = roles?.hasReadOnlyRole;

  const [packageDialogOpen, setPackageDialogOpen] = useState(false);

  const [manageDocumentOpen, setManageDocumentOpen] = useState(false);

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

  const {
    mutateAsync: onCreateAttachment,
    isLoading: createAttachmentInProgress,
  } = useCreatePackageAttachment();

  const { mutate: onUpdateAttachment, isLoading: updateInProgress } =
    useUpdatePackageAttachment();

  const { mutate: onDeleteAttachment, isLoading: deleteAttachInProgress } =
    useDeletePackageAttachment();

  const { mutate: onUpdatePackage, isLoading: updatePackageInProgress } =
    useUpdateBenefitImplementationPackage();

  const { mutate: onDeletePackage, isLoading: deletePackageInProgress } =
    useDeleteBenefitImplementationPackage();

  const handleEditPackageDialogState = useCallback((packageItem) => {
    setSelectedPackage(packageItem);
    setPackageDialogOpen(true);
  }, []);

  const handlePackageDialogOpen = useCallback((state) => {
    setPackageDialogOpen(state);
  }, []);

  const handleOpenManageDocuments = useCallback((packageItem) => {
    setSelectedPackage(packageItem);
    setManageDocumentOpen(true);
  }, []);

  const handleDeletePackageOpen = useCallback((packageItem) => {
    setSelectedPackage(packageItem);

    setDeleteConfirmationOpen(true);
  }, []);

  const onManageDocumentsOpenStateChange = useCallback((state) => {
    setManageDocumentOpen(state);
  }, []);

  const toggleDeleteConfirmationState = useCallback(() => {
    setDeleteConfirmationOpen((prev) => !prev);
  }, []);

  const handleDeletePackage = useCallback(() => {
    if (!selectedPackage) return;
    onDeletePackage(
      {
        benefitCategoryTag: Number(selectedPackage.benefitCategoryTag),
        benefitImplementationTag: Number(
          selectedPackage.benefitImplementationTag
        ),
        benefitPackageTag: Number(selectedPackage.benefitPackageTag),
        clientInternalNumberGOS: Number(
          selectedPackage.clientInternalNumberGos
        ),
        countryCode: Number(selectedPackage.countryCode),
        rowVersion: selectedPackage.rowVersion || "",
      },
      {
        onSuccess: () => {
          toggleDeleteConfirmationState();
          toast.success(t("hbh.packageDeletedSuccessfully.label"));
        },
      }
    );
  }, [selectedPackage, benefitPackages, t]);

  const handleUpdateAttachment = useCallback(
    ({
      attachment,
      newValues,
    }: {
      attachment: Attachment;
      newValues: {
        attachmentName: string;
        attachmentSummary: string;
        attachmentAbstract: string;
        isHiddenForEmployee: boolean;
      };
    }) => {
      onUpdateAttachment(
        {
          attachmentName: attachment.attachmentName || "",
          benefitCategoryTag: Number(selectedPackage?.benefitCategoryTag),
          benefitImplementationTag: Number(
            selectedPackage?.benefitImplementationTag
          ),
          clientInternalNumberGOS:
            selectedPackage?.clientInternalNumberGos || 0,
          benefitPackageTag: Number(selectedPackage?.benefitPackageTag),
          countryCode: selectedPackage?.countryCode || 0,
          payload: {
            ...attachment,
            attachmentName: newValues?.attachmentName,
            attachmentSummary: newValues?.attachmentSummary,
            attachmentAbstract: newValues?.attachmentAbstract,
            isHiddenForEmployee: newValues?.isHiddenForEmployee,
          },
        },
        {
          onSuccess: () => {
            toast.success(t("hbh.documentUpdatedSuccessfully.label"));
          },
        }
      );
    },
    [selectedPackage]
  );

  const onUpdatePhoto = useCallback(
    (photo, packageData) => {
      setSelectedPackage(packageData);
      onUpdatePackage(
        {
          countryCode: Number(benefitImplementation?.countryCode),
          clientInternalNumberGOS: Number(
            benefitImplementation?.clientInternalNumberGos
          ),
          benefitCategoryTag: Number(benefitImplementation?.benefitCategoryTag),
          benefitImplementationTag: Number(
            benefitImplementation?.benefitImplementationTag
          ),
          benefitPackageTag: Number(packageData.benefitPackageTag),
          payload: {
            ...(packageData as IBenefitPackage),
            backgroundPictureContent: photo?.base64String,
            backgroundPictureName: photo?.name,
            backgroundPictureMimeType: photo?.type,
            backgroundPicturePath: photo?.path,
          },
        },
        {
          onSuccess() {
            toast.success(t("hbh.packageUpdatedSuccessfully.label"));
          },
          onError() {
            toast.error(t("greco.error"));
          },
        }
      );
    },
    [selectedPackage]
  );

  const handleCreateAttachment = useCallback(
    (
      newAttach: IFormattedFile | null,
      isHiddenForEmployee?: boolean,
      newAttachList?: IFormattedFileExtended[],
      onSuccessFn?: () => void
    ) => {
      try {
        if (newAttachList) {
          const promiseArray = [] as Promise<any>[];
          newAttachList.forEach((attachment) => {
            promiseArray.push(
              onCreateAttachment({
                benefitCategoryTag: Number(selectedPackage?.benefitCategoryTag),
                benefitImplementationTag: Number(
                  selectedPackage?.benefitImplementationTag
                ),
                clientInternalNumberGOS:
                  selectedPackage?.clientInternalNumberGos || 0,
                benefitPackageTag: Number(selectedPackage?.benefitPackageTag),
                countryCode: selectedPackage?.countryCode || 0,
                payload: {
                  attachmentName: attachment?.name || "",
                  attachmentContent: attachment?.base64String || "",
                  attachmentMimeType: attachment?.type || "",
                  isHiddenForEmployee: attachment.isAttachHiddenForEmployee,
                },
              })
            );
          });

          Promise.all(promiseArray).then(() => {
            onSuccessFn && onSuccessFn();
            toast.success(t("hbh.documentCreatedSuccessfully.label"));
          });
          return;
        }
        onCreateAttachment(
          {
            benefitCategoryTag: Number(selectedPackage?.benefitCategoryTag),
            benefitImplementationTag: Number(
              selectedPackage?.benefitImplementationTag
            ),
            clientInternalNumberGOS:
              selectedPackage?.clientInternalNumberGos || 0,
            benefitPackageTag: Number(selectedPackage?.benefitPackageTag),
            countryCode: selectedPackage?.countryCode || 0,
            payload: {
              attachmentName: newAttach?.name || "",
              attachmentContent: newAttach?.base64String || "",
              attachmentMimeType: newAttach?.type || "",
              isHiddenForEmployee,
            },
          },
          {
            onSuccess: () => {
              onSuccessFn && onSuccessFn();

              toast.success(t("hbh.documentCreatedSuccessfully.label"));
            },
          }
        );
      } catch (error) {
        console.log(error, "error in package card list");
      }
    },
    [selectedPackage]
  );

  const handleDeleteAttachment = useCallback(
    (attach: Attachment) => {
      onDeleteAttachment(
        {
          attachmentName: attach.attachmentName || "",
          benefitCategoryTag: Number(selectedPackage?.benefitCategoryTag),
          benefitImplementationTag: Number(
            selectedPackage?.benefitImplementationTag
          ),
          benefitPackageTag: Number(selectedPackage?.benefitPackageTag),
          clientInternalNumberGOS:
            selectedPackage?.clientInternalNumberGos || 0,
          countryCode: selectedPackage?.countryCode || 0,
          rowVersion: attach.rowVersion || "",
        },
        {
          onSuccess: () => {
            toast.success(t("hbh.documentDeletedSuccessfully.label"));
          },
        }
      );
    },
    [selectedPackage]
  );

  const sortedPackages = benefitPackages.sort(
    (a, b) => Number(a?.benefitPackageTag) - Number(b.benefitPackageTag)
  );

  useEffect(() => {
    const updatedPackage = benefitPackages.find(
      (item) => item.benefitPackageTag === selectedPackage?.benefitPackageTag
    );
    if (updatedPackage) {
      setSelectedPackage(updatedPackage); // update selected package when documents are updated
    }
  }, [benefitPackages]);

  return (
    <>
      <CardListWrap>
        {sortedPackages.map((packageItem) => {
          if (
            packageItem.benefitPackageTag ===
              selectedPackage?.benefitPackageTag &&
            (updatePackageInProgress || deletePackageInProgress)
          ) {
            return (
              <Skeleton aria-label="Loading Content">
                <CardItemSkeleton />
              </Skeleton>
            );
          }
          return (
            <BenefitCardNew
              previewMode={previewModeCard}
              type="package"
              benefitPackage={packageItem}
              key={packageItem.benefitPackageTag}
              onEdit={handleEditPackageDialogState}
              onManageDocuments={handleOpenManageDocuments}
              onUpdatePhoto={onUpdatePhoto}
              onDelete={isSuperAdminUser ? handleDeletePackageOpen : undefined}
              openBenefitPackageEmployeesImplementation={
                openBenefitPackageEmployeesImplementation
              }
            />
          );
        })}
      </CardListWrap>

      {packageDialogOpen && (
        <PackageDialog
          benefitImplementation={benefitImplementation}
          packageData={selectedPackage}
          open={packageDialogOpen}
          setOpen={handlePackageDialogOpen}
        />
      )}
      {manageDocumentOpen && (
        <ManageAttachmentsDialog
          attachmentActionsState={{
            createAttachmentInProgress,
            onCreateAttachment: handleCreateAttachment,
            updateInProgress,
            onUpdateAttachment: handleUpdateAttachment,
            deleteInProgress: deleteAttachInProgress,
            onDeleteAttachment: handleDeleteAttachment,
          }}
          item={selectedPackage as IPackageRowItem}
          open={manageDocumentOpen}
          setOpen={onManageDocumentsOpenStateChange}
        />
      )}
      {deleteConfirmationOpen && (
        <ConfirmationDialog
          isDisabled={deletePackageInProgress}
          isOpen={deleteConfirmationOpen}
          toggleOpen={toggleDeleteConfirmationState}
          onConfirm={handleDeletePackage}
          description={t("hbh.deleteObject.label", {
            object: selectedPackage?.name,
          })}
          confirmLabel={t("greco.delete")}
          title={`${t("hbh.deletePackage.label")}: ${selectedPackage?.name} `}
        />
      )}
    </>
  );
}
