import useNotificationHandler from "hooks/useNotificationHandler";
import { MUTATION_KEYS, QUERY_KEYS } from "library/shared";
import { Attachment, IEmployee } from "library/types";
import { useMutation, useQueryClient } from "react-query";
import api from "service/api";
import { useInvalidateEmployeeDetails } from "./useGetEmployeeDetails";

type Params = {
  countryCode: number | string;
  employeeLoginEmailAddress: string;
  clientInternalNumberGOS: number | string;
  attachmentName: string;
  payload: Attachment;
};
const updateAttachment = async ({
  countryCode,
  clientInternalNumberGOS,
  employeeLoginEmailAddress,
  attachmentName,
  payload,
}: Params) => {
  const response = await api.patch(
    `/employee/attachment/${countryCode}/${clientInternalNumberGOS}/${employeeLoginEmailAddress}/${attachmentName}`,
    payload
  );
  return response.data as Attachment;
};
export function useUpdateEmployeeAttachment(refetch = true) {
  const queryClient = useQueryClient();
  const invalidateEmployeeDetails = useInvalidateEmployeeDetails();
  const { handleAddAxiosErrorNotification } = useNotificationHandler();

  return useMutation(updateAttachment, {
    mutationKey: MUTATION_KEYS.updateEmployeeAttachment,
    onSuccess: (updatedAttach) => {
      if (refetch) {
        invalidateEmployeeDetails();
      } else {
        const previousEmployeeData = queryClient.getQueryData<IEmployee>([
          QUERY_KEYS.employeeDetails,
          updatedAttach?.countryCode?.toString(),
          updatedAttach?.clientInternalNumberGos?.toString(),
          updatedAttach?.attachmentObjectTag,
        ]);
        const updatedEmployeeData = {
          ...previousEmployeeData,
          attachments: [
            ...(previousEmployeeData?.attachments || []).map((attach) => {
              if (attach.attachmentName === updatedAttach.attachmentName) {
                return updatedAttach;
              }
              return attach;
            }),
          ],
        };
        queryClient.setQueryData(
          [
            QUERY_KEYS.employeeDetails,
            updatedAttach?.countryCode?.toString(),
            updatedAttach?.clientInternalNumberGos?.toString(),
            updatedAttach?.attachmentObjectTag,
          ],
          updatedEmployeeData
        );
      }
    },
    onError: (error) => {
      // handle error
      handleAddAxiosErrorNotification(error);
    },
  });
}
