import useNotificationHandler from "hooks/useNotificationHandler";
import { MUTATION_KEYS, QUERY_KEYS } from "library/shared";
import {
  IEmployeeDependent,
  IEmployeeEnrollment,
  IEmployeeView,
} from "library/types";
import { useMutation, useQueryClient } from "react-query";
import api from "service/api";
import { useInvalidateCompanyEmployeeViews } from "./useGetCompanyEmployeeViews";
import { useInvalidateNeedsAttentionData } from "./useGetNeedsAttentionData";

type Params = {
  countryCode: number | string;
  clientInternalNumberGOS: number | string;
  payload: IEmployeeEnrollment;
  benefitImplementationTag: number | string;
  benefitPackageTag: number | string;
  employeeLoginEmailAddress: string;
  employeeEnrollmentTag: number | string;
};

const updateEmployeeEnrollment = async ({
  countryCode,
  clientInternalNumberGOS,
  benefitImplementationTag,
  benefitPackageTag,
  employeeLoginEmailAddress,
  employeeEnrollmentTag,
  payload,
}: Params) => {
  const response = await api.patch(
    `/employeeenrollment/${countryCode}/${clientInternalNumberGOS}/${benefitImplementationTag}/${benefitPackageTag}/${employeeLoginEmailAddress}/${employeeEnrollmentTag}`,
    payload
  );

  return response.data as IEmployeeDependent;
};

export function useUpdateEmployeeEnrollment(
  refetch = true,
  onlyActiveImplementations = true
) {
  const queryClient = useQueryClient();
  const invalidateCompanyEmployees = useInvalidateCompanyEmployeeViews();
  const { handleAddAxiosErrorNotification } = useNotificationHandler();
  const invalidateNeedsAttentionData = useInvalidateNeedsAttentionData();

  return useMutation(updateEmployeeEnrollment, {
    mutationKey: MUTATION_KEYS.updateEmployeeEnrollment,
    onSuccess: (employeeEligibility: any) => {
      invalidateNeedsAttentionData();
      if (refetch) {
        invalidateCompanyEmployees();

        queryClient.invalidateQueries({
          queryKey: QUERY_KEYS.companyAction,
        });
      } else {
        const previousEmployeeData = queryClient.getQueryData<IEmployeeView[]>([
          QUERY_KEYS.companyEmployeeViews,
          employeeEligibility?.countryCode?.toString(),
          employeeEligibility?.clientInternalNumberGos?.toString(),
          onlyActiveImplementations,
        ]);
        const updatedEmployeeData = previousEmployeeData?.map(
          (employee: IEmployeeView) => {
            if (
              employee.employeeLoginEmailAddress ===
                employeeEligibility.employeeLoginEmailAddress &&
              !employee.isDependent
            ) {
              return {
                ...employee,
                expanded: true,
                selected: true,
                enrollments: [
                  ...(employee.enrollments || []).map((enrollment) => {
                    if (
                      enrollment.employeeEnrollmentTag ===
                      employeeEligibility.employeeEnrollmentTag
                    ) {
                      return employeeEligibility;
                    } else {
                      return enrollment;
                    }
                  }),
                ],
              };
            }
            return employee;
          }
        );
        queryClient.setQueryData(
          [
            QUERY_KEYS.companyEmployeeViews,
            employeeEligibility.countryCode.toString(),
            employeeEligibility.clientInternalNumberGos.toString(),
            onlyActiveImplementations,
          ],
          updatedEmployeeData
        );
      }
    },
    onError: (error) => {
      // handle error
      handleAddAxiosErrorNotification(error);
    },
  });
}
