import useNotificationHandler from "hooks/useNotificationHandler";
import { MUTATION_KEYS } from "library/shared";
import { useMutation } from "react-query";
import api from "service/api";

type Params = {
  payload: {
    emailList: string[];
    countryCode: number;
    clientInternalNumberGos: number;
    isSalaryDeductionConsent: boolean;
    onlyActive: boolean;
  };
};

const exportEmployeeConsents = async ({ payload }: Params) => {
  const queryParams = new URLSearchParams({
    onlyActive: payload.onlyActive.toString(),
  });
  const countryCode = payload?.countryCode;
  const clientInternalNumberGos = payload?.clientInternalNumberGos;
  const isSalaryDeductionConsent = payload?.isSalaryDeductionConsent;
  const response = await api.post(
    `/employee/consents/${countryCode}/${clientInternalNumberGos}/${isSalaryDeductionConsent}?${queryParams}`,
    payload.emailList
  );

  return response.data as string;
};

export function useExportEmployeeIConsents(refetch = true) {
  const { handleAddAxiosErrorNotification } = useNotificationHandler();

  return useMutation(exportEmployeeConsents, {
    mutationKey: MUTATION_KEYS.exportEmployeeConsents,
    onSuccess: () => {},
    onError: (error) => {
      // handle error
      handleAddAxiosErrorNotification(error);
    },
  });
}
