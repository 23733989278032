import useNotificationHandler from "hooks/useNotificationHandler";
import { useAtom, useAtomValue } from "jotai";
import { MUTATION_KEYS, QUERY_KEYS } from "library/shared";
import { Attachment, IBenefitImplementation } from "library/types";
import { useMutation, useQueryClient } from "react-query";
import api from "service/api";
import { onlyActiveImplementationsState as onlyActiveImplementationsHrState } from "store/UIHrPage";
import { onlyActiveImplementationsState as onlyActiveImplementationsCompanyState } from "store/UICompanyPage";
import { useLocation } from "react-router-dom";
import { ROUTES } from "library/constants";

type Params = {
  countryCode: number | string;
  benefitCategoryTag: number | string;
  clientInternalNumberGOS: number | string;
  benefitImplementationTag: number | string;
  payload: Attachment;
};
const createAttachment = async ({
  countryCode,
  clientInternalNumberGOS,
  benefitCategoryTag,
  benefitImplementationTag,
  payload,
}: Params) => {
  const response = await api.post(
    `/benefitimplementation/attachment/${countryCode}/${clientInternalNumberGOS}/${benefitCategoryTag}/${benefitImplementationTag}`,
    payload
  );

  return response.data as Attachment;
};
export function useCreateBenefitImplementationAttachment(
  refetch = true,
  implementation?: IBenefitImplementation
) {
  const queryClient = useQueryClient();
  const { handleAddAxiosErrorNotification } = useNotificationHandler();
  const onlyActiveImplementationsHr = useAtomValue(
    onlyActiveImplementationsHrState
  );
  const onlyActiveImplementationsCompany = useAtomValue(
    onlyActiveImplementationsCompanyState
  );
  const location = useLocation();
  const onlyActive = location.pathname.includes(ROUTES.HR)
    ? onlyActiveImplementationsHr
    : onlyActiveImplementationsCompany;

  return useMutation(createAttachment, {
    mutationKey: MUTATION_KEYS.createBenefitImplementationAttachment,
    onSuccess: (newAttach) => {
      if (refetch) {
        queryClient.refetchQueries({
          queryKey: QUERY_KEYS.benefitImplementations,
        });
      } else {
        const prevImplementations = queryClient.getQueryData<
          IBenefitImplementation[]
        >([
          QUERY_KEYS.benefitImplementations,
          implementation?.countryCode?.toString(),
          implementation?.clientInternalNumberGos?.toString(),
          implementation?.benefitCategoryTag?.toString(),
          onlyActive,
        ]);
        const updatedImplementation = (prevImplementations || []).map(
          (implementation) => {
            if (
              implementation.countryCode === newAttach.countryCode &&
              implementation.clientInternalNumberGos ===
                newAttach.clientInternalNumberGos
            ) {
              return {
                ...implementation,
                attachments: [...(implementation.attachments || []), newAttach],
              };
            }
            return implementation;
          }
        );

        queryClient.setQueryData(
          [
            QUERY_KEYS.benefitImplementations,
            implementation?.countryCode?.toString(),
            implementation?.clientInternalNumberGos?.toString(),
            implementation?.benefitCategoryTag?.toString(),
            true,
          ],
          updatedImplementation
        );
      }
    },
    onError: (error) => {
      // handle error
      handleAddAxiosErrorNotification(error);
    },
  });
}
