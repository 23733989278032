import { useAtom } from "jotai";
import { IClient, IEmployeeView } from "library/types";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useGetClientsByCountry } from "state/queries/useGetClientsByCountry";
import { useGetNeedsAttentionData } from "state/queries/useGetNeedsAttentionData";
import { needsAttentionDataStateAtom } from "store/UIHrPage";

//define set of needs attention types
export const needsAttentionTypes = {
  EMPLOYEE: "EMPLOYEE",
  EMPLOYEE_ENROLLMENT: "EMPLOYEE_ENROLLMENT",
  DEPENDENT: "DEPENDENT",
  DEPENDENT_ENROLLMENT: "DEPENDENT_ENROLLMENT",
};

const useNeedsAttentionData = ({
  countryCode,
  clientInternalNumberGOS,
  enabled,
  searchEmployeeFilterValue,
}) => {
  const { t } = useTranslation();

  const [needsAttentionDataState, setNeedsAttentionDataState] = useAtom(
    needsAttentionDataStateAtom
  );

  const {
    data: companies,
    isLoading: companyOptionsLoading,
    isRefetching: companyOptionsRefetching,
  } = useGetClientsByCountry({
    countryCode: 0,
  });
  const {
    data: needsAttentionDataView,
    isLoading: loadingNeedsAttentionDataView,
  } = useGetNeedsAttentionData({
    countryCode: countryCode || 0,
    // clientInternalNumberGOS: 408439,
    clientInternalNumberGOS: clientInternalNumberGOS || "",
    enabled: enabled,
  });

  const needsAttentionEmployeeTree: IEmployeeView[] | undefined =
    useMemo(() => {
      if (!needsAttentionDataView || !companies) return [];
      const retVal: any[] = [];
      // Add employees
      needsAttentionDataView.forEach((item) => {
        if (identifyNeedsAttentionType(item) === needsAttentionTypes.EMPLOYEE) {
          addEmployeeIfNeeded(item, retVal, companies);
        }
      });
      // Add employee enrollments
      needsAttentionDataView.forEach((item) => {
        if (
          identifyNeedsAttentionType(item) ===
          needsAttentionTypes.EMPLOYEE_ENROLLMENT
        ) {
          const employee = addEmployeeIfNeeded(item, retVal, companies);
          addEnrollment(item, employee.enrollments);
        }
      });
      // Add dependents
      needsAttentionDataView.forEach((item) => {
        if (
          identifyNeedsAttentionType(item) === needsAttentionTypes.DEPENDENT
        ) {
          const employee = addEmployeeIfNeeded(
            convertDependentEntryToEmployee(item),
            retVal,
            companies
          );
          addDependentIfNeeded(item, employee);
        }
      });
      // Add dependent enrollments
      needsAttentionDataView.forEach((item) => {
        if (
          identifyNeedsAttentionType(item) ===
          needsAttentionTypes.DEPENDENT_ENROLLMENT
        ) {
          const employee = addEmployeeIfNeeded(
            convertDependentEntryToEmployee(item),
            retVal,
            companies
          );
          const dependent = addDependentIfNeeded(item, employee);
          addEnrollment(item, dependent.enrollments);
        }
      });

      return retVal;
    }, [needsAttentionDataView, companies]);

  useEffect(() => {
    if (needsAttentionEmployeeTree) {
      setNeedsAttentionDataState(
        needsAttentionEmployeeTree?.filter((item) =>
          `${item.firstName} ${item.lastName}`
            .toLowerCase()
            .includes(searchEmployeeFilterValue)
        )
      );
    }
  }, [needsAttentionEmployeeTree, searchEmployeeFilterValue]);

  return {
    needsAttentionData: needsAttentionDataView,
    needsAttentionEmployeeTree: needsAttentionEmployeeTree,
    loadingNeedsAttentionData: loadingNeedsAttentionDataView,
  };
};

export default useNeedsAttentionData;

function identifyNeedsAttentionType(item: any) {
  if (item.isDependent === false && item.needsAttention === true) {
    return needsAttentionTypes.EMPLOYEE;
  }
  if (item.isDependent === false && item.needsAttention === false) {
    return needsAttentionTypes.EMPLOYEE_ENROLLMENT;
  }
  if (item.isDependent === true && item.needsAttention === true) {
    return needsAttentionTypes.DEPENDENT;
  }
  if (item.isDependent === true && item.needsAttention === false) {
    return needsAttentionTypes.DEPENDENT_ENROLLMENT;
  }
  return null;
}

function prepareEmployee(item: IEmployeeView, companies: IClient[]): any {
  return {
    ...item,
    dependents: [],
    enrollments: [],
    dependentList: [],
    clientName: companies.find(
      (company) =>
        company.clientInternalNumberGos === item.clientInternalNumberGos
    )?.clientName,
  };
}

function findEmployee(item: any, employees: any[]) {
  return employees.find(
    (employeeItem) =>
      employeeItem.employeeLoginEmailAddress ===
        item.employeeLoginEmailAddress &&
      employeeItem.clientInternalNumberGos === item.clientInternalNumberGos
  );
}

function findDependent(item: any, dependents: any[]) {
  return dependents.find(
    (dependentItem) =>
      dependentItem.employeeDependentTag === item.employeeDependentTag
  );
}

function prepareDependent(item: any) {
  return {
    ...item,
    enrollments: [],
  };
}

function addEmployeeIfNeeded(
  item: any,
  employees: any[],
  companies: IClient[]
) {
  let employee = findEmployee(item, employees);
  if (!employee) {
    // Add employee if missing
    employee = prepareEmployee(item, companies);
    employees.push({ ...employee, needsAttention: true });
  }
  return employee;
}
function addDependentIfNeeded(item: IEmployeeView, employee: any) {
  let dependent = findDependent(item, employee.dependentList);

  if (!dependent) {
    // Add dependent if missing
    dependent = prepareDependent(item);
    employee.dependents.push(dependent.id);
    employee.dependentList.push(dependent);
  }
  return dependent;
}
function addEnrollment(item: IEmployeeView, enrollments: any) {
  let enrollment = prepareEnrollment(item);
  enrollments.push(enrollment);
}

function prepareEnrollment(item: IEmployeeView) {
  return {
    ...item,
    name: (item as any).benefitPackageName,
    backgroundPicturePath: (item as any).benefitPackageBackgroundPicturePath,
  };
}
function convertDependentEntryToEmployee(item: IEmployeeView): any {
  return {
    ...item,
    firstName: (item as any).employeeFirstName,
    lastName: (item as any).employeeLastName,
    loginEmailAddress: (item as any).employeeLoginEmailAddress,
    emailAddress: (item as any).employeeLoginEmailAddress,
    backgroundPicturePath: (item as any).employeeBackgroundPicturePath,
    needsAttentionInformation: null,
  };
}
