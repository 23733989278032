import { ArrowExportRegular } from "@fluentui/react-icons";
import { ManageAttachmentsDialog } from "components/ManageAttachmentsDialog/ManageAttachmentsDialog";
import { ActionButton } from "components/styled";
import { useAtomValue } from "jotai";
import { ROUTES } from "library/constants";
import {
  Attachment,
  IClient,
  IFormattedFile,
  IFormattedFileExtended,
} from "library/types";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useAddClientAttachment } from "state/queries/useAddClientAttachment";
import { useDeleteClientAttachments } from "state/queries/useDeleteClientAttachment";
import { useInvalidateBenefitCategories } from "state/queries/useGetBenefitCategories";
import { useInvalidateClientsByCountry } from "state/queries/useGetClientsByCountry";
import { useInvalidateSingleClient } from "state/queries/useGetSingleClient";
import { useUpdateClientAttachment } from "state/queries/useUpdateClientAttachment";
import { selectedCompanyState } from "store/UIHrPage";

type Props = {
  client: IClient;
};

export function ManageClientAttachments({ client }: Props) {
  const { t } = useTranslation();

  const attachments = client?.attachments;

  const [open, setOpen] = useState(false);
  const location = useLocation();
  const isHrPage = location.pathname.includes(ROUTES.HR);

  const invalidateBenefitCategories = useInvalidateBenefitCategories();
  const invalidateSingleClient = useInvalidateSingleClient();
  const onDialogOpenChange = useCallback((state) => {
    setOpen(state);
  }, []);

  const clientAttachmentsNumber = attachments?.length;

  const {
    mutateAsync: onCreateAttachment,
    isLoading: createAttachmentInProgress,
  } = useAddClientAttachment(false);

  const { mutate: onUpdateAttachment, isLoading: updateInProgress } =
    useUpdateClientAttachment();

  const { mutate: onDeleteAttachment, isLoading: deleteInProgress } =
    useDeleteClientAttachments();

  const handleUpdateAttachment = useCallback(
    ({
      attachment,
      newValues,
    }: {
      attachment: Attachment;
      newValues: {
        attachmentName: string;
        attachmentSummary: string;
        attachmentAbstract: string;
        isHiddenForEmployee: boolean;
      };
    }) => {
      onUpdateAttachment(
        {
          attachmentName: attachment.attachmentName || "",
          clientInternalNumberGOS: client?.clientInternalNumberGos || 0,
          countryCode: client?.countryCode || 0,
          payload: {
            ...attachment,
            attachmentName: newValues?.attachmentName,
            attachmentSummary: newValues?.attachmentSummary,
            attachmentAbstract: newValues?.attachmentAbstract,
            isHiddenForEmployee: newValues?.isHiddenForEmployee,
          },
        },
        {
          onSuccess: () => {
            isHrPage ? invalidateSingleClient() : invalidateBenefitCategories();
            toast.success(t("hbh.documentUpdatedSuccessfully.label"));
          },
        }
      );
    },
    []
  );

  const titleLabel = client?.attachments?.length
    ? t("hbh.manageDocuments.label")
    : t("hbh.addDocuments.label");

  const handleCreateAttachment = useCallback(
    (
      newAttach: IFormattedFile | null,
      isHiddenForEmployee?: boolean,
      newAttachList?: IFormattedFileExtended[],
      onSuccessFn?: () => void
    ) => {
      try {
        if (newAttachList) {
          const promiseArray = [] as Promise<any>[];
          newAttachList.forEach((attachment) => {
            promiseArray.push(
              onCreateAttachment({
                clientInternalNumberGOS: client?.clientInternalNumberGos || 0,
                countryCode: client?.countryCode || 0,
                payload: {
                  attachmentName: attachment?.name || "",
                  attachmentContent: attachment?.base64String || "",
                  attachmentMimeType: attachment?.type || "",
                  isHiddenForEmployee: attachment?.isAttachHiddenForEmployee,
                },
              })
            );
          });

          Promise.all(promiseArray).then(() => {
            onSuccessFn && onSuccessFn();
            isHrPage ? invalidateSingleClient() : invalidateBenefitCategories();

            toast.success(t("hbh.documentCreatedSuccessfully.label"));
          });
          return;
        }
        onCreateAttachment(
          {
            clientInternalNumberGOS: client?.clientInternalNumberGos || 0,
            countryCode: client?.countryCode || 0,
            payload: {
              attachmentName: newAttach?.name || "",
              attachmentContent: newAttach?.base64String || "",
              attachmentMimeType: newAttach?.type || "",
              isHiddenForEmployee,
            },
          },
          {
            onSuccess: () => {
              onSuccessFn && onSuccessFn();
              isHrPage
                ? invalidateSingleClient()
                : invalidateBenefitCategories();

              toast.success(t("hbh.documentCreatedSuccessfully.label"));
            },
          }
        );
      } catch (error) {}
    },
    []
  );

  const handleDeleteAttachment = useCallback((attach: Attachment) => {
    onDeleteAttachment(
      {
        attachmentName: attach.attachmentName || "",
        clientInternalNumberGOS: client?.clientInternalNumberGos || 0,
        countryCode: client?.countryCode || 0,
        rowVersion: attach.rowVersion || "",
      },
      {
        onSuccess: () => {
          isHrPage ? invalidateSingleClient() : invalidateBenefitCategories();

          toast.success(t("hbh.documentDeletedSuccessfully.label"));
        },
      }
    );
  }, []);

  return (
    <>
      <ActionButton
        icon={<ArrowExportRegular />}
        noBorder
        appearance="subtle"
        onClick={(e) => {
          setOpen(true);
        }}
      >
        {titleLabel}
        {clientAttachmentsNumber
          ? ` (${clientAttachmentsNumber} ${t("hbh.items.label")})`
          : ` (${t("hbh.noData.label")}) `}
      </ActionButton>
      {open && client && (
        <ManageAttachmentsDialog
          attachmentActionsState={{
            createAttachmentInProgress,
            onCreateAttachment: handleCreateAttachment,
            updateInProgress,
            onUpdateAttachment: handleUpdateAttachment,
            deleteInProgress,
            onDeleteAttachment: handleDeleteAttachment,
          }}
          item={client}
          open={open}
          setOpen={onDialogOpenChange}
        />
      )}
    </>
  );
}
