import { EditableTable } from "components/EditableTable/EditableTable";
import { useSetAtom } from "jotai";
import { columnNames } from "library/constants";
import { IEmployeeView } from "library/types";
import { useCallback, useMemo } from "react";
import { selectedTableViewEmployeesAtom } from "store/UIHrPage";
import styled from "styled-components";
import { defaultColumnSizingOptions } from "./columns";
import { SubrowComponent } from "./SubrowComponent/SubrowComponent";

type Props = {
  companyEmployees: IEmployeeView[] | undefined;
  columns: any;
  selectionMode: "multiselect" | "single";
  stickyColumnsLeft?: number;
  stickyColumnsRight?: number;
  stickyRowsTop?: number;
  stickyRowsBottom?: number;
  showDeleteOption?: boolean;
  showManagePackageEligibilityOption?: boolean;
};

export function EmployeesTable({
  companyEmployees,
  columns,
  selectionMode = "multiselect",
  stickyColumnsLeft,
  stickyColumnsRight,
  stickyRowsTop,
  stickyRowsBottom,
  showDeleteOption,
  showManagePackageEligibilityOption,
}: Props) {
  const setSelectedEmployees = useSetAtom(selectedTableViewEmployeesAtom);
  const tableItems = useMemo(() => {
    if (!companyEmployees) return [];
    return companyEmployees
      ?.map((item) => {
        return {
          ...item,
          id:
            item.employeeLoginEmailAddress + "_" + item.clientInternalNumberGos,
          // expanded: true,
          showSubrow: item.enrollments?.length || item.dependentList?.length,
        };
      })
      .sort((a, b) => (a?.firstName || "").localeCompare(b?.firstName || ""));
  }, [companyEmployees]);

  const handleAction = useCallback((data) => {
    const selectedRows = data
      .filter((item: any) => {
        return item.selected;
      })
      .map((row) => row.item);
    setSelectedEmployees(selectedRows);
  }, []);

  return (
    <div>
      <TableWrap>
        <EditableTable
          // withPagination={false}
          SubrowComponent={SubrowComponent}
          defaultColumnSizingOptions={defaultColumnSizingOptions}
          defaultItems={tableItems}
          columns={columns}
          columnNames={columnNames}
          tableDataAction={handleAction}
          onRowClick={(row) => {
            row.updateAllState({
              expanded: false,
            });
            row.updateState({
              ...row,
              expanded: !row.expanded,
              selected: !row.selected,
            });
          }}
          showDeleteOption={showDeleteOption}
          showManagePackageEligibilityOption={
            showManagePackageEligibilityOption
          }
          selectionMode={selectionMode}
          stickyColumnsLeft={stickyColumnsLeft}
          stickyColumnsRight={stickyColumnsRight}
          stickyRowsTop={stickyRowsTop}
          stickyRowsBottom={stickyRowsBottom}
        />
      </TableWrap>
    </div>
  );
}

const TableWrap = styled.div`
  margin-top: 10px;
  table {
    td:nth-of-type(2),
    th:nth-of-type(2) {
      position: sticky;
      left: 60px;
      z-index: 1;
      background: inherit;
      box-shadow: 16px 0px 32px 0px rgba(5, 54, 69, 0.06);
    }
    td:nth-of-type(3),
    th:nth-of-type(3) {
      position: sticky;
      left: 370px;
      z-index: 1;
      background: inherit;
      box-shadow: 16px 0px 32px 0px rgba(5, 54, 69, 0.06);
    }
    .selected-row:not(.sub-component-row) {
      background-color: ${({ theme }) => theme.palette.themeLighter} !important;
    }
    .sub-component-row {
      background-color: ${({ theme }) => theme.palette.white} !important;
    }

    .with-subrow {
      border: 3px solid ${(props) => props.theme.palette.themeLighter};
      border-bottom: none;
      background-color: ${({ theme }) => theme.palette.white} !important;
    }

    // .selected-row {
    //   background-color: ${({ theme }) =>
      theme.palette.themeLighter} !important;
    // }
  }
`;
