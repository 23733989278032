import { AddAttachments } from "components/AddAttachments/AddAttachments";
import DatePickerField from "components/controls/DatePickerField";
import { FilteringCombobox } from "components/controls/FilteringCombobox";
import InputField from "components/controls/InputField";
import { RadioGroupField } from "components/controls/RadioGroupField";
import { TextareaField } from "components/controls/TextareaField";
import { ManageImplementationDocuments } from "components/ManageImplementationDocuments/ManageImplementationDocuments";
import { Grid } from "components/styled";
import { UploadFile } from "components/Upload/UploadFile";
import { useFormikContext } from "formik";
import useGetCurrencyOptionsPerUser from "hooks/useGetCurrencyOptionsPerUser";
import { checkedOptions } from "library/constants";
import { IBenefitImplementation, IRowItem } from "library/types";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { ConsentDependencyFields } from "./ConsentDependencyFields";
import { SelectInsurer } from "./SelectInsurer";
import { SelectPolicy } from "./SelectPolicy";

type Props = {
  benefitImplementation?: IBenefitImplementation;
  currencyCodeOption: any;
};

export function ImplementBenefitFormItems({
  currencyCodeOption,
  benefitImplementation,
}: Props) {
  const { t } = useTranslation();
  const { values, setFieldValue, errors } = useFormikContext<FormData>();

  const params = useParams();

  const clientInternalNumberGOS = params?.clientInternalNumberGOS;
  const countryCode = params?.countryCode;

  const currencyOptions = useGetCurrencyOptionsPerUser();

  return (
    <Container>
      <Grid>
        <InputField
          label={t("greco.name")}
          name="name"
          value={values["name"]}
          setFieldValue={setFieldValue}
          error={errors["name"]}
          required
        />

        <RadioGroupField
          value={values["isAutoEnrollment"]}
          setFieldValue={setFieldValue}
          name="isAutoEnrollment"
          label={t("hbh.isAutoEnrollment.label")}
          options={checkedOptions}
          error={errors["isAutoEnrollment"]}
        />
        <RadioGroupField
          value={values["consentsNeeded"]}
          setFieldValue={setFieldValue}
          name="consentsNeeded"
          label={t("hbh.consentNeeded.label")}
          error={errors["consentsNeeded"]}
          options={checkedOptions}
          // required
        />

        <FilteringCombobox
          error={errors["currencyCode"]}
          setFieldValue={setFieldValue}
          value={values["currencyCode"]}
          label={t("hbh.currency.label")}
          options={
            currencyCodeOption &&
            !currencyOptions?.find(
              (option) => option.code === currencyCodeOption.code
            )
              ? [...currencyOptions, currencyCodeOption]
              : currencyOptions
          }
          name={"currencyCode"}
          required
        />
        <UploadFile
          isUploadAppImages
          setFieldValue={setFieldValue}
          fieldName="coverPhoto"
          defaultValue={values["coverPhoto"]}
          label={t("hbh.chooseCoverPicture.label")}
        />
      </Grid>
      <ConsentDependencyFields />

      <TextareaField
        label={t("hbh.description.label")}
        name="description"
        value={values["description"]}
        setFieldValue={setFieldValue}
        error={errors["description"]}
        rows={3}
        resize="vertical"
        required
      />
      <Grid $gridColumns={2}>
        <SelectPolicy
          countryCode={
            benefitImplementation
              ? benefitImplementation?.countryCode
              : countryCode
          }
          clientInternalNumberGos={
            benefitImplementation
              ? benefitImplementation?.clientInternalNumberGos
              : clientInternalNumberGOS
          }
        />
        <SelectInsurer
          countryCode={
            benefitImplementation
              ? benefitImplementation?.countryCode
              : countryCode
          }
        />
      </Grid>
      <Grid $gridColumns={3}>
        <InputField
          required
          error={errors["carrierName"]}
          label={t("hbh.carrier.label")}
          name="carrierName"
          value={values["carrierName"]}
          setFieldValue={setFieldValue}
        />
        <InputField
          required
          error={errors["policyOrContractNumber"]}
          label={t("hbh.policyOrContractNumber.label")}
          name="policyOrContractNumber"
          value={values["policyOrContractNumber"]}
          setFieldValue={setFieldValue}
        />

        <DatePickerField
          required
          error={errors["validFrom"]}
          name="validFrom"
          value={values["validFrom"]}
          setFieldValue={setFieldValue}
          label={t("hbh.validFrom.label")}
        />
        <DatePickerField
          required
          name="validTo"
          value={values["validTo"]}
          setFieldValue={setFieldValue}
          error={errors["validTo"]}
          label={t("hbh.validTo.label")}
        />
      </Grid>
      {benefitImplementation ? (
        <ManageImplementationDocuments
          item={benefitImplementation as IRowItem}
        />
      ) : (
        <AddAttachments />
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
`;
