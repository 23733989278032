import useNotificationHandler from "hooks/useNotificationHandler";
import { MUTATION_KEYS, QUERY_KEYS } from "library/shared";
import { IEmployeeEnrollment, IEmployeeView } from "library/types";
import { useMutation, useQueryClient } from "react-query";
import api from "service/api";
import { useInvalidateCompanyEmployeeViews } from "./useGetCompanyEmployeeViews";
import { useInvalidateNeedsAttentionData } from "./useGetNeedsAttentionData";

type Params = {
  countryCode: number | string;
  clientInternalNumberGOS: number | string;
  payload: IEmployeeEnrollment;
  employeeEmail: string;
};

const createEmployeeEligibility = async ({
  countryCode,
  clientInternalNumberGOS,
  employeeEmail,
  payload,
}: Params) => {
  const response = await api.post(
    `/employeeenrollment/${countryCode}/${clientInternalNumberGOS}`,
    // `/employeeenrollment/${countryCode}/${clientInternalNumberGOS}/${employeeEmail}`,
    payload
  );
  return response.data as IEmployeeEnrollment;
};

export function useCreateEmployeeEligibility(
  refetch = true,
  onlyActiveImplementations = true
) {
  const queryClient = useQueryClient();
  const invalidateCompanyEmployees = useInvalidateCompanyEmployeeViews();
  const { handleAddAxiosErrorNotification } = useNotificationHandler();
  const invalidateNeedsAttentionData = useInvalidateNeedsAttentionData();

  return useMutation(createEmployeeEligibility, {
    mutationKey: MUTATION_KEYS.createEmployeeEligibility,
    onSuccess: (employeeEligibility: any) => {
      invalidateNeedsAttentionData();
      if (refetch) {
        invalidateCompanyEmployees();
      } else {
        const previousEmployeeData = queryClient.getQueryData<IEmployeeView[]>([
          QUERY_KEYS.companyEmployeeViews,
          employeeEligibility?.countryCode?.toString(),
          employeeEligibility?.clientInternalNumberGos?.toString(),
          onlyActiveImplementations,
        ]);

        const updatedEmployeeData = previousEmployeeData?.map(
          (employee: IEmployeeView) => {
            if (
              employee.employeeLoginEmailAddress ===
                employeeEligibility.employeeLoginEmailAddress &&
              !employee.isDependent
            ) {
              return {
                ...employee,
                expanded: true,
                selected: true,
                enrollments: [
                  ...(employee.enrollments || []),
                  employeeEligibility,
                ],
              };
            }
            return employee;
          }
        );

        queryClient.setQueryData(
          [
            QUERY_KEYS.companyEmployeeViews,
            employeeEligibility?.countryCode?.toString(),
            employeeEligibility?.clientInternalNumberGos?.toString(),
            onlyActiveImplementations,
          ],
          updatedEmployeeData
        );
        return employeeEligibility;
      }
    },
    onError: (error) => {
      // handle error
      handleAddAxiosErrorNotification(error);
    },
  });
}
