import useNotificationHandler from "hooks/useNotificationHandler";
import { QUERY_KEYS } from "library/shared";
import { IEmployeeView } from "library/types";
import { UseQueryResult, useQuery, useQueryClient } from "react-query";
import api from "service/api";

type Params = {
  countryCode: number | string | null;
  clientInternalNumberGOS: number | string;
  enabled?: boolean;
  onlyActive?: boolean;
};

const getCompanyEmployeeViews = async ({
  countryCode,
  clientInternalNumberGOS,
  onlyActive = true,
}: Params) => {
  if (countryCode === null || clientInternalNumberGOS === null) return;
  const response = await api.get(
    `/company/employee/view/${countryCode}/${clientInternalNumberGOS}`,
    {
      params: {
        onlyActive,
      },
    }
  );

  return response.data;
};

export function useGetCompanyEmployeeViews({
  countryCode,
  clientInternalNumberGOS,
  onlyActive,
  enabled = true,
}: Params): UseQueryResult<IEmployeeView[]> {
  const { handleAddAxiosErrorNotification } = useNotificationHandler();

  return useQuery<IEmployeeView[], Error>(
    [
      QUERY_KEYS.companyEmployeeViews,
      countryCode?.toString(),
      clientInternalNumberGOS?.toString(),
      onlyActive,
    ],
    () =>
      getCompanyEmployeeViews({
        countryCode,
        clientInternalNumberGOS,
        onlyActive,
      }),
    {
      // staleTime: 0,
      // refetchOnMount: true,

      // cacheTime: 0,

      enabled: enabled,
      onError: (error: any) => {
        // errorHandler({
        //   label: "Error",
        //   type: notificationTypes.error,
        //   value: "Error while loading offer item data",
        //   errors: error?.message,
        //   fieldName: "",
        // });
        handleAddAxiosErrorNotification(error);
      },
    }
  );
}
export const useInvalidateCompanyEmployeeViews = () => {
  const queryClient = useQueryClient();

  return () =>
    queryClient.invalidateQueries({
      queryKey: QUERY_KEYS.companyEmployeeViews,
    });
};
